<template>
  <crud
    v-if="urlBase"
    ref="crud"
    nomeEntidade="Atendimento"
    nomeEntidadePlural="Atendimentos"
    :headers="cabecalhos"
    :resource-url="urlBase"
    rota-edicao="AtendimentoEditor"
    item-key="id"
    :exibirBotaoCadastrar="false"
    @clicou-item="selecionarParaEdicao"
    :clicou-salvar="salvar"
    :voltarParaPrimeiraTelaAoSalvar="false"
    @clicou-cancelar="cancelar"
    @clicou-novo="novo"
    :for-each-item-callback="formatarAtendimento"
    @validou-formulario="validouFormulario"
  >
    <template slot="beforeAdd">
      <v-flex xs12>
        <v-menu
          ref="menuDataAtendimentos"
          v-model="menuDataAtendimentos"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              tabindex="5"
              v-model="dataAtendimentosFormatada"
              label="Data"
              prepend-icon="event"
              mask="##/##/####"
              return-masked-value
              hint="DD/MM/AAAA"
              persistent-hint
              @blur="dataAtendimentos = parseDate(dataAtendimentosFormatada)"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="dataAtendimentos"
            no-title
            locale="pt-br"
            @input="menuDataAtendimentos = false"
          />
        </v-menu>
      </v-flex>
    </template>

    <template slot="topFormArea">
      <h3>{{ computedSituacaoDoAtendimento }}</h3>
    </template>

    <template slot="detalhe">
      <div v-if="entidadeAtual">
        <v-container>
          <!-- Dados do Atendimento -->
          <v-layout row wrap>
            <v-flex xs12 md6>
              <v-text-field label="Atendente" v-model="computedNomeDoAtendente" disabled />
            </v-flex>
            <v-flex xs12 md2>
              <v-text-field label="Data" v-model="entidadeAtual.data" disabled />
            </v-flex>
            <v-flex xs12 md2>
              <v-text-field label="Início" v-model="entidadeAtual.inicio" disabled />
            </v-flex>
            <v-flex xs12 md2>
              <v-text-field label="Fim" v-model="entidadeAtual.fim" disabled />
            </v-flex>
          </v-layout>

          <!-- Dados do Atendido -->
          <v-layout row wrap>
            <v-flex xs12 md5>
              <v-text-field
                label="Atendido"
                v-model="entidadeAtual.atendido"
                :rules="[
                  validacao.obrigatorio,
                  validacao.tamanhoMinimo(entidadeAtual.atendido, 3),
                ]"
                required
                :disabled="atendimentoConcluido()"
              />
            </v-flex>
            <v-flex xs12 md2>
              <v-text-field
                label="CPF/CNPJ"
                v-model="entidadeAtual.cpf"
                :rules="[validacao.cpf_cnpj]"
                :disabled="atendimentoConcluido()"
                @change="aplicarMascaraCPFouCNPJ"
                validate-on-blur
              />
            </v-flex>
            <v-flex xs12 md3>
              <v-text-field
                label="E-mail"
                v-model="entidadeAtual.email"
                :rules="[validacao.email]"
                :disabled="atendimentoConcluido()"
              />
            </v-flex>
            <v-flex xs12 md2>
              <v-text-field
                label="Telefone"
                v-model="entidadeAtual.telefone"
                :rules="[validacao.telefone]"
                :disabled="atendimentoConcluido()"
                @change="aplicarMascaraTelefone"
                validate-on-blur
              />
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12>
              <v-tabs v-model="tabAtiva">
                <v-tab>Assuntos</v-tab>
                <v-tab>Comentários</v-tab>
                <v-tab>Pendências</v-tab>

                <v-tabs-slider color="primary"></v-tabs-slider>
                <!-- TAB ASSUNTOS -->
                <v-tab-item class="ma-4 mb-8">
                  <v-layout row wrap class="pa-8">
                    <v-layout row wrap>
                      <v-flex xs12>
                        <h4>Assuntos</h4>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex
                        xs12
                        md4
                        lg3
                        align-start
                        justify-start
                        v-for="assunto in entidadeAtual.assuntos"
                        v-bind:key="assunto.id"
                      >
                        <v-checkbox
                          :label="assunto.assunto"
                          align-start
                          v-model="assunto.checked"
                          :disabled="atendimentoConcluido()"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </v-tab-item>

                <!-- TAB COMENTARIOS -->
                <v-tab-item class="ma-4 mb-8">
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-textarea
                        filled
                        label="Comentário:"
                        placeholder="Registre aqui informações que julgar relevantes ao atendimento prestado."
                        v-model="novoComentario"
                        :rules="[validacao.tamanhoMinimo(novoComentario, 8)]"
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-end>
                    <v-btn
                      color="primary lighten-2"
                      @click="inserirComentario"
                      :disabled="!botaoInserirComentarioHabilitado"
                    >
                      <v-icon>add</v-icon>Inserir Comentário
                    </v-btn>
                  </v-layout>
                  <comentarios :comentarios="entidadeAtual.comentarios"></comentarios>
                </v-tab-item>

                <!-- TAB PENDENCIAS -->
                <v-tab-item class="ma-8 mb-8">
                  <crud
                    v-if="urlBasePendencias"
                    ref="crudPendencias"
                    nomeEntidade="Pendência"
                    nomeEntidadePlural="Pendências"
                    :headers="cabecalhosPendencias"
                    :resource-url="urlBasePendencias"
                    item-key="id"
                    @clicou-item="selecionarPendenciaParaEdicao"
                    :clicou-salvar="salvarPendencia"
                    @clicou-cancelar="cancelarPendencia"
                    @clicou-novo="novaPendencia"
                    :for-each-item-callback="formatarPendencia"
                    :podeSalvar="podeSalvarPendencia"
                    color="green lighten-5"
                  >
                    <template slot="detalhe">
                      <div v-if="pendenciaAtual != null">
                        <v-container>
                          <v-layout row wrap>
                            <v-flex xs12 md4>
                              <v-text-field label="Data" v-model="pendenciaAtual.criacao" disabled />
                            </v-flex>
                            <v-flex xs12 md4>
                              <v-text-field
                                label="Demandante"
                                v-model="computedNomeDoAtendente"
                                disabled
                              />
                            </v-flex>
                            <v-flex xs12 md4>
                              <v-dialog
                                v-model="exibirConclusaoPendencia"
                                persistent
                                max-width="600px"
                              >
                                <v-card>
                                  <v-card-title>
                                    <span class="headline">Concluir Pendência</span>
                                  </v-card-title>
                                  <v-card-text>
                                    <v-layout row wrap>
                                      <v-flex xs12 md12>
                                        <v-textarea
                                          label="Comentário"
                                          v-model="pendenciaAtual.comentario_resolucao"
                                          :rules="[
                                            validacao.obrigatorio,
                                            validacao.tamanhoMinimo(pendenciaAtual.comentario_resolucao, 8),
                                          ]"
                                          required
                                        />
                                      </v-flex>
                                    </v-layout>
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer />
                                    <v-btn color="primary"
                                    @click="concluirPendencia" :disabled="!botaoConcluirPendencia">Concluir</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                              <v-autocomplete
                                label="Área Demandada"
                                clearable
                                :items="divisoesorganograma"
                                v-model="
                                  pendenciaAtual.divisaoorganogramaid_destino
                                "
                                :rules="[validacao.obrigatorio]"
                                required
                                item-text="nome"
                                item-value="id"
                                :loading="carregandoDivisoesOrganograma"
                                :disabled="!podeAlterarDivisaoOrganograma"
                              />
                            </v-flex>
                            <v-flex xs12 md12>
                              <v-textarea
                                label="Pendência"
                                v-model="pendenciaAtual.pendencia"
                                :rules="[
                                  validacao.obrigatorio,
                                  validacao.tamanhoMinimo(pendenciaAtual.pendencia, 8),
                                ]"
                                required
                                :disabled="!podeAlterarDescricaoPendencia"
                              />
                            </v-flex>
                            <v-flex xs12 md12 v-if="podeConcluirPendencia">
                              <v-btn
                                color="primary"
                                @click="exibirConclusaoPendencia = true"
                              >Concluir Pendência</v-btn>
                            </v-flex>
                            <v-flex xs12 md4 v-if="!podeConcluirPendencia">
                              <v-text-field
                                label="Data da Conclusão"
                                v-model="pendenciaAtual.conclusao"
                                disabled
                              />
                            </v-flex>
                            <v-flex xs12 md4 v-if="!podeConcluirPendencia">
                              <v-text-field
                                label="Concluída por"
                                v-model="pendenciaAtual.concluida_por"
                                disabled
                              />
                            </v-flex>
                            <v-flex xs12 md12 v-if="!podeConcluirPendencia">
                              <v-textarea
                                label="Comentário Conclusão"
                                v-model="pendenciaAtual.comentario_resolucao"
                                disabled
                              />
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </div>
                    </template>
                  </crud>
                </v-tab-item>
              </v-tabs>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </template>

    <template slot="beforeSaveButton">
      <v-btn
        color="success"
        @click="concluir"
        :disabled="!formularioValido() || atendimentoConcluido()"
      >
        <v-icon>check</v-icon>Concluir Atendimento
      </v-btn>
    </template>
  </crud>
</template>

<script>
import Crud from "./../../../components/ApiCrud.vue";
import Comentarios from "./../../../components/Comentarios.vue";
import rotas from "./../../../rotas-servico";
import Utils from "./../../../Utils";
import Validador from "./../../../validacao";
import EventBus from "./../../../event-bus";

const ATENDIMENTO_TAB_ASSUNTOS = 0;
const ATENDIMENTO_TAB_COMENTARIOS = 1;
const ATENDIMENTO_TAB_PENDENCIAS = 2;

export default {
  components: {
    crud: Crud,
    comentarios: Comentarios,
  },
  data: (vm) => {
    return {
      tabAtiva: ATENDIMENTO_TAB_ASSUNTOS,

      cabecalhos: [
        {
          text: "Tipo",
          value: "tipoAtendimento",
          // type: "filter",
          valueProperty: "tipoAtendimento",
        },
        {
          text: "Atendente",
          value: "atendente",
          // type: "filter",
          valueProperty: "atendente",
        },
        {
          text: "Atendido",
          value: "atendido",
          // type: "filter",
          valueProperty: "atendido",
        },
        {
          text: "Data",
          value: "data",
        },
        {
          text: "Início",
          value: "inicio",
        },
        {
          text: "Fim",
          value: "fim",
        },
        {
          text: 'Com pendência para',
          value: 'pendenciasParaChips',
          type: 'chip',
          sortable: false
        },
      ],
      registros: [],
      validacao: Validador,
      valid: false,
      assuntos: [],
      carregando: false,
      entidadeAtual: null,
      idTipoPresencial: 1,
      idTipoTelefonico: 2,
      idTipoEmail: 3,
      menuDataAtendimentos: false,
      dataAtendimentos: vm.parseDate(new Date().toLocaleDateString("pt-BR")),
      dataAtendimentosFormatada: vm.formatarData(
        new Date().toISOString().substr(0, 10)
      ),
      novoComentario: null,
      tempoAtendimento: null,
      pendenciaAtual: null,
      cabecalhosPendencias: [
        {
          text: "created_at",
          value: "created_at",
          type: "hidden",
        },
        {
          text: "Criação",
          value: "criacao",
        },
        {
          text: "De",
          value: "de_nome",
        },
        {
          text: "Para",
          value: "para_nome",
          subheader: "para_sigla"
        },
        {
          text: "Concluída em",
          value: "conclusao",
        },
        {
          text: "Concluída por",
          value: "concluida_por",
        },

      ],
      divisoesorganograma: [],
      carregandoDivisoesOrganograma: false,
      exibirConclusaoPendencia: false,
    };
  },
  methods: {
    selecionarParaEdicao (item, comentario=false) {
      if(!comentario) this.limparTela();
      this.entidadeAtual = item;
      let url = rotas.atendimentos.atendimento.obter.url({
        id: item.id,
      });
      this.$http.get(url).then(
        (response) => {
          if(comentario){
            this.entidadeAtual.comentarios = response.body.comentarios;
          }
          else{
            this.entidadeAtual = response.body;
            this.formatarAtendimento(this.entidadeAtual);
            this.preencherAssuntos(this.entidadeAtual);
          }
        },
        (error) => {
          console.log(error.body);
          EventBus.alerta(error.body.message);
        }
      );
    },

    novo (idTipoAtendimento) {
      let formData = new FormData();
      formData.append("tipoid", idTipoAtendimento);
      formData.append("atendido", "Não informado");
      let url = rotas.atendimentos.atendimento.criar.url();
      this.$http.post(url, formData).then(
        (response) => {
          let atendimento = {};
          atendimento.id = response.body.id;
          this.selecionarParaEdicao(atendimento);
          this.$refs.crud.showDetailsPanel();
          EventBus.mensagem("Atendimento iniciado!");
        },
        (error) => {
          console.log(error.body);
          EventBus.alerta(error.body.message);
        }
      );
    },

    preencherFormDataParaSalvarAtendimento () {
      let formData = new FormData();
      formData.append("tipoid", this.entidadeAtual.tipo.id);
      formData.append("atendido", this.entidadeAtual.atendido);
      if (this.entidadeAtual.cpf != null) {
        formData.append("cpf", this.entidadeAtual.cpf);
      }
      if (this.entidadeAtual.email != null) {
        formData.append("email", this.entidadeAtual.email);
      }
      if (this.entidadeAtual.telefone != null) {
        formData.append("telefone", this.entidadeAtual.telefone);
      }
      this.assuntos.forEach((assunto) => {
        if (assunto.checked) {
          formData.append("idsAssunto[]", assunto.id);
        }
      });
      if (this.novoComentario != null && this.novoComentario.length > 0) {
        formData.append("comentario", this.novoComentario);
      }

      formData.append("_method", "PUT");

      return formData;
    },

    salvar (concluir = false) {
      return new Promise((resolve, reject) => {
        try {
          let formData = this.preencherFormDataParaSalvarAtendimento();
          let url = rotas.atendimentos.atendimento.editar.url();
          if (concluir) {
            url += "concluir/";
          }
          url += this.entidadeAtual.id;
          this.$http
            .post(url, formData)
            .then(
              () => {
                if (concluir) {
                  EventBus.mensagem("Atendimento Concluído");
                  this.$refs.crud.loadItems()
                } else {
                  EventBus.mensagem("Alterações salvas com sucesso!");
                }
                resolve()
              },
              (error) => {
                reject(new Error(error.body.message))
              }
            )
        } catch (e) {
          reject(e)
        }
      })
    },

    concluir () {
      this.salvar(true);
      this.$refs.crud.loadItems();
      this.$refs.crud.showGridPanel();
    },

    cancelar () {
      this.$refs.crud.loadItems();
    },

    formatarAtendimento (element) {
      let dataHoraInicio = new Date(element.dataHoraInicio);
      element.data = dataHoraInicio.toLocaleDateString("pt-BR");
      element.inicio = dataHoraInicio.toLocaleTimeString("pt-BR");

      element.fim = "--";
      if (element.dataHoraFim) {
        let dataHoraFim = new Date(element.dataHoraFim);
        element.fim = dataHoraFim.toLocaleTimeString("pt-BR");
      }

      element.tipoAtendimento = "--";
      if (element.tipo && element.tipo.tipo) {
        element.tipoAtendimento = element.tipo.tipo;
      }

      element.atendente = "--";
      if (element.usuario && element.usuario.name) {
        element.atendente = element.usuario.name;
      }

      if (element.atendido != null) {
        // console.log(element.atendido)
        element.atendido = element.atendido.replace("Não informado", "");
      }

      element.pendenciasParaChips = []
      if (typeof element.pendenciaspara == 'string') {
        let divOrg = '';
        element.pendenciaspara.split(',').forEach(siglaDivisaoOrganograma => {
          if (divOrg != siglaDivisaoOrganograma){
            element.pendenciasParaChips.push({
              value: siglaDivisaoOrganograma
            })
          }
          divOrg = siglaDivisaoOrganograma;
        })
      }

      return element;
    },

    preencherAssuntos (atendimento) {
      // console.log('preencherAssuntos()...')
      this.assuntos.forEach((assunto) => {
        // console.log('assunto: ' + assunto.id + ' (' + assunto.assunto + ')')
        let assuntoJaAssociado = false;
        atendimento.assuntos.forEach((assuntoAssociado) => {
          // console.log('assuntoAssociado: ' + assuntoAssociado.id + ' (' + assuntoAssociado.assunto + ')')
          if (!assuntoJaAssociado && assunto.id == assuntoAssociado.id) {
            assuntoAssociado.checked = true;
            assuntoJaAssociado = true;
          }
        });
        if (!assuntoJaAssociado) {
          assunto.checked = false;
          atendimento.assuntos.push(assunto);
        }
      });
      atendimento.assuntos.sort(function (a, b) {
        return a.assunto.localeCompare(b.assunto);
      });
    },

    inserirComentario () {
      let formData = new FormData();
      formData.append("atendimentoid", this.entidadeAtual.id);
      formData.append("comentario", this.novoComentario);
      let url = rotas.atendimentos.comentario.criar.url();
      this.$http.post(url, formData).then(
        (response) => {
          this.novoComentario = "";
          EventBus.mensagem("Comentário registrado com sucesso!");
          this.selecionarParaEdicao(this.entidadeAtual, true);
          },
        (error) => {
          EventBus.alerta(error.body.message);
        }
      );
    },

    sleep (ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    carregarAssuntos () {
      this.assuntos = [];
      let url = rotas.atendimentos.assunto.listar.url();
      this.carregando = true;
      this.$http.get(url).then(
        (response) => {
          response.body.forEach((element) => {
            element.checked = false;
            this.assuntos.push(element);
          });
          this.carregando = false;
        },
        (error) => {
          console.log(error);
          this.carregando = false;
        }
      );
    },

    validouFormulario (val) {
      this.valid = val;
    },

    formularioValido () {
      if (this.valid) return this.valid;
      return false;
    },

    parseDate (dataFormatada) {
      return Utils.toIsoDate(dataFormatada);
    },

    formatarData (data) {
      return Utils.formatarData(data);
    },

    atendimentoConcluido () {
      return (
        this.entidadeAtual != null && this.entidadeAtual.dataHoraFim != null
      );
    },

    atualizarTempoAtendimento () {
      if (this.entidadeAtual) {
        let agora = new Date();
        let inicio = new Date(this.entidadeAtual.dataHoraInicio);
        this.tempoAtendimento = Utils.tempoDecorridoEntre(inicio, agora);
      }
    },

    limparTela () {
      this.tabAtiva = ATENDIMENTO_TAB_ASSUNTOS;
      this.novoComentario = null;

    },

    aplicarMascaraTelefone (valorInput) {
      valorInput = Utils.somenteNumeros(valorInput);
      const posicaoHifen = valorInput.length < 11 ? 6 : 7;
      let novoValor = "";
      for (let posicao = 0; posicao < valorInput.length; posicao++) {
        const caractere = valorInput[posicao];
        let valorAConcatenar = "";
        switch (posicao) {
          case 0:
            valorAConcatenar = "(";
            break;
          case 2:
            valorAConcatenar = ") ";
            break;
          case posicaoHifen:
            valorAConcatenar = "-";
            break;
        }
        novoValor = `${novoValor}${valorAConcatenar}${caractere}`;
      }
      this.entidadeAtual.telefone = novoValor;
    },

    aplicarMascaraCPFouCNPJ (valorInput) {
      valorInput = Utils.somenteNumeros(valorInput);
      if (valorInput.length > 11) {
        valorInput = this.aplicarMascaraCNPJ(valorInput);
      } else {
        valorInput = this.aplicarMascaraCPF(valorInput);
      }
      this.entidadeAtual.cpf = valorInput;
    },

    /**
     * @param {string} valor
     * @returns String contendo o valor com a máscara de CPF aplicada (###.###.###-##).
     */
    aplicarMascaraCPF (valor) {
      let novoValor = "";
      for (let posicao = 0; posicao < valor.length; posicao++) {
        const caractere = valor[posicao];
        let valorAConcatenar = "";
        switch (posicao) {
          case 3:
            valorAConcatenar = ".";
            break;
          case 6:
            valorAConcatenar = ".";
            break;
          case 9:
            valorAConcatenar = "-";
            break;
        }
        novoValor = `${novoValor}${valorAConcatenar}${caractere}`;
      }
      return novoValor;
    },

    /**
     * @param {string} valor
     * @returns String contendo o valor com a máscara de CNPJ aplicada (##.###.###/####-##).
     */
    aplicarMascaraCNPJ (valor) {
      let novoValor = "";
      for (let posicao = 0; posicao < valor.length; posicao++) {
        const caractere = valor[posicao];
        let valorAConcatenar = "";
        switch (posicao) {
          case 2:
            valorAConcatenar = ".";
            break;
          case 5:
            valorAConcatenar = ".";
            break;
          case 8:
            valorAConcatenar = "/";
            break;
          case 12:
            valorAConcatenar = "-";
            break;
        }
        novoValor = `${novoValor}${valorAConcatenar}${caractere}`;
      }
      return novoValor;
    },

    /**
     * Faz requisição da pendência selecionada e prepara a tela para exibição/edição
     * @param {Object} item
     * @returns void
     */
    selecionarPendenciaParaEdicao (item) {
      // this.limparTela();
      this.pendenciaAtual = {};
      if (item.id) {
        let url = rotas.atendimentos.pendencia.obter.url({
          atendimentoid: this.entidadeAtual.id,
          id: item.id,
        });
        this.$http.get(url).then(
          (response) => {
            this.pendenciaAtual = this.formatarPendencia(response.body);
          },
          (error) => {
            EventBus.alerta(error.body.message);
          }
        );
      }
    },

    /**
     * @param {Object} pendencia
     * @returns JSON object com os dados da pendência formatados para exibição na listagem do CRUD.
     */
    formatarPendencia (pendencia) {
      pendencia.criacao = Utils.formatarDataHora(pendencia.created_at);
      pendencia.conclusao = Utils.formatarDataHora(pendencia.datahora_resolucao);
      pendencia.de_nome = pendencia.de.name;
      pendencia.para_nome = pendencia.para != null ? pendencia.para.nome : null
      pendencia.para_sigla = pendencia.para != null ? pendencia.para.sigla : null
      pendencia.concluida_por = pendencia.usuario_conclusao != null ? pendencia.usuario_conclusao.name : null
      return pendencia;
    },

    /**
     * Executa requisição HTTP POST para salvar a pendência.
     *
     * @returns void
     */
    salvarPendencia () {
      return new Promise((resolve, reject) => {
        try {
          let formData = this.preencherFormDataParaSalvarPendencia();
          let url = rotas.atendimentos.pendencia.criar.url({
            atendimentoid: this.entidadeAtual.id,
          });
          this.$http
            .post(url, formData)
            .then(
              () => {
                EventBus.mensagem("Pendência cadastrada com sucesso!");
                resolve()
              },
              (error) => {
                reject(new Error(error.body.message))
              }
            )
        } catch(e) {
          reject(e)
        }
      })
    },

    /**
     * Preenche um FormData com os dados do formulário de cadastro/edição de pendência.
     * 
     * @param {boolean} concluir indica se a pendência deve ser concluída, passando comentario_resolucao.
     * @returns FormData preenchido com os dados do formulário de cadastro/edição de pendência.
     */
    preencherFormDataParaSalvarPendencia (concluir = false) {
      let formData = new FormData();
      if (concluir) {
        formData.append("comentario_resolucao", this.pendenciaAtual.comentario_resolucao);
      } else {
        formData.append("pendencia", this.pendenciaAtual.pendencia);
        formData.append("divisaoorganogramaid_destino", this.pendenciaAtual.divisaoorganogramaid_destino);
      }
      if (this.pendenciaAtual.id != null) {
        formData.append('_method', 'PUT')
      }
      return formData;
    },

    cancelarPendencia () {
      this.$refs.crudPendencias.loadItems();
    },

    /**
     * Prepara a tela para a criação de nova pendência.
     * @returns void
     */
    novaPendencia () {
      this.pendenciaAtual = {};
    },

    /**
     * Recupera a lista de disivões organograma pertencentes à unidade gestora do usuário logado.
     *
     * @returns void
     */
    carregarDivisoesOrganograma () {
      this.carregandoDivisoesOrganograma = true;
      this.divisoesorganograma = [];
      this.$http
        .get(rotas.controleAcesso.organograma.listar.url())
        .then(
          (response) => {
            this.divisoesorganograma = response.body;
          },
          (error) => {
            EventBus.alerta(error);
          }
        )
        .finally(() => {
          this.carregandoDivisoesOrganograma = false;
        });
    },

    /**
     * Prepara e submete requisição para conclusão da pendência atual.
     * @returns void
     */
    concluirPendencia () {
      let formData = this.preencherFormDataParaSalvarPendencia(true);
      let url = rotas.atendimentos.pendencia.editar.url({
        atendimentoid: this.entidadeAtual.id,
        id: this.pendenciaAtual.id
      });
      this.$http
        .post(url, formData)
        .then(
          () => {
            EventBus.mensagem("Pendência concluída com sucesso!");
          },
          (error) => {
            EventBus.alerta(error.body.message);
          }
        )
        .finally(() => {
          this.exibirConclusaoPendencia = false
          this.$refs.crudPendencias.loadItems()
          this.$refs.crudPendencias.exibirGrid = true
        });
    },

    /**
     * Verifica se a tab ativa é a de pendências
     * 
     * @return boolean true, se a this.tabAtiva == ATENDIMENTO_TAB_PENDENCIAS
     */
    isTabPendencias () {
      return this.tabAtiva == ATENDIMENTO_TAB_PENDENCIAS
    }

  },

  watch: {
    dataAtendimentos (val) {
      this.dataAtendimentosFormatada = this.formatarData(val);
    },

  },
  computed: {
    urlBase () {
      console.log('Data pesquisa');
      console.log(this.dataAtendimentos);
      let dataPesquisa = '1900-01-01';
      if(this.dataAtendimentos){
        dataPesquisa = this.dataAtendimentos;
      }
      return rotas.atendimentos.atendimento.porData.url({
        //data: this.dataAtendimentos,
        data: dataPesquisa,
      });
    },
    urlBasePendencias () {
      return rotas.atendimentos.pendencia.url({
        atendimentoid: this.entidadeAtual.id,
      });
    },

    botaoInserirComentarioHabilitado () {
      if (this.novoComentario == null) {
        return false
      }
      return typeof Validador.tamanhoMinimo(this.novoComentario, 8) != 'string' && this.novoComentario.length >= 8;
    }, 
    botaoConcluirPendencia () {
      if (this.pendenciaAtual.comentario_resolucao == null) {
        return false
      }
      return typeof Validador.tamanhoMinimo(this.pendenciaAtual.comentario_resolucao, 8) != 'string' && this.pendenciaAtual.comentario_resolucao.length >= 8;
    },

    computedNomeDoAtendente () {
      if (this.entidadeAtual == null || this.entidadeAtual.usuario == null) {
        return "";
      }
      return this.entidadeAtual.usuario.name;
    },

    computedSituacaoDoAtendimento () {
      if (this.entidadeAtual == null) {
        return "";
      }

      if (this.entidadeAtual.dataHoraFim != null) {
        return "Atendimento encerrado";
      }

      return "Em atendimento " + this.tempoAtendimento;
    },

    podeAlterarDivisaoOrganograma () {
      return this.pendenciaAtual != null && this.pendenciaAtual.id == null;
    },

    podeAlterarDescricaoPendencia () {
      return this.pendenciaAtual != null && this.pendenciaAtual.id == null;
    },

    podeSalvarPendencia () {
      return this.pendenciaAtual != null && this.pendenciaAtual.id == null;
    },

    podeConcluirPendencia () {
      return this.pendenciaAtual != null && this.pendenciaAtual.id != null && this.pendenciaAtual.datahora_resolucao == null;
    }
  },
  mounted () {
    this.carregarAssuntos();
    this.carregarDivisoesOrganograma();
    setInterval(this.atualizarTempoAtendimento, 1000);
    if (this.$route.params.id) {
      this.selecionarParaEdicao({ id: this.$route.params.id });
    }
  },
};
</script>