<template>
  <v-container>
    <v-skeleton-loader
      v-if="this.carregando"
      type="card-heading, button"
    ></v-skeleton-loader>
    <v-card v-else>
      <v-card-title
        >{{ possuiAtendimentoAberto ? "Você está em atendimento" : "Iniciar Atendimento" }}
      </v-card-title>
      <v-card-text>
        <v-row v-if="possuiAtendimentoAberto">
            <v-col class="text-center">
                <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                    color="green"
                    large
                    rounded
                    light
                    @click="continuarAtendimento(dadosAtendente.atendimento_aberto.id)"
                    :disabled="!podeAtenderPresencial"
                    v-on="on"
                    >
                    <v-icon>play_arrow</v-icon>
                    <span class="pa-2"> Continuar </span>
                    </v-btn>
                </template>
                Continuar atendimento
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary darken-1"
                  large
                  rounded
                  light
                  @click="novo(idTipoPresencial)"
                  :disabled="!podeAtenderPresencial"
                  v-on="on"
                >
                  <v-icon>people_outline</v-icon>
                  <span class="pa-2"> Presencial </span>
                </v-btn>
              </template>
              Iniciar atendimento presencial
            </v-tooltip>
          </v-col>
          <v-col class="text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  large
                  rounded
                  @click="novo(idTipoTelefonico)"
                  :disabled="!podeAtenderTelefonico"
                  v-on="on"
                >
                  <v-icon>local_phone</v-icon>
                  <span class="pa-2"> Telefônico </span>
                </v-btn>
              </template>
              Iniciar atendimento telefônico
            </v-tooltip>
          </v-col>
          <v-col class="text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary lighten-1"
                  large
                  rounded
                  @click="novo(idTipoEmail)"
                  :disabled="!podeAtenderEmail"
                  v-on="on"
                >
                  <v-icon>mail_outline</v-icon>
                  <span class="pa-2"> Email </span>
                </v-btn>
              </template>
              Iniciar atendimento de e-mail
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import rotas from "./../../../rotas-servico";
import EventBus from "./../../../event-bus";
export default {
  data: () => {
    return {
      carregando: false,
      dadosAtendente: null,
      idTipoPresencial: 1,
      idTipoTelefonico: 2,
      idTipoEmail: 3,
    };
  },

  methods: {
    novo(idTipoAtendimento) {
      let formData = new FormData();
      formData.append("tipoid", idTipoAtendimento);
      formData.append("atendido", "Não informado");
      this.carregando = true;
      let url = rotas.atendimentos.atendimento.criar.url();
      this.$http.post(url, formData).then(
        (response) => {
          let atendimento = {};
          atendimento.id = response.body.id;
          this.continuarAtendimento(atendimento.id);
          EventBus.mensagem("Atendimento iniciado!");
        },
        (error) => {
          EventBus.alerta(error.body.message);
        }
      )
      .finally(() => {
        this.carregando = false;
      });
    },

    carregarDadosAtendente() {
      this.dadosAtendente = null;
      this.carregando = true;
      let url = rotas.atendimentos.atendente.url();
      this.$http
        .get(url)
        .then(
          (response) => {
            this.dadosAtendente = response.body.atendente;
          },
          (error) => {
            EventBus.alerta(error.body.message);
          }
        )
        .finally(() => {
          this.carregando = false;
        });
    },

    continuarAtendimento(id) {
        this.$router.push("/atendimentos/atendimento/" + id);
    }
  },

  computed: {
    possuiAtendimentoAberto() {
      return (
        this.dadosAtendente != null &&
        this.dadosAtendente.atendimento_aberto != null
      );
    },

    podeAtenderPresencial() {
      if (this.dadosAtendente == null) {
        return false;
      }
      return this.dadosAtendente.permissoes
        .ATENDIMENTO_ATENDIMENTO_CRIAR_PRESENCIAL;
    },

    podeAtenderTelefonico() {
      if (this.dadosAtendente == null) {
        return false;
      }
      return this.dadosAtendente.permissoes
        .ATENDIMENTO_ATENDIMENTO_CRIAR_TELEFONICO;
    },

    podeAtenderEmail() {
      if (this.dadosAtendente == null) {
        return false;
      }
      return this.dadosAtendente.permissoes.ATENDIMENTO_ATENDIMENTO_CRIAR_EMAIL;
    },
  },

  mounted() {
    this.carregarDadosAtendente();
  },
};
</script>

<style>
</style>