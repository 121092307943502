const state = {
    mensagem: '',
    aviso: '',
    alerta: '',
    exibirMenu: true
}

const getters = {
    mensagem: (state) => {
        return state.mensagem
    },
    aviso: (state) => {
        return state.aviso
    },
    alerta: (state) => {
        return state.alerta
    },
    exibirMenu: (state) => {
        return state.exibirMenu
    }
}

const actions = {
}

const mutations = {
    mensagem (state, msg) {
        window.scrollTo(0,0);
        state.mensagem = msg
        state.aviso = ''
        state.alerta = ''
    },
    aviso (state, msg) {
        window.scrollTo(0,0);
        state.mensagem = ''
        state.aviso = msg
        state.alerta = ''
    },
    alerta (state, msg) {
        window.scrollTo(0,0);
        state.mensagem = ''
        state.aviso = ''
        state.alerta = msg
    },
    limpar (state) {
        state.mensagem = ''
        state.alerta = ''
    },
    exibirMenu (state, exibir = null) {
        state.exibirMenu = exibir == null ? !state.exibirMenu : exibir
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }