<template>
  <crud
    v-if="urlBase"
    ref="crud"
    nomeEntidade="Órgão"
    nomeEntidadePlural="Órgãos"
    :headers="cabecalhos"
    :resource-url="urlBase"
    :query-params="parametrosConsultaAPI"
    itemKey="id"
    @clicou-item="selecionarParaEdicao"
    :clicou-salvar="salvar"
    @clicou-cancelar="cancelar"
    @clicou-novo="novo"
  >
    <template slot="beforeAdd">
      <v-switch
        v-if="usuarioTemVisaoTotal"
        label="Listar de todas UGs?"
        v-model="parametrosConsultaAPI.listarDeTodasUGs"
        @change="aplicarListarDeTodasUGs"
      ></v-switch>
    </template>

    <template slot="detalhe">
      <div v-if="entidadeAtual">
        <!-- Órgão -->

        <v-autocomplete
          label="Natureza"
          clearable
          :items="naturezas"
          v-model="entidadeAtual.natureza.id"
          :rules="[validacao.obrigatorio]"
          required
          item-text="natureza"
          item-value="id"
          :loading="carregandoNaturezas"
        />

        <v-autocomplete
          label="Órgão Pai"
          clearable
          :items="orgaosRaiz"
          v-model="entidadeAtual.orgao_pai.id"
          item-text="orgao"
          item-value="id"
          :loading="carregandoOrgaos"
        />

        <v-text-field
          label="Órgão"
          v-model="entidadeAtual.orgao"
          :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(entidadeAtual.orgao, 8)]"
          required
          counter="255"
          maxlength="255"
        />
        <v-text-field
          label="Sigla"
          v-model="entidadeAtual.sigla"
          :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(entidadeAtual.sigla, 2)]"
          required
          counter="50"
          maxlength="50"
        />
        <v-text-field
          label="E-mail"
          v-model="entidadeAtual.email"
          :rules="[validacao.obrigatorio, validacao.email]"
          required
          counter="100"
          maxlength="100"
        />
        <v-text-field
          label="Telefone"
          v-model="entidadeAtual.telefone"
          counter="14"
          maxlength="14"
        />
      </div>
    </template>
  </crud>
</template>

<script>
import rotas from '../../../rotas-servico'
import CRUD from '../../../components/ApiCrud.vue'
import Validador from '../../../validacao';
import EventBus from '../../../event-bus'
import storageBus from '../../../store/StorageBus'

export default {
  components: {
    'crud': CRUD
  },
  data: () => {
    return {
      cabecalhos: [
        {
          text: 'Natureza',
          value: 'naturezaorgao'
        },
        {
          text: 'Órgão Pai',
          value: 'orgaopai_orgao'
        },
        {
          text: 'Órgão',
          value: 'orgao'
        },
        {
          text: 'Sigla',
          value: 'sigla'
        }
      ],
      entidadeAtual: null,
      validacao: Validador,

      orgaosRaiz: [],
      carregandoOrgaos: false,

      naturezas: [],
      carregandoNaturezas: false,

      urlBase: rotas.demandas.orgao.listar.url(),

      usuarioTemVisaoTotal: false,
      parametrosConsultaAPI: {
        listarDeTodasUGs: false,
      }
    }
  },
  methods: {
    selecionarParaEdicao (item) {
      this.carregarOrgaos()
      this.carregarNaturezas()
      this.entidadeAtual = item
      if (this.entidadeAtual.orgao_pai == null) {
        this.entidadeAtual.orgao_pai = {}
      }
      if (this.entidadeAtual.natureza == null) {
        this.entidadeAtual.natureza = {}
      }
      // console.log('Item selecionado: ' + item.id)
    },
    salvar () {
      return new Promise((resolve, reject) => {
        try {
          let formData = new FormData()
          formData.append('orgao', this.entidadeAtual.orgao)
          formData.append('sigla', this.entidadeAtual.sigla)
          formData.append('email', this.entidadeAtual.email)
          formData.append('telefone', this.entidadeAtual.telefone ? this.entidadeAtual.telefone : '')
          if (this.entidadeAtual.orgao_pai.id) {
            formData.append('idorgaopai', this.entidadeAtual.orgao_pai.id)
          }
          if (this.entidadeAtual.natureza.id) {
            formData.append('idnaturezaorgao', this.entidadeAtual.natureza.id)
          }
          if (this.entidadeAtual.id != null) {
            formData.append('_method', 'PUT')
          }

          let url = this.entidadeAtual.id === null ?
            rotas.demandas.orgao.criar.url() :
            rotas.demandas.orgao.editar.url({
              id: this.entidadeAtual.id
            });

          this.$http.post(url, formData)
            .then(
              response => {
                EventBus.mensagem('Órgão cadastrado com sucesso!')
                resolve(response)
              },
              error => {
                  reject(new Error(error.body.message))
              }
            )
        } catch (e) {
          reject(e)
        }
      })
    },
    cancelar () {
      this.$refs.crud.loadItems()
    },
    novo (item) {
      this.carregarOrgaos()
      this.carregarNaturezas()
      this.entidadeAtual = {
        id: null,
        natureza: {},
        orgao_pai: {}
      }
    },

    carregarOrgaos () {
      this.carregandoOrgaos = true
      this.orgaosRaiz = []
      this.$http
        .get(this.urlBase + "?per_page=-1")
        .then(
          response => {
            response.body.forEach(element => {
              this.orgaosRaiz.push(element)
            })
          },
          error => {
            console.log(error)
          }
        )
        .finally(() => {
          this.carregandoOrgaos = false
        })
    },

    carregarNaturezas () {
      this.carregandoNaturezas = true
      this.naturezas = []
      this.$http
        .get(rotas.demandas.naturezaOrgao.listar.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.naturezas.push(element)
            })
          },
          error => {
            console.log(error)
          }
        )
        .finally(() => {
          this.carregandoNaturezas = false
        })
    },

    carregarPermissoesUsuario () {
      this.usuarioTemVisaoTotal = storageBus.usuarioPossuiPermissao("DEMANDA_VISAO_TOTAL")
    },

    aplicarListarDeTodasUGs () {
      this.$refs.crud.loadItems();
    },


  },
  mounted () {
    this.carregarPermissoesUsuario()
  }
}
</script>
