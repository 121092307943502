<template>
  <crud
    v-if="urlBase"
    ref="crud"
    nomeEntidade="Imóvel"
    nomeEntidadePlural="Imóveis"
    :headers="cabecalhos"
    :resource-url="urlBase"
    rota-edicao="DestinacoesEditor"
    :query-params="parametrosConsultaAPI"
    itemKey="id"
    :exibirBotaoCadastrar="cadastrar"
    :podeSalvar="podeSalvar"
    @clicou-item="selecionarParaEdicao"
    :clicou-salvar="salvar"
    @clicou-cancelar="cancelar"
    @clicou-novo="novo"
  >
    <template slot="beforeAdd">
      <v-switch
        v-if="usuarioTemVisaoTotal"
        label="Listar de todas UGs?"
        v-model="parametrosConsultaAPI.listarDeTodasUGs"
        @change="aplicarListarDeTodasUGs"
      ></v-switch>
    </template>
    <template slot="detalhe">
      <v-container fluid>
        <v-layout row wrap>
          <v-flex xs12>
            <v-card>
              <v-card-text>
                <v-tabs v-model="tabAtiva" @change="mudouDeTab">
                  <v-tab>Cadastro </v-tab>
                  <v-tab>Geolocalização 
                    <span v-if="coordenadaAtualizada.length < 36" style='color: red; font-size: 23px; display: inline-block; height: 24px;'> *</span></v-tab>
                  <v-tabs-slider color="primary"></v-tabs-slider>
                  <!-- TAB CADASTRO -->
                  <v-tab-item >
                    <div v-if="entidadeAtual != null" :style="podeSalvar ? {} : {'pointer-events':'none'} ">
                      <v-container fluid class="ma-4 pr-8">
                        <v-layout row wrap >
                          <v-flex lg12>
                            <span>
                              <h3 style="margin-top: 20px; color: rgb(71, 146, 219);">Dados Gerais:
                                <hr style="border-color: rgb(71, 146, 219); color: #4792db;"></h3></span>
                                  <div class="text-right" style="margin-top: -25px; font-size: 12px;" >
                                  <span >Campos com </span>
                                  <span 
                                    style='color: red;  font-size: 28px;  vertical-align: middle;  height: 18px;  display: inline-block;'
                                  >*</span>
                                  <span > são de preenchimento obrigatório!</span>
                                  </div>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex style="margin-top: 15px;" xs12 class="mx-4">
                            <v-text-field label="Denominação área/imóvel (apelido)"  v-model="entidadeAtual.denominacao" 
                              outlined 
                              counter="100" 
                              :rules="[validacao.tamanhoMax(entidadeAtual.denominacao, 100)]" 
                              required  >
                              <template #label>
                                <span>Denominação área/imóvel (apelido) </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                              </template>
                              </v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap class="mx-3">
                          <h3 style="color: #878787; margin-bottom: 5px;">RIP</h3>
                        </v-layout>
                        <v-layout row wrap class="mx-3">
                            <v-col cols="12" sm="6" md="4" lg="2"  
                              v-for="(inputValue, index) in entidadeAtual.rip" 
                              :key="index" 
                              style="background-color: whitesmoke;margin: 3px;">
                              <v-text-field label="RIP"  v-model="entidadeAtual.rip[index]"
                                :rules="[validacao.tamanho(entidadeAtual.rip[index], 13)]" 
                                />
                              <div class="text-right">
                                <a style="color: #f6414d;"  @click="removeInput(index)" ><i class="fa fa-trash"></i></a>
                              </div>
                            </v-col>
                        </v-layout>
                        <v-layout row wrap class="mx-1">
                          <v-flex xs12>
                          <div style="margin-bottom: 5px; margin-top: -20px;" class="text-right">
                            <a @click="addInput" class="adicionar-button"><i class="fa fa-plus-circle "></i> Novo RIP</a>
                          </div>
                        </v-flex>
                        </v-layout>
                        <v-layout row wrap  class="mx-1">
                          <v-flex lg12>
                            <span><hr style="color: #cdc9c9; margin-bottom: 10px; "></span>
                          </v-flex>
                        </v-layout>                        
                        <v-layout row wrap class="mx-3">
                          <h3 style="color: #878787;">Matrícula</h3>
                        </v-layout>
                        <v-layout row wrap class="mx-3">
                            <v-col cols="12" sm="6" md="8" lg="4"
                              v-for="(inputValue, index) in entidadeAtual.matricula" 
                              :key="index" 
                              style="background-color: whitesmoke; margin: 3px;">
                              <v-text-field label="Matrícula"  v-model="entidadeAtual.matricula[index]" 
                                />
                              <div class="text-right">
                                <a style="color: #f6414d;" @click="removeInputMatricula(index)" ><i class="fa fa-trash"></i></a>
                              </div>
                            </v-col>
                        </v-layout>
                        <v-layout row wrap class="mx-1">
                          <v-flex xs12>
                          <div style="margin-bottom: 5px; margin-top: -20px;" class="text-right">
                            <a @click="addInputMatricula" class="adicionar-button"><i class="fa fa-plus-circle "></i> Nova Matrícula</a>
                          </div>
                        </v-flex>
                        </v-layout>                        
                        <v-layout row wrap  class="mx-1">
                          <v-flex lg12>
                            <span><hr style="color: #cdc9c9;  margin-bottom: 10px;"></span>
                          </v-flex>
                        </v-layout>                        

<!-- novos inicio -->   
                        <v-layout row wrap>                          
                          <v-flex lg3 class="mx-4">
                            <v-autocomplete
                              :label="texto"
                              :items="proprietario_presumido"
                              v-model="entidadeAtual.propr_presumida_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoProprietario_presumido"
                              >
                              <template #label>
                                <span>Propriedade </span>
                                <span 
                                  style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'
                                >*</span>
                              </template>
                              </v-autocomplete>
                          </v-flex>
                          <v-flex lg3 class="mx-2">
                            <v-autocomplete
                              :label="texto"
                              :items="grupo_imovel"
                              v-model="entidadeAtual.grupo_imovel_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoGrupo_imovel"
                              >
                              <template #label>
                                <span>Grupo do Imóvel </span>
                                <span 
                                  style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'
                                >*</span>
                              </template>
                              </v-autocomplete>
                          </v-flex>
                          <v-flex lg5 class="mx-4">
                            <v-text-field 
                              label="Uso do solo"
                              v-model="entidadeAtual.uso_solo"
                              counter="100" 
                              :rules="[validacao.tamanhoMax(entidadeAtual.uso_solo, 100)]" 
                            />
                          </v-flex>
                        </v-layout>
                          <v-layout row wrap> 
                            <v-flex lg3 class="mx-4">
                              <v-autocomplete
                                label="Situação da incorporação"
                                :items="sit_incorporacao"
                                v-model="entidadeAtual.sit_incorporacao_id"
                                :rules="[validacao.obrigatorio]"
                                required
                                item-text="nome"
                                item-value="id"
                                :loading="carregandoSit_incorporacao"
                              >
                              <template #label>
                                  <span>Situação da incorporação </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                              </template>  
                              </v-autocomplete>
                            </v-flex>
                            <v-flex lg3 class="mx-2"> 
                            <v-autocomplete
                              label="texto"
                              :items="existe_processo"
                              v-model="entidadeAtual.existe_processo_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoExiste_processo"
                            >
                            <template #label>
                                <span>Existe processo da incorporação? </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>
                            </v-autocomplete>
                          </v-flex>
                          <v-flex lg3 class="mx-2" v-if= "entidadeAtual.existe_processo_id == 1" >
                            <v-text-field 
                            label="Nº processo da incorporação"  
                            v-model="entidadeAtual.no_processo_incorporacao"
                              @value="limpaProcessoIncorporacao"
                              :rules="[validacao.tamanhoSEI(entidadeAtual.no_processo_incorporacao, no_incorporacao_ok)]"
                               />
                          </v-flex>

                        </v-layout>
                        <v-layout row wrap>   
                            <v-flex lg3 class="mx-4"> 
                            <v-text-field 
                            label="texto"  v-model="entidadeAtual.area_terreno" 
                            @input="valorAreaTempoReal"
                              :rules="[validacao.obrigatorio]" 
                              required  >
                              <template #label>
                                <span>Área estimada do terreno(m²) </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>
                            </v-text-field>                              
                          </v-flex>                            
                            <v-flex lg2 class="mx-2">
                            <v-autocomplete
                              label=""
                              :items="possui_benfeitoria"
                              v-model="entidadeAtual.possui_benfeitoria_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoPossui_benfeitoria"
                            >
                            <template #label>
                                <span>Possui benfeitoria </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                              </template>
                              </v-autocomplete>
                          </v-flex>
                          <v-flex lg3 class="mx-2" v-if= "(entidadeAtual.possui_benfeitoria_id == 1)">
                            <v-autocomplete
                              label=""
                              :items="estado_benfeitoria"
                              v-model="entidadeAtual.estado_benfeitoria_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoEstado_benfeitoria"
                            >
                            <template #label>
                                <span>Estado da benfeitoria </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                              </template>
                              </v-autocomplete>

                          </v-flex>
                          <v-flex lg3 class="mx-2" v-if= "(entidadeAtual.possui_benfeitoria_id == 1)">
                            <v-text-field label="Área estimada da benfeitoria(m²)"  v-model="entidadeAtual.area_benfeitoria"
                              @input="areaBenfeitoriaTempoReal" 
                              :rules="[validacao.obrigatorio]"  
                              required
                              >
                              <template #label>
                                <span>Área estimada da benfeitoria(m²) </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                              </template>
                              </v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex lg3 class="mx-4">                              
                            <v-text-field 
                            label="Valor estimado(R$)"  v-model="entidadeAtual.valor_estimado" 
                            @input="editarValorEmTempoReal"
                              :rules="[validacao.obrigatorio]" 
                              required  >
                              <template #label>
                                <span>Valor estimado(R$) </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;;'>*</span>
                            </template>
                            </v-text-field> 
                            </v-flex>

                            <v-flex lg3 class="mx-2">                              
                            <v-text-field 
                            label="Gasto anual de manutenção(R$)"  v-model="entidadeAtual.gasto_anual_imovel" 
                            @input="editarGastoEmTempoReal"
                              :rules="[validacao.obrigatorio]" 
                              required  >
                              <template #label>
                                <span>Gasto anual de manutenção (R$) </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>
                            </v-text-field> 
                            </v-flex>
                            
                      </v-layout>
                      <v-layout row wrap>
                          <v-flex lg12>
                            <span><h3 style="color: #4792db;">Endereço:<hr style="margin-bottom: 10px; color: #4792db;  border-color: rgb(71, 146, 219);"></h3></span>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex lg2 class="mx-4">
                            <v-autocomplete
                              label="Tipo de logradouro"
                              :items="tipo_logradouro"
                              v-model="entidadeAtual.tipo_logradouro_id"
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoTipo_logradouro"
                            />
                          </v-flex>
                          <v-flex lg3 class="mx-4">
                            <v-text-field label="Nome"  v-model="entidadeAtual.descricao_logradouro" 
                            />
                            </v-flex>
                          <v-flex lg1 class="mx-4">
                            <v-text-field label="Número"  v-model="entidadeAtual.no_logradouro" 
                              />
                            </v-flex>
                          <v-flex lg3 class="mx-4">                              
                            <v-text-field label="Complemento"  v-model="entidadeAtual.complemento_logradouro" 
                            />
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex lg2 class="mx-4">
                            <v-text-field label="Bairro"  v-model="entidadeAtual.bairro" 
                            />
                          </v-flex>
                          <v-flex lg1 class="mx-4">
                            <v-text-field label="CEP"  v-model="entidadeAtual.cep" 
                              />
                          </v-flex>
                          <v-flex lg4 class="mx-4"> 
                            <v-autocomplete
                              v-model="entidadeAtual.municipio_id"
                              no-filter
                              :items="municipios"
                              :search-input.sync="search"
                              :loading="carregandoMunicipio"
                              @keyup="carregaMunicipio"
                              color="blue"
                              item-text="text"
                              item-value="value"
                              label="" 
                              :rules="[validacao.obrigatorio]"
                              required      
                            > 
                            <template #label>
                                <span>Município </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>  
                            </v-autocomplete>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>  
                          <v-flex lg12>
                            <span><h3 style="color: #4792db;">Características:<hr style="margin-bottom: 10px; color: #4792db;  border-color: rgb(71, 146, 219);"></h3></span>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>  
                          <v-flex lg3 class="mx-4">  
                            <v-autocomplete
                              label=""
                              :items="conceituacao"
                              v-model="entidadeAtual.conceituacao_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoConceituacao"
                            >
                            <template #label>
                                <span>Conceituação </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>  
                            </v-autocomplete>
                          </v-flex>
                          <v-flex lg2 class="mx-4">  
                            <v-autocomplete
                              label=""
                              :items="natureza"
                              v-model="entidadeAtual.natureza_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoNatureza"
                            >
                            <template #label>
                                <span>Natureza </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>  
                            </v-autocomplete>
                          </v-flex>
                          
                            <v-flex lg2 class="mx-4" v-if= "entidadeAtual.natureza_id == 1" > 
                            <v-autocomplete
                              label="Condição da Urbanização"
                              :items="cond_urbanizacao"
                              v-model="entidadeAtual.cond_urbanizacao_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoCondicao_urbanizacao"
                            >
                            <template #label>
                                <span>Condição da Urbanização </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>  
                          </v-autocomplete>
                            </v-flex> 
                          </v-layout>
                          
                        <v-layout row wrap>  
                          <v-flex lg3 class="mx-4">
                            <v-autocomplete
                              label=""
                              :items="categoria"
                              v-model="entidadeAtual.categoria_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoCategoria"
                            >
                            <template #label>
                                <span>Categoria </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>  
                          </v-autocomplete>
                          </v-flex>
                            <v-flex lg2 class="mx-4" v-if= "entidadeAtual.categoria_id == 3">                              
                            <v-text-field label="Número de blocos"  
                            v-model="entidadeAtual.no_blocos"
                            :rules="[validacao.numerico]"
                            />
                            </v-flex>
                          <v-flex lg2 class="mx-4" v-if= "entidadeAtual.categoria_id == 3 || entidadeAtual.categoria_id == 6">                              
                            <v-text-field label="Número de pavimentos"  
                            v-model="entidadeAtual.no_pavimentos" 
                            :rules="[validacao.numerico]"
                            />
                            </v-flex>
                          </v-layout>
                        <v-layout row wrap>
                          <v-flex lg12>
                            <span><h3 style="color: #4792db;">Ocupação e restrições:<hr style="margin-bottom: 10px; color: #4792db; border-color: rgb(71, 146, 219);"></h3></span>
                          </v-flex>  
                        </v-layout>
                        <v-layout row wrap> 
                          <v-flex lg3 class="mx-4">
                            <v-autocomplete
                              label=""
                              :items="situacao_ocupacao"
                              v-model="entidadeAtual.sit_ocupacao_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoSituacao_ocupacao"
                            >
                            <template #label>
                                <span>Situação da ocupação </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>  
                          </v-autocomplete>
                            </v-flex>
                            <v-flex lg2 class="mx-4" v-if= "entidadeAtual.sit_ocupacao_id == 2 || entidadeAtual.sit_ocupacao_id == 3">
                            <v-autocomplete
                              label=""
                              :items="tipo_ocupacao"
                              v-model="entidadeAtual.tipo_ocupacao_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoTipo_ocupacao"
                            >
                            <template #label>
                                <span>Tipo de ocupação </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>  
                          </v-autocomplete>
                            </v-flex>                                                  
                          <v-flex lg2 class="mx-4" 
                            v-if= "(entidadeAtual.sit_ocupacao_id == 2 || entidadeAtual.sit_ocupacao_id == 3) &&
                            (entidadeAtual.tipo_ocupacao_id == 1 || entidadeAtual.tipo_ocupacao_id == 3)">
                            <v-text-field label=""  
                              v-model="entidadeAtual.qtd_familias_ocupacao"
                              :rules="[validacao.numerico]">
                              <template #label>
                                <span>Quantas famílias ocupam? </span>
                            </template>  
                          </v-text-field>
                            </v-flex>
                            
                          </v-layout>
                        <v-layout row wrap>
  
                        <v-flex lg3 class="mx-4">
                            <v-autocomplete
                              label=""
                              :items="acao_judicial"
                              v-model="entidadeAtual.acao_judicial_id"
                              @change="limpaProcessoJudicialControle"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoAcao_judicial"
                            >
                            <template #label>
                              <span>Ação judicial ou Controle </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>  
                          </v-autocomplete>
                        </v-flex>
                        <v-flex v-if= "entidadeAtual.acao_judicial_id > 0 &&  entidadeAtual.acao_judicial_id < 5" lg3 class="mx-4">
                            <v-text-field v-if= "entidadeAtual.acao_judicial_id > 0 &&  entidadeAtual.acao_judicial_id < 4" 
                            v-model="entidadeAtual.no_processo_judicial" 
                              @value="validaProcessoControle"
                              :rules="[validacao.obrigatorio]"
                              required
                              >
                              <template #label>
                                <span>Nº Processo de Controle </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                              </template>  
                            </v-text-field>
                            <v-text-field v-if= "entidadeAtual.acao_judicial_id == 4" 
                            v-model="entidadeAtual.no_processo_judicial" 
                              @value="validaProcessoJudicial"
                              :rules="[validacao.tamanhoCNJ(entidadeAtual.no_processo_judicial, dvOk)]"
                              required
                              >
                              <template #label>
                                <span>Nº Processo Judicial </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                              </template>  
                            </v-text-field>
                        </v-flex>

                          <v-flex lg2 class="mx-4">
                            <v-autocomplete
                              label=""
                              :items="conflito"
                              v-model="entidadeAtual.conflito_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoConflito"
                            >
                            <template #label>
                                <span>Conflito </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>  
                          </v-autocomplete>
                            </v-flex>
                          </v-layout>
                            <v-layout row wrap>                            
                          <v-flex lg12 class="mx-4">
                            <v-text-field label="Providência necessária"  
                              v-model="entidadeAtual.providencia" 
                              counter="300" 
                              :rules="[validacao.tamanhoMax(entidadeAtual.providencia, 300)]"
                              outlined
                              />
                            </v-flex>
                            </v-layout>
                            <v-layout row wrap>
                          <v-flex lg12>
                            <span><h3 style="color: #4792db;">Destinação:<hr style="margin-bottom: 10px; color: #4792db; border-color: rgb(71, 146, 219);"></h3></span>
                          </v-flex>  
                        </v-layout>


<!-- novos fim -->
                        <v-layout row wrap>
                          <v-flex lg3 class="mx-4"> 
                            <v-autocomplete
                              label="texto"
                              :items="existe_processo_destinacao"
                              v-model="entidadeAtual.existe_processo_destinacao_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoExiste_processo_dest"
                            >
                            <template #label>
                                <span>Existe processo da destinação? </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>
                            </v-autocomplete>
                          </v-flex>
                          <v-flex lg2 class="mx-2" v-if= "entidadeAtual.existe_processo_destinacao_id == 1" >
                            <v-text-field 
                            label="Nº processo da destinação"  
                            v-model="entidadeAtual.no_processo_destinacao"
                              @value="limpaProcessoDestinacao"
                              :rules="[validacao.tamanhoSEI(entidadeAtual.no_processo_destinacao, no_destinacao_ok)]"
                               />
                          </v-flex>
                          <v-flex lg3 class="mx-4">
                            <v-autocomplete
                              label=""
                              :items="entrada_imovel"
                              v-model="entidadeAtual.entrada_imovel_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoEntrada_imovel"
                            >
                            <template #label>
                                <span>Entrada do Imóvel </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>  
                          </v-autocomplete>
                          </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                          <v-flex lg3 class="mx-4">
                            <v-autocomplete
                              label=""
                              :items="linha_programa"
                              v-model="entidadeAtual.linha_programa_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoEixo"
                              @change="carregaFinalidade_destinacao" 
                            >
                            <template #label>
                                <span>Linha do Programa </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>  
                          </v-autocomplete>
                          </v-flex>
                          <v-flex lg4 class="mx-4">
                            <v-autocomplete
                              label=""
                              :items="finalidade_destinacao"
                              v-model="entidadeAtual.util_especifica_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoFinalidade_destinacao"
                            >
                            <template #label>
                                <span>Utilização específica </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>  
                          </v-autocomplete>
                          </v-flex> 

                        </v-layout>
                          <v-layout row wrap>
                            <v-flex lg3 class="mx-4">
                            <v-autocomplete
                              label=""
                              :items="tipo_interessado"
                              v-model="entidadeAtual.tipo_interessado_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoTipo_interessado"
                            >
                            <template #label>
                                <span>Tipo de interessado </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>  
                          </v-autocomplete>
                            </v-flex>
                          
                          <v-flex lg4 class="mx-4">
                            <v-text-field 
                              label="Nome do interessado"  
                              v-model="entidadeAtual.nome_interessado" 
                              counter="150" 
                              :rules="[validacao.tamanhoMax(entidadeAtual.nome_interessado, 150)]" 
                              required 
                              />
                            </v-flex>
                            <v-flex lg4 class="mx-4">
                            <v-text-field label="Interlocução/Parceiro"  v-model="entidadeAtual.interlocucao_parceiros" 
                              />
                            </v-flex>

                        </v-layout>
                        <v-layout row wrap> 
                          <v-flex lg3 class="mx-4">
                            <v-autocomplete
                              label=""
                              :items="instrumento_destinacao"
                              v-model="entidadeAtual.instrumento_destinacao_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoInstrumento_destinacao"
                            >
                            <template #label>
                                <span>Instrumento de Destinação </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>  
                          </v-autocomplete>
                          </v-flex>  
                          <v-flex lg3 class="mx-4">
                            <v-autocomplete
                              label=""
                              :items="programa_especifico"
                              v-model="entidadeAtual.programa_especifico_id"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="nome"
                              item-value="id"
                              :loading="carregandoPrograma_especifico"
                            >
                            <template #label>
                                <span>Programa Específico Atendido </span><span style='color: red; font-size: 23px; display: inline-block; vertical-align: middle;'>*</span>
                            </template>  
                          </v-autocomplete>
                          </v-flex>
                          <v-flex lg2 class="mx-4" v-if= "(entidadeAtual.linha_programa_id == 2 || entidadeAtual.linha_programa_id == 3)">
                            <v-text-field label="Número de famílias beneficiadas"  
                            v-model="entidadeAtual.num_familias_beneficiadas" 
                            :rules="[validacao.numerico]"
                            />
                            </v-flex>                                          
                        </v-layout>

                        <v-layout row wrap>  
                        </v-layout>

                        <v-layout row wrap>
                        </v-layout>

                        <v-layout row wrap>
                        </v-layout>

                        <v-layout row wrap>
                          <v-flex lg12>
                            <span><h3 style="color: #4792db;">Informações adicionais:<hr style="margin-bottom: 10px; color: #4792db; border-color: rgb(71, 146, 219);"></h3></span>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap >
                          <v-flex lg12 class="mx-4">
                            <v-textarea  label="Observações"  v-model="entidadeAtual.obs" rows="3"
                            counter="600" 
                            :rules="[validacao.tamanhoMax(entidadeAtual.obs, 600)]"
                            no-resize style="margin-top: 10px;"
                            outlined
                                />
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex lg12 >   
                            <span><h3 style="color: #4792db;">Imagens:<hr style="margin-bottom: 10px; color: #4792db; border-color: rgb(71, 146, 219);"></h3></span>
                          </v-flex>
                        </v-layout>
                          <v-layout row wrap>
                          <v-flex lg12 class="mx-4">                              
                            <input 
                              ref="fileInput" 
                              name="fotos" 
                              multiple 
                              accept=".jpeg,.jpg,.png,.gif"
                              type="file"
                              @change="handleFileChange" 
                              style="display: none">
                            <div v-if="imageUrls.length > 0" style="display: block;">
                              <span>Imagens selecionadas:</span>
                            </div>
                            <div v-if="imageUrls.length > 0">
                              <div v-if="imageUrls.length > 0" class="image-grid">
                                <div v-for="(imageUrl, index) in imageUrls" :key="index" class="image-container">
                                  <img :src="imageUrl" alt="Imagem Carregada" style="width: 250px; height: auto;  border: 1px solid #ccc;">
                                  <span>
                                    <i @click="removeFile(index)" title="Remover" class="fa fa-trash"></i> 
                                    <i class="icon-space"></i>
                                    <i @click="daCapaSel(index)" v-if="fotoCapaSel != index" title="Foto da Capa" class="fa fa-newspaper-o"></i>
                                    <span v-if="fotoCapaSel == index">Da Capa</span>
                                  </span>
                                </div>
                              </div>
                              <span><hr style="margin-bottom: 5px;"></span>
                            </div>
                            <!-- Exibir imagens da base de dados -->
                            <div v-if="databaseImages.length > 0" >
                              <span>Imagens já cadastradas:</span>
                            </div>
                            <div v-if="databaseImages.length > 0" class="image-grid">
                              <div v-for="(databaseImage, index) in databaseImages" :key="index" class="image-container">
                                <img :src="databaseImage" alt="Imagem da Base de Dados" style="width: 250px; height: auto;  border: 1px solid #ccc;">
                                <span>
                                  <i @click="removeFileBase(index)" title="Remover" class="fa fa-trash"></i>
                                  <i class="icon-space"></i>
                                  <i @click="daCapaBase(index)" v-if="fotoCapaBase != index" title="Foto da Capa" class="fa fa-newspaper-o"></i>
                                  <span v-if="fotoCapaBase == index">Da Capa</span>
                                </span>
                              </div>
                            </div>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex xs12>
                            <div class="text-right" style="margin-right: 15px;">
                              <a  @click="openFileInput"><span class="adicionar-button" ><i class="fa fa-camera"></i> Mais Imagens</span></a>
                            </div>

                          </v-flex>
                        </v-layout>
                        <v-layout row wrap class="text-center">
                          <v-flex xs12>
                          <div style="margin-top: 15px; margin-bottom: -18x; margin-right: 15px; background-color: #d7d7d7;">
                          </div>
                        </v-flex>
                        </v-layout>
                        <v-layout row wrap class="text-center">
                          <v-flex xs12 v-if= "(coordenadaAtualizada.length < 36)">
                          <v-text-field label="Coordenadas"  v-model="coordenadaAtualizada" 
                            :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(coordenadaAtualizada, 35)]"
                            v-show=false required/>  
                          <div style="margin-top: 1; margin-bottom: -18x; margin-right: 15px; background-color: #d7d7d7;">
                            <span   style='color: red; padding-right: 5px;'>Atenção!</span><span >Polígono não incluído na aba GEOLOCALIZAÇÃO</span>
                          </div>
                        </v-flex>
                        </v-layout>                       
                      </v-container>
                    </div>
                  </v-tab-item>
                  <!-- Fim da TAB CADASTRO -->

                  <!-- TAB GEORREFERENCIAMENTO -->
                  <v-tab-item>
                    <div :style="podeSalvar ? {} : {'pointer-events':'none'} ">
                    <v-text-field label="Coordenadas"  v-model="coordenadaAtualizada" 
                    :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(coordenadaAtualizada, 35)]"
                    v-show=false required/>                    
                    <v-container fluid :style="podeSalvar ? {} : {'pointer-events':'none'} ">
                      <geo-imoveis
                      :style="podeSalvar ? {} : {'pointer-events':'none'} "
                        v-if="entidadeAtual"
                        :tabelaReferenciaCamada="tabelaReferenciaCamada"
                        :idReferenciado="entidadeAtual.id"
                        :coordenadas="coordenadas"
                        :unidadeGestora="unidadeGestora"
                        :podeEditar="podeEditar"
                        @atualizarCoordenadas="atualizarCoordenadas"
                      ></geo-imoveis>
                      <p style="margin-left: 15px;  margin-bottom: 10px; font-size: 12px;">
                        <b>Nota:</b> a geolocalização disponibilizada não tem valor cartográfico ou topográfico compatível com rigores de medições de áreas de propriedades, sendo aqui utilizadas como fonte de planejamento para a gestão do Programa de Democratização dos Imóveis da União.</p>
                    </v-container>
                  </div>                  
                  </v-tab-item>
                  <!-- Fim da TAB GEORREFERENCIAMENTO -->
                </v-tabs>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </crud>
</template>

<script>
import 'font-awesome/css/font-awesome.css';
import rotas from '../../rotas-servico'
import CRUD from '../ApiCrud.vue'
import Validador from '../../validacao';
import EventBus from '../../event-bus'
import storageBus from '../../store/StorageBus'

const PROCEDIMENTO_TAB_CADASTRO = 0;
const PROCEDIMENTO_TAB_GEORREFERENCIAMENTO = 1;
export default {
  components: {
    'crud': CRUD,
  },
  data: () => {
    return {
      tabelaReferenciaCamada: '',
      atualizarMapa: false,
      tabAtiva: 0,
      urlBase: rotas.destinacoes.destinacoes.url(),
      cabecalhos: [
        { text: 'UF', value: "estado_descricao", class: 'destinacao-top', sortable:true, origem:'municipio.estado.descricao'},
        { text: 'Município', value: 'municipio_cidade', class:"destinacao-top", sortable:true, origem:'municipio.cidade'},
        { 
          text: 'Denominação', 
          value: 'denominacao', 
          subheader: 'form_created_at',
          class:"destinacao-top", 
          sortable:true, 
          origem:'denominacao'
        },
        { 
          text: 'Área (m²)', 
          value: 'area', 
          class: "destinacao-top-area destinacao-top", 
          type: "number",
          sortable:true, 
          origem:'area'
        },
        { 
          text: 'Categoria', 
          value: 'categoria_nome', 
          class:"destinacao-top", 
          sortable:true, 
          origem:'categoria.nome'
        },
        { text: 'Linha do Programa', value: 'linha_programa_nome', class:"destinacao-top", sortable:true, origem:'linha_programa.nome'},
        { text: 'Utilização específica', value: 'util_especifica_nome', class:"destinacao-top", sortable:true, origem:'util_especifica.nome'},
      ],
      dvOk: false,
      no_destinacao_ok:false,
      no_incorporacao_ok:false,
      cadastrar: true,
      entidadeAtual: null,
      validacao: Validador,
      proprietario_presumido: [],
      categoria: [],
      conceituacao: [],
      conflito: [],
      tipo_logradouro: [],
      municipios: [],
      propr_presumida:[],
      grupo_imovel:[],
      sit_incorporacao: [],
      natureza: [],
      cond_urbanizacao: [],
      tipo_ocupacao:[],
      existe_processo: [],
      programa_especifico:[],
      existe_processo_destinacao: [],
      estado_benfeitoria: [],
      possui_benfeitoria: [],
      acao_judicial: [],
      situacao_ocupacao: [],
      tipo_interessado: [],
      linha_programa:[],
      isSelected: false,
      selectedItemsSel: [] ,
      selectedItemsbase: [] ,
      instrumento_destinacao: [],
      finalidade_destinacao: [],
      municipio: null,
      search: '',
      propriedadePresumidaLabel: "*",
      texto: "SeuTextoAqui ",
      campoModificado: false,
      carregandoMunicipio: false,
      carregandoAcao_judicial: false,
      carregandoSit_incorporacao:false,
      carregandoTipo_logradouro: false,
      carregandoProprietario_presumido: false,
      carregandoGrupo_imovel: false,
      carregandoCondicao_urbanizacao: false,
      carregandoEstado_benfeitoria: false,
      carregandoPossui_benfeitoria: false,
      carregandoFinalidade_destinacao: false,
      carregandoNatureza: false,
      carregandoSituacao_ocupacao: false,
      carregandoTipo_interessado: false,
      carregandoTipo_ocupacao: false,
      carregandoCategoria: false,
      carregandoConceituacao: false,
      carregandoExiste_processo: false,
      carregandoExiste_processo_dest:false,
      carregandoEntrada_imovel:false,
      carregandoConflito: false,
      carregandoEixo: false,
      carregandoInstrumento_destinacao: false,
      carregandoPrograma_especifico:false,
      dest_sede: false,
      dest_regional: false,
      unidadeGestora: null,
      unidadeGestora_id: null,
      podeEditar: false,
      coordenadas: [],
      coordenadaAtualizada:[],
      usuarioTemVisaoTotal: false,
      parametrosConsultaAPI: {
        listarDeTodasUGs: false,
      },
      selectedFiles: [], 
      imageUrls: [], 
      databaseImages: [],
      indexCapaSel:null,
      indexCapaBase:null,
      novoFile: [],
      fotosSalvas: [],
      imageUrl: null,
      valor_formatado:'',
    }
  },
  computed: {
    podeSalvar () {
      this.dest_sede =  storageBus.usuarioPossuiPermissao('DESTINACOES_DESTINACAO_SEDE')
      this.dest_regional = storageBus.usuarioPossuiPermissao('DESTINACOES_DESTINACAO_REGIONAL')
      this.cadastrar = false
      if (this.dest_regional === true || this.dest_sede===true){
        this.cadastrar = true
      }
      if (this.entidadeAtual != null && typeof this.entidadeAtual != 'undefined') {
        if ((this.dest_regional === true && (this.unidadeGestora_id == this.entidadeAtual.unidadegestora_id)) || (this.dest_sede===true)){
          this.podeEditar = true
          return true
        }
        else{
          this.podeEditar = false
          return false
        }
      }
      this.podeEditar = false
      return false
    },
    fotoCapaBase (){
      return this.indexCapaBase 
    },
    fotoCapaSel (){
      return this.indexCapaSel 
    },

    validaProcessoJudicial(){
      let numero = ''
      if (this.entidadeAtual.no_processo_judicial){
        numero = this.entidadeAtual.no_processo_judicial.replace(/[^0-9]/g, '');
      }
      let num = ''
      let dv = ''
      let ano = ''
      let tr = ''
      let uf = ''
      let uo = ''
      let resto = 0
      this.dvOk = false
      if (numero.length == 20){
        num = numero.substring(0, 7);
        dv = numero.substring(7, 9);
        ano = numero.substring(9, 13);
        tr = numero.substring(13, 14);
        uf = numero.substring(14, 16);
        uo = numero.substring(16, 20);
        resto = Number(num)%97
        resto = Number(resto+ano+tr+uf)%97
        resto = Number(resto+uo+'00')%97       
        if (dv ==(98 - resto) &&  numero.length == 20){
          this.dvOk = true
          this.entidadeAtual.no_processo_judicial = 
            numero.slice(0, 7) + '-'+ numero.slice(7, 9) + '.'+ numero.slice(9, 13)+ '.'+ numero.slice(13, 14) + '.'+ numero.slice(14, 16) + '.'+ numero.slice(16, 20);
        }
      }      
      return `${numero}`;
    },

    validaProcessoControle(){
      let numero = ''
      if (this.entidadeAtual.no_processo_judicial && this.entidadeAtual.no_processo_judicial.length > 0){
        numero = this.entidadeAtual.no_processo_judicial.replace(/[^0-9]/g, '');
      }
      return `${numero}`;
    },
    limpaProcessoDestinacao(){
      let numero = ''
      this.no_destinacao_ok = true;
      
      if (this.entidadeAtual.no_processo_destinacao && this.entidadeAtual.no_processo_destinacao.length>0){
        this.no_destinacao_ok = false;
      }
      if (this.entidadeAtual.no_processo_destinacao){
        numero = this.entidadeAtual.no_processo_destinacao.replace(/[^0-9]/g, '');
        if (numero.length == 17){
          this.no_destinacao_ok = true;
          this.entidadeAtual.no_processo_destinacao = numero.slice(0, 5) + '.'+ numero.slice(5, 11) + '/'+ numero.slice(11, 15) + '-'+ numero.slice(15, 17);
        }
      }

      return `${this.entidadeAtual.no_processo_destinacao }`;

    },
    limpaProcessoIncorporacao(){
      this.no_incorporacao_ok = true;
      
      if (this.entidadeAtual.no_processo_incorporacao && this.entidadeAtual.no_processo_incorporacao.length>0){
        this.no_incorporacao_ok = false;
      }
      let numero = ''
      if (this.entidadeAtual.no_processo_incorporacao){
        numero = this.entidadeAtual.no_processo_incorporacao.replace(/[^0-9]/g, '');
        if (numero.length == 17){
          this.no_incorporacao_ok = true;
          this.entidadeAtual.no_processo_incorporacao = numero.slice(0, 5) + '.'+ numero.slice(5, 11) + '/'+ numero.slice(11, 15) + '-'+ numero.slice(15, 17);
        }
      }
      return `${this.entidadeAtual.no_processo_incorporacao}`;
    },
  },

  methods: {
    campoVazio() {
      
      this.campoModificado = true; 
    },
    removeInputMatricula(index){
      this.entidadeAtual.matricula.splice(index, 1);
    },
    addInputMatricula(index){
      this.entidadeAtual.matricula.push(''); 
    },
    addInput() {
      this.entidadeAtual.rip.push(''); 
    },
    removeInput(index) {
      this.entidadeAtual.rip.splice(index, 1); 
    },
    formatarValor() {
      this.valorFormatado = this.valorFormatado.replace(/[^0-9.]/g, '');
      const partes = this.valorFormatado.split('.');
      if (partes.length > 1) {
        partes[1] = partes[1].slice(0, 2); 
      }
      this.valorFormatado = partes.join('.');
      
    },
    limpaProcessoJudicialControle(){
      if (this.entidadeAtual.no_processo_judicial){
        this.entidadeAtual.no_processo_judicial = ''
      }
      
    },
    editarValorEmTempoReal() {
      if ( this.entidadeAtual.valor_estimado.length > 0){
        this.valorEstimadoFormatado()
      }
      else{
        this.entidadeAtual.valor_estimado = "0,00"
      }
    },
    valorEstimadoFormatado() {
      this.entidadeAtual.valor_estimado = (parseFloat(this.entidadeAtual.valor_estimado.replace(/\D+/g, '')) / 100).toFixed(2);
      let numero = '0.00'
      if (this.entidadeAtual.valor_estimado.length > 0){
        numero = this.entidadeAtual.valor_estimado.replace(/[^0-9.]/g, '');
      }
      if (numero.length == 0){
        this.entidadeAtual.valor_estimado = '0,00'
      }
      else{
        const numeroFormatado = numero;
        const [parteInteira, parteDecimal] = numeroFormatado.split('.');
        const parteInteiraFormatada = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        this.entidadeAtual.valor_estimado = parteInteiraFormatada +','+ parteDecimal;
        }
      return this.entidadeAtual.valor_estimado ;
    },

    editarGastoEmTempoReal() {
      if ( this.entidadeAtual.gasto_anual_imovel.length > 0){
        this.gastoFormatado()
      }
      else{
        this.entidadeAtual.gasto_anual_imovel = "0,00"
      }
    },
    gastoFormatado() {
      this.entidadeAtual.gasto_anual_imovel = (parseFloat(this.entidadeAtual.gasto_anual_imovel.replace(/\D+/g, '')) / 100).toFixed(2);
      let numero = '0.00'
      if (this.entidadeAtual.gasto_anual_imovel.length > 0){
        numero = this.entidadeAtual.gasto_anual_imovel.replace(/[^0-9.]/g, '');
      }
      if (numero.length == 0){
        this.entidadeAtual.gasto_anual_imovel = '0,00'
      }
      else{
        const numeroFormatado = numero;
        const [parteInteira, parteDecimal] = numeroFormatado.split('.');
        const parteInteiraFormatada = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        this.entidadeAtual.gasto_anual_imovel = parteInteiraFormatada +','+ parteDecimal;
        }
      return this.entidadeAtual.gasto_anual_imovel ;
    },
    valorAreaTempoReal() {
      if ( this.entidadeAtual.area_terreno.length > 0){
        this.valorAreaFormatado()
        return `${this.entidadeAtual.area_terreno }`;
      }
      else{
        this.entidadeAtual.area_terreno = "0,00"
        return `${this.entidadeAtual.area_terreno }`;
      }
    },
    valorAreaFormatado() {
      this.entidadeAtual.area_terreno = (parseFloat(this.entidadeAtual.area_terreno.replace(/\D+/g, '')) / 100).toFixed(2);
      let numero = '0.00'
      if (this.entidadeAtual.area_terreno.length > 0){
        numero = this.entidadeAtual.area_terreno.replace(/[^0-9.]/g, '');
      }
      if (numero.length == 0){
        this.entidadeAtual.area_terreno = '0,00'
      }
      else{
        const numeroFormatado = numero;
        const [parteInteira, parteDecimal] = numeroFormatado.split('.');
        const parteInteiraFormatada = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        this.entidadeAtual.area_terreno = parteInteiraFormatada +','+ parteDecimal;
        }
      return `${this.entidadeAtual.area_terreno }`;
      //return this.entidadeAtual.area_terreno ;
    },

    areaBenfeitoriaTempoReal() {
      if ( this.entidadeAtual.area_benfeitoria.length > 0){
        this.areaBenfeitoria()
      }
      else{
        this.entidadeAtual.area_benfeitoria = "0,00"
      }
    },

    areaBenfeitoria() {
      this.entidadeAtual.area_benfeitoria = (parseFloat(this.entidadeAtual.area_benfeitoria.replace(/\D+/g, '')) / 100).toFixed(2);
      let numero = '0.00'
      if (this.entidadeAtual.area_benfeitoria.length > 0){
        numero = this.entidadeAtual.area_benfeitoria.replace(/[^0-9.]/g, '');
      }
      if (numero.length == 0){
        this.entidadeAtual.area_benfeitoria = '0,00'
      }
      else{
        const numeroFormatado = numero;
        const [parteInteira, parteDecimal] = numeroFormatado.split('.');
        const parteInteiraFormatada = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        this.entidadeAtual.area_benfeitoria = parteInteiraFormatada +','+ parteDecimal;
        }
        return `${this.entidadeAtual.area_benfeitoria }`;
      //return this.entidadeAtual.area_benfeitoria ;
    },

    atualizarCoordenadas (coords) {
      let result = ''
      if (coords.length > 0){
        coords[0].forEach(function(item) {
          result = result + item[0]+" "+item[1]+", "
        });
        result = result.slice(0, -1)
      }
      this.coordenadaAtualizada = "GEOMETRYCOLLECTION (POLYGON ((" + result.slice(0, -1) + ")))";
    },
    openFileInput() {
    this.$refs.fileInput.click()
    },

    handleFileChange() {
      this.selectedFiles = Array.from(this.$refs.fileInput.files);
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.novoFile.push(this.selectedFiles[i]);
        const imageUrl = URL.createObjectURL(this.selectedFiles[i]);
        this.imageUrls.push(imageUrl);
        this.selectedItemsSel.push(false);
      }
      event.target.value = null;     
    },
    removeFile(index) {
      if (index == this.indexCapaSel){
        this.indexCapaSel = null
      }
      if (index < this.indexCapaSel){
        this.indexCapaSel = this.indexCapaSel - 1
      }
      this.imageUrls.splice(index, 1);
      this.novoFile.splice(index, 1);
      this.selectedItemsSel.splice(index, 1);
    },   
    removeFileBase(index) {
      if (index == this.indexCapaBase){
        this.indexCapaBase = null
      }
      if (index < this.indexCapaBase){
        this.indexCapaBase = this.indexCapaBase -1
      }
      this.databaseImages.splice(index, 1);
      this.fotosSalvas.splice(index, 1);
      this.selectedItemsBase.splice(index, 1);
    },
    daCapaBase(index){
      this.selectedItemsBase[index] = true;
      this.indexCapaSel = null
      this.indexCapaBase = index
    },
    daCapaSel(index){
      this.selectedItemsSel[index] = true;
      this.indexCapaSel = index     
      this.indexCapaBase = null
  
    },
    async selecionarParaEdicao (item) {
      this.unidadeGestora = storageBus.getUnidadeGestora().sigla
      this.unidadeGestora_id = storageBus.getUnidadeGestora().id
      this.dest_sede =  storageBus.usuarioPossuiPermissao('DESTINACOES_DESTINACAO_SEDE')
      this.dest_regional = storageBus.usuarioPossuiPermissao('DESTINACOES_DESTINACAO_REGIONAL')
      this.coordenadas = []
      this.selectedItemsBase = []
      this.selectedItemsSel = []
      this.imageUrl = [],
      this.imageUrls = [],
      this.databaseImages = [],
      this.novoFile = [],
      this.fotosSalvas = [],
      this.indexCapaBase = null,
      this.indexCapaSel = null,
      this.tabAtiva = PROCEDIMENTO_TAB_CADASTRO
      let response = await this.$http.get(rotas.destinacoes.consultar.url({
        id: item.id
      }));
      if (response.status == 200) {

        this.municipios = [];
        this.fotosSalvas = [],
        this.municipios.push({
          'text':response.body.municipio.cidade.trim() + "/"+ response.body.municipio.estado.descricao, 
          'value':response.body.municipio.id, 
          "estado_id":response.body.municipio.estado.id});
        this.entidadeAtual = response.body
        let apuUrl =  process.env.VUE_APP_API_URL.replace('api/','')
        if (this.entidadeAtual.linha_programa_id){
          this.carregaFinalidade_destinacao ();
        }
        let rips = []
        let matriculas = []
          for (let i = 0; i < this.entidadeAtual.rip.length; i++) {
            if (this.entidadeAtual.rip[i] != null){
              rips.push(this.entidadeAtual.rip[i]);
            }
          }
          for (let i = 0; i < this.entidadeAtual.matricula.length; i++) {
            if (this.entidadeAtual.matricula[i] != null){
              matriculas.push(this.entidadeAtual.matricula[i]);
            }
          }
        this.entidadeAtual.rip = rips
        this.entidadeAtual.matricula  = matriculas
        if (this.entidadeAtual.fotos){
          this.indexCapaBase = this.entidadeAtual.foto_capa
          for (let i = 0; i < this.entidadeAtual.fotos.length; i++) {
            this.fotosSalvas.push(this.entidadeAtual.fotos[i]) 
            this.selectedItemsBase.push(false);
            this.databaseImages.push(apuUrl+'destinacoes/'+this.entidadeAtual.fotos[i])
          }
        }
        this.coordenadaAtualizada = this.entidadeAtual.coordenadas
        if (this.entidadeAtual.coordenadas){
          let result = this.entidadeAtual.coordenadas.replace("GEOMETRYCOLLECTION (POLYGON ((", "");
          result = result.replace(")))", "");
          result = result.replaceAll(', ',',');
          result = result.split(",");
          let result3 = [];
          let result2 = [];
          if (result[0].length > 0){
            result.forEach(function(item) {
              result2 = item.split(" ")
              if (result2.length == 2){
                result2[0]= Number(result2[0])
                result2[1]= Number(result2[1])
                result3.push(result2)
              }
            });
            let result4 = [];
            result4.push(result3)
            this.coordenadas = result4
          }
          else{
            this.coordenadas = []
          }
        }
        this.atualizarMapa = true
      } else {
        console.log(response)
      }
      this.valorAreaFormatado()
      this.valorEstimadoFormatado()
      this.areaBenfeitoria()
      this.gastoFormatado()
    },

    salvar () {
      return new Promise((resolve, reject) => {
        try {
          let campoNull = null;
          let formData = new FormData()
          formData.append('id', this.entidadeAtual.id)
          formData.append('denominacao', this.entidadeAtual.denominacao)
          if (this.indexCapaBase != null){
            formData.append('foto_capa', this.indexCapaBase)
          }
          else{
              let FC = this.fotosSalvas.length + this.indexCapaSel
              formData.append('foto_capa', FC)
          }
          if (this.entidadeAtual.rip.length == 0){
            formData.append('rip[]', '');
          }
          else{
            for (let i = 0; i < this.entidadeAtual.rip.length; i++) {
              formData.append('rip[]', this.entidadeAtual.rip[i]);
            }
          }
          if (this.entidadeAtual.matricula.length == 0){
            formData.append('matricula[]', '');
          }
          else{
            for (let i = 0; i < this.entidadeAtual.matricula.length; i++) {
              formData.append('matricula[]', this.entidadeAtual.matricula[i]);
            }
          }

          if (this.entidadeAtual.tipo_logradouro_id){
            formData.append('tipo_logradouro_id', this.entidadeAtual.tipo_logradouro_id)
          }
          if (this.entidadeAtual.propr_presumida_id){ 
            formData.append('propr_presumida_id', this.entidadeAtual.propr_presumida_id)}
          if (this.entidadeAtual.existe_processo_id){ 
            formData.append('existe_processo_id', this.entidadeAtual.existe_processo_id)}

          if (this.entidadeAtual.existe_processo_destinacao_id){ 
            formData.append('existe_processo_destinacao_id', this.entidadeAtual.existe_processo_destinacao_id)
          }

          if (this.entidadeAtual.categoria_id){ 
            formData.append('categoria_id', this.entidadeAtual.categoria_id)}
          if (this.entidadeAtual.natureza_id){ 
            formData.append('natureza_id', this.entidadeAtual.natureza_id)}
          if (this.entidadeAtual.cond_urbanizacao_id){ 
            formData.append('cond_urbanizacao_id', this.entidadeAtual.cond_urbanizacao_id)}
          if (this.entidadeAtual.estado_benfeitoria_id){ 
            formData.append('estado_benfeitoria_id', this.entidadeAtual.estado_benfeitoria_id)}
          if (this.entidadeAtual.acao_judicial_id){ 
            formData.append('acao_judicial_id', this.entidadeAtual.acao_judicial_id)}
          if (this.entidadeAtual.sit_ocupacao_id){ 
            formData.append('sit_ocupacao_id', this.entidadeAtual.sit_ocupacao_id)}
          if (this.entidadeAtual.tipo_ocupacao_id){ 
            formData.append('tipo_ocupacao_id', this.entidadeAtual.tipo_ocupacao_id)}
          if (this.entidadeAtual.tipo_interessado_id){ 
            formData.append('tipo_interessado_id', this.entidadeAtual.tipo_interessado_id)}
          if (this.entidadeAtual.util_especifica_id){ 
            formData.append('util_especifica_id', this.entidadeAtual.util_especifica_id)}
          if (!this.entidadeAtual.cep){ 
            this.entidadeAtual.cep = ''}
          this.entidadeAtual.cep = this.entidadeAtual.cep=("00000000"+this.entidadeAtual.cep ).slice(-8)
          this.entidadeAtual.cep = this.entidadeAtual.cep.replace(/[^0-9]/g, '')
          formData.append('cep', this.entidadeAtual.cep)
          if (!this.entidadeAtual.no_processo_incorporacao || this.entidadeAtual.existe_processo_id == 2 || this.entidadeAtual.existe_processo_id == 3 ){ 
            this.entidadeAtual.no_processo_incorporacao = ''}
          else{
            this.entidadeAtual.no_processo_incorporacao = this.entidadeAtual.no_processo_incorporacao.replace(/[^0-9]/g, '');
          }
          formData.append('no_processo_incorporacao', this.entidadeAtual.no_processo_incorporacao)

          if (!this.entidadeAtual.no_processo_destinacao || this.entidadeAtual.existe_processo_destinacao_id == 2 || this.entidadeAtual.existe_processo_destinacao_id == 3 ){ 
            this.entidadeAtual.no_processo_destinacao = ''}
          else{
            this.entidadeAtual.no_processo_destinacao = this.entidadeAtual.no_processo_destinacao.replace(/[^0-9]/g, '');
          }
          formData.append('no_processo_destinacao', this.entidadeAtual.no_processo_destinacao)
          if (!this.entidadeAtual.descricao_logradouro){ 
            this.entidadeAtual.descricao_logradouro = ''}
          formData.append('descricao_logradouro', this.entidadeAtual.descricao_logradouro)
          if (!this.entidadeAtual.complemento_logradouro){ 
            this.entidadeAtual.complemento_logradouro = ''}
          formData.append('complemento_logradouro', this.entidadeAtual.complemento_logradouro)
          if (!this.entidadeAtual.bairro){ 
            this.entidadeAtual.bairro = ''}
          formData.append('bairro', this.entidadeAtual.bairro)
          if (!this.entidadeAtual.no_logradouro){ 
            this.entidadeAtual.no_logradouro = ''}
          formData.append('no_logradouro', this.entidadeAtual.no_logradouro)
          if (!this.entidadeAtual.num_familias_beneficiadas){
            this.entidadeAtual.num_familias_beneficiadas = ''}
          formData.append('num_familias_beneficiadas', this.entidadeAtual.num_familias_beneficiadas)
          if (!this.entidadeAtual.obs){ 
            this.entidadeAtual.obs = ''}
          formData.append('obs', this.entidadeAtual.obs)
          formData.append('grupo_imovel_id',this.entidadeAtual.grupo_imovel_id)
          formData.append('programa_especifico_id',this.entidadeAtual.programa_especifico_id)

          if (!this.entidadeAtual.no_processo_judicial){ 
            this.entidadeAtual.no_processo_judicial = ''
          }
          else{
            this.entidadeAtual.no_processo_judicial = this.entidadeAtual.no_processo_judicial.replace(/[^0-9]/g, '');
          }
          formData.append('no_processo_judicial', this.entidadeAtual.no_processo_judicial)

          formData.append('possui_benfeitoria_id', this.entidadeAtual.possui_benfeitoria_id)
          formData.append('entrada_imovel_id', this.entidadeAtual.entrada_imovel_id)
          
          formData.append('conflito_id', this.entidadeAtual.conflito_id)
          formData.append('conceituacao_id', this.entidadeAtual.conceituacao_id)
          formData.append('instrumento_destinacao_id', this.entidadeAtual.instrumento_destinacao_id)
          formData.append('sit_incorporacao_id', this.entidadeAtual.sit_incorporacao_id)

          if (!this.entidadeAtual.gasto_anual_imovel){
            this.entidadeAtual.gasto_anual_imovel = ''}          

          if (!this.entidadeAtual.area_benfeitoria){
            this.entidadeAtual.area_benfeitoria = ''}
          let valor = this.entidadeAtual.area_benfeitoria.replaceAll('.','');
          formData.append('area_benfeitoria', valor.replaceAll(',','.'))
          if (!this.entidadeAtual.valor_estimado){
            this.entidadeAtual.valor_estimado = ''}          
          valor = this.entidadeAtual.valor_estimado.replaceAll('.','');
          formData.append('valor_estimado', valor.replaceAll(',','.'))

          valor = this.entidadeAtual.gasto_anual_imovel.replaceAll('.','');
          formData.append('gasto_anual_imovel', valor.replaceAll(',','.'))

          valor = this.entidadeAtual.area_terreno.replaceAll('.','');
          formData.append('area_terreno', valor.replaceAll(',','.'))
          formData.append('estado_id', this.municipios.filter(element => element.value == this.entidadeAtual.municipio_id)[0].estado_id)

          formData.append('municipio_id', this.entidadeAtual.municipio_id)
          
          if (!this.entidadeAtual.no_blocos){ 
            this.entidadeAtual.no_blocos = ''}
          formData.append('no_blocos', this.entidadeAtual.no_blocos)
          if (!this.entidadeAtual.no_pavimentos){ 
            this.entidadeAtual.no_pavimentos = ''}
          formData.append('no_pavimentos', this.entidadeAtual.no_pavimentos)



          if (!this.entidadeAtual.qtd_familias_ocupacao){
            this.entidadeAtual.qtd_familias_ocupacao = ''}
          formData.append('qtd_familias_ocupacao', this.entidadeAtual.qtd_familias_ocupacao)

          
          if (!this.entidadeAtual.interlocucao_parceiros){
            this.entidadeAtual.interlocucao_parceiros = ''}
          formData.append('interlocucao_parceiros', this.entidadeAtual.interlocucao_parceiros)
          if (!this.entidadeAtual.uso_solo){
            this.entidadeAtual.uso_solo = ''}
          formData.append('uso_solo', this.entidadeAtual.uso_solo)


          if (!this.entidadeAtual.nome_interessado){            
            this.entidadeAtual.nome_interessado = ''}
          this.entidadeAtual.nome_interessado = this.entidadeAtual.nome_interessado +''
          formData.append('nome_interessado', this.entidadeAtual.nome_interessado)
          
          if (!this.entidadeAtual.providencia){ 
            this.entidadeAtual.providencia = ''}
          formData.append('providencia', this.entidadeAtual.providencia)
          let salvas = []
          if (this.fotosSalvas.length > 0){
            salvas.push(this.fotosSalvas)
            formData.append('fotosSalvas', this.fotosSalvas)
          }
          for (let i = 0; i < this.novoFile.length; i++) {
            formData.append('fotos[]', this.novoFile[i]);
          }
          formData.append('linha_programa_id', this.entidadeAtual.linha_programa_id)
          formData.append('poligonais', this.coordenadaAtualizada)
          formData.append('coordenadas', this.coordenadaAtualizada)
          if (this.entidadeAtual.id != null) {
            formData.append('_method', 'PUT')
          }
          else{
            formData.append('_method', 'POST')
          }
          let config = {
              header : {
              'Content-Type' : 'multipart/form-data'
            }
          }
          let url = this.entidadeAtual.id === null ?
            rotas.destinacoes.destinacoes.url() :
            rotas.destinacoes.editar.url({ id: this.entidadeAtual.id });
            this.$http.post(url, formData, config)
              .then(
                response => {
                  EventBus.mensagem('Imóvel cadastrado com sucesso!')
                  resolve(response)
                },
                error => {
                    reject(new Error(error.body.message))
                }
              )
        } catch (e) {
          reject(e)
        }
      })
    },
    cancelar () {
      this.$refs.crud.loadItems()
    },
    novo () {
        this.entidadeAtual = {
          id: null,
          rip: [],
          matricula: [],
          util_especifica_id: 47,
          linha_programa_id: 4,
          programa_especifico_id: 9,
          instrumento_destinacao_id: 12,
          tipo_interessado_id: 7,
        }
        this.databaseImages = []
        this.imageUrls = []
        this.tabAtiva = 0
        this.coordenadas = []
        this.coordenadaAtualizada = []
        this.municipios = []
        this.fotos = []
        this.fotosSalvas = []
        this.unidadeGestora = storageBus.getUnidadeGestora().sigla
        this.carregaFinalidade_destinacao_47()
      },
    carregaAcao_judicial () {
      this.carregandoAcao_judicial = true
      this.acao_judicial = []
      this.$http
        .get(rotas.destinacoes.acao_judicial.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.acao_judicial.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoAcao_judicial = false
        })

    },
    carregaCondicao_urbanizacao () {
      this.carregandoCondicao_urbanizacao = true
      this.cond_urbanizacao = []
      this.$http
        .get(rotas.destinacoes.condicao_urbanizacao.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.cond_urbanizacao.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoCondicao_urbanizacao = false
        })
    },
    carregaPossui_benfeitoria () {
      this.carregandoPossui_benfeitoria = true
      this.possui_benfeitoria = []
      this.$http
        .get(rotas.destinacoes.possui_benfeitoria.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.possui_benfeitoria.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoPossui_benfeitoria = false
        })
    },

    carregaEstado_benfeitoria () {
      this.carregandoEstado_benfeitoria = true
      this.estado_benfeitoria = []
      this.$http
        .get(rotas.destinacoes.estado_benfeitoria.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.estado_benfeitoria.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoEstado_benfeitoria = false
        })
    },

    carregaFinalidade_destinacao () {   
      this.carregandoFinalidade_destinacao = true
      this.finalidade_destinacao = []
      this.$http
        .get(rotas.destinacoes.util_especifica.url() + "?linha_programa_id=" + this.entidadeAtual.linha_programa_id)
        .then(
          response => {
            //console.log(response)
            response.body.forEach(element => {
              this.finalidade_destinacao.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoFinalidade_destinacao = false
        })},
    carregaFinalidade_destinacao_47 () {   
      this.carregandoFinalidade_destinacao = true
      this.finalidade_destinacao = []
      this.$http
        .get(rotas.destinacoes.util_especifica.url() + "?linha_programa_id=4")
        .then(
          response => {
            //console.log(response)
            response.body.forEach(element => {
              this.finalidade_destinacao.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoFinalidade_destinacao = false
    })},
    carregaNatureza () {
      this.carregandoNatureza = true
      this.natureza = []
      this.$http
        .get(rotas.destinacoes.natureza.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.natureza .push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoNatureza = false
        })
    },
    carregaSituacao_ocupacao () {
      this.carregandoSituacao_ocupacao = true
      this.situacao_ocupacao = []
      this.$http
        .get(rotas.destinacoes.situacao_ocupacao.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.situacao_ocupacao.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoSituacao_ocupacao = false
        })
    },
    carregaTipo_interessado () {
      this.carregandoTipo_interessado = true
      this.tipo_interessado = []
      this.$http
        .get(rotas.destinacoes.tipo_interessado.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.tipo_interessado.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoTipo_interessado = false
        })
    },
    
    carregaCategoria () {
      this.carregandoCategoria = true
      this.categoria = []
      this.$http
        .get(rotas.destinacoes.categoria.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.categoria.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoCategoria = false
        })
    },

    carregaProprietario_presumido () {
      this.carregandoProprietario_presumido = true
      this.proprietario_presumido = []
      this.$http
        .get(rotas.destinacoes.proprietario_presumido.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.proprietario_presumido.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoProprietario_presumido = false
        })
    },
    carregaGrupo_imovel () {
      this.carregandoGrupo_imovel = true
      this.grupo_imovel = []
      this.$http
        .get(rotas.destinacoes.grupo_imovel.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.grupo_imovel.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoGrupo_imovel = false
        })
    },
    carregaSituacao_incorporacao () {
      this.carregandoSit_incorporacao = true
      this.sit_incorporacao = []
      this.$http
        .get(rotas.destinacoes.sit_incorporacao.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.sit_incorporacao.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoSit_incorporacao = false
        })
    },
    
    carregaTipo_logradouro () {
      this.carregandoTipo_logradouro = true
      this.tipo_logradouro = []
      this.$http
        .get(rotas.destinacoes.tipo_logradouro.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.tipo_logradouro.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoTipo_logradouro = false
        })
    },
    carregaConceituacao () {
      this.carregandoConceituacao = true
      this.conceituacao = []
      this.$http
        .get(rotas.destinacoes.conceituacao.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.conceituacao.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoConceituacao = false
        })
    },
    carregaConflito() {
      this.carregandoConflito = true
      this.conflito = []
      this.$http
        .get(rotas.destinacoes.conflito.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.conflito.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoConflito = false
        })
    },
    carregaEixo() {
      this.carregandoEixo = true
      this.linha_programa = []
      this.$http
        .get(rotas.destinacoes.linha_programa.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.linha_programa.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoEixo = false
        })
    },
    carregaExiste_processo() {
      this.carregandoExiste_processo = true
      this.existe_processo = []
      this.$http
        .get(rotas.destinacoes.existe_processo.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.existe_processo.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoExiste_processo = false
        })
    },

    
    carregaEntrada_imovel() {
      this.carregandoEntrada_imovel = true
      this.entrada_imovel = []
      this.$http
        .get(rotas.destinacoes.entrada_imovel.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.entrada_imovel.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoEntrada_imovel = false
        })
    },

    carregaExiste_processo_destinacao() {
      this.carregandoExiste_processo_dest = true
      this.existe_processo_destinacao = []
      this.$http
        .get(rotas.destinacoes.existe_processo_destinacao.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.existe_processo_destinacao.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoExiste_processo_dest = false
        })
    },

    carregaTipo_ocupacao() {
      this.carregandoTipo_ocupacao = true
      this.tipo_ocupacao = []
      this.$http
        .get(rotas.destinacoes.tipo_ocupacao.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.tipo_ocupacao.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoTipo_ocupacao = false
        })
    },
    
    carregaPrograma_especifico() {
      this.carregandoPrograma_especifico = true
      this.programa_especifico = []
      this.$http
        .get(rotas.destinacoes.programa_especifico.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.programa_especifico.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoPrograma_especifico = false
        })
    },
    carregaInstrumento_destinacao() {
      this.carregandoInstrumento_destinacao = true
      this.instrumento_destinacao = []
      this.$http
        .get(rotas.destinacoes.instrumento_destinacao.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.instrumento_destinacao.push(element)
            })
          },
          error => {
            console.log("Erro ", error)
          }
        )
        .finally(() => {
          this.carregandoInstrumento_destinacao = false
        })
    },

    carregaMunicipio(){
      this.municipios = [];
      if (this.search.length > 2){
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        if (this.search.length > 2){
          this.carregandoMunicipio = true;
          this.timer = setTimeout(() => {      
            let url = rotas.correspondencia.pesquisaMunicipio.url();
            url = url+"?search="+this.search
            this.$http.get(url)
            .then(
              response => {
                response.body.forEach(element => {
                  this.municipios.push({
                    'text':element.cidade.trim() + "/"+element.estado.descricao, 
                    'value':element.id,
                    "estado_id":element.estado.id});
                });
              },
              error => {
                EventBus.alerta(error.body.message);
            });
            this.entidadeAtual.municipio_id = null;
          }, 800);
          this.carregandoMunicipio = false;
        }
      }
    },
    isTabGeorreferenciamento () {
      return this.tabAtiva == PROCEDIMENTO_TAB_GEORREFERENCIAMENTO
    },
    mudouDeTab () {
      this.atualizarMapa = false;
      if (this.isTabGeorreferenciamento()) {
        this.atualizarMapa = true;
      }
    },
    carregarTabelasApoio () {
      this.carregaAcao_judicial()
      this.carregaCategoria()
      this.carregaCondicao_urbanizacao()
      this.carregaEstado_benfeitoria()
      this.carregaPossui_benfeitoria()
      this.carregaGrupo_imovel()
      this.carregaNatureza()
      this.carregaProprietario_presumido()
      this.carregaSituacao_incorporacao()
      this.carregaSituacao_ocupacao()
      this.carregaTipo_interessado()
      this.carregaTipo_logradouro()
      this.carregaConceituacao()
      this.carregaConflito()
      this.carregaEixo()
      this.carregaExiste_processo()
      this.carregaEntrada_imovel()
      this.carregaExiste_processo_destinacao()
      this.carregaPrograma_especifico()
      this.carregaInstrumento_destinacao()
      this.carregaTipo_ocupacao()
    },
    carregarPermissoesUsuario () {
      this.usuarioTemVisaoTotal = storageBus.usuarioPossuiPermissao("DEMANDA_VISAO_TOTAL")
    },
    aplicarListarDeTodasUGs () {
      this.$refs.crud.loadItems();
    },
    abrirDemanda(idDemanda) {
        this.$router.push("/demanda/" + idDemanda )
    },
  },
  mounted () {
    this.carregarPermissoesUsuario()
    this.carregarTabelasApoio ()
    if (this.$route.params.id) {
      this.selecionarParaEdicao({ id: this.$route.params.id })
    }
  },
}
</script>
<style>
v-text-field__details{
  display: none;
}
.textarea-outlined {
  border: 1px solid #ccc; 
  padding: 8px; 
  width: 100%; 
  box-sizing: border-box;
  border-radius: 4px; 
  outline: none; 
}
.fa-newspaper-o-selected {
    color: blue;
}
.bordered-input {
  padding-left: 5px;
  border-bottom: 1px solid #ccc;
}
.icon-space {
    margin-left: 10px; 
}
.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px;
  justify-content: space-between; 
  height: auto; 
  width: 252px;
  border: 1px solid #ccc; 
  background-color: #f0f0f0;
}
.adicionar-button {
  background-color: #4792db;
  color: #ffffff !important; 
  padding: 5px 10px;
  border: none; 
  cursor: pointer; 
  border-radius: 4px; 
  font-weight: bold;
}
.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.icon-clicked {
    color: red;
}
.campo-vazio {
  color: red; 
}
div.campo-obrigatorio > div > div > div.v-select__slot > label{
  color: red;
}
div.campo-obrigatorio > div > div > div.v-text-field__slot > label{
  color: red;
}
.cor-nao-geo{
  color: red;
}
.destinacao-top{
  vertical-align: top;
}
.destinacao-top-area {
  width: 140px;
  text-align: right;
}
.text-start{
  padding-right: 0 !important;
}
</style>