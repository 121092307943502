import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import sistema from './modules/sistema'
import auth from './modules/auth'
import demandas from './modules/demandas'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    sistema,
    auth,
    demandas
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
  strict: debug
})
