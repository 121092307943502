var Validador = function() {

    var email = function(valor) {
        if(valor == null || valor.length == 0) {
            return true
        }
        return /.+@.+\..+/.test(valor) || 'E-mail precisa ser válido'
    }

    var telefone = function(valor) {
        if(valor == null || valor.length == 0) {
            return true
        }
        return /\(\d{2}\)\s\d{4,5}\-\d{4}/.test(valor) || 'Telefone precisa ser válido'
    }

    var cpf_cnpj = function(valor) {
        if(valor == null || valor.length == 0) {
            return true
        }
        if(valor.length > 14) {
            return /\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}/.test(valor) || 'CNPJ precisa ser válido'
        }
        return /\d{3}\.\d{3}\.\d{3}\-\d{2}/.test(valor) || 'CPF precisa ser válido'
    }

    var obrigatorio = function(valor) {
        let mensagem = "Preenchimento obrigatório."
        if(valor == null || valor.length == 0) {
            return mensagem
        }
        return true
    }    
    
    var numerico = function(valor) {
        let mensagem = "Deve conter apenas números."
        if(isNaN(valor) && valor != undefined) {
            return mensagem
        }
        return true
    }

    var tamanhoMinimo = function(valor, tamanho) {
        if (valor == null || valor.length == 0) {
            return true
        }
        if (valor.length >= tamanho) {
            return true
        }
        return 'O tamanho mínimo: ' + tamanho + ' caracteres.'
    }

    var tamanho = function(valor, tamanho) {
        if (valor == null) {
            return true
        }
        if (valor.length == tamanho) {
            return true
        }
        return 'O tamanho deve ser de ' + tamanho + ' caracteres.'
    } 
    var tamanhoMax = function(valor, tamanho) {
        if (valor == null) {
            return true
        }
        if (valor.length <= tamanho) {
            return true
        }
        return 'Excede o tamanho máximo de ' + tamanho + ' caracteres.'
    } 

    var tamanhoSEI = function(valor, num_OK) {
        if (valor == null) {
            return true
        }
        if (num_OK){
            return true
        }
        return 'Deve conter 17 caracteres numéricos. Se for necessário, complete com zeros à esquerda'
    }
    var tamanhoCNJ = function(valor, Ok) {
        if (Ok) {
            return true
        }
        return 'O número do processo não é válido. Reveja os valores informados.'
    }

    var igual = function(valor, aComparar, mensagem) {
        if(valor == null) {
            return true
        }

        if(valor == aComparar) {
            return true
        }
        return mensagem
    }
    var maiorQue = function(valor, aComparar, mensagem) {
        if(valor == null) {
            return true
        }

        if(valor > aComparar) {
            return true
        }
        return mensagem
    }

    var data = function(valor) {
        const msg = 'Data inválida'
        if(valor == null || valor.length == 0) return true
        return /\d{2}\/\d{2}\/\d{4}/.test(valor) || msg
    }

    return { 
        email,
        obrigatorio,
        numerico,
        tamanhoMinimo : tamanhoMinimo,
        tamanho,
        tamanhoMax,
        tamanhoSEI,
        tamanhoCNJ,
        igual,
        maiorQue: maiorQue,
        data,
        telefone,
        cpf_cnpj
    }
} ();

module.exports = Validador;