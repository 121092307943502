<template>
  <app-template>
    <router-view></router-view>
  </app-template>
</template>

<script>
import rotas from "./rotas-servico/";
import EventBus from "./event-bus";
export default {
  data() {
    return {
      superintendencia: null,
    };
  },
  methods: {
    carregarDadosSuperintendencia() {
      // console.log("App.carregarDadosSuperintendencia()")
      this.$http
        .get(rotas.controleAcesso.organograma.unidadeGestoraDoUsuario.url())
        .then(
          (response) => {
            // console.log(response)
            this.superintendencia = response.body;
            this.$store.commit(
              "auth/setSuperintendencia",
              this.superintendencia
            );
          },
          () => {
            EventBus.alerta("Erro ao carregar dados da unidade gestora");
          }
        );
    },

    carregarPermissoesUsuario() {
      // console.log("carregarPermissoesUsuario()")
      this.$http.get(rotas.controleAcesso.permissao.usuario.url()).then(
        (response) => {
          // console.log('response', response)
          this.$store.commit("auth/setPermissoes", response.body);
        },
        () => {
          console.log("Erro ao carregar permissões do usuário");
        }
      );
    },

    carregarDadosUsuario() {
      this.carregando = true;
      return this.$http
        .get(rotas.controleAcesso.userData.url())
        .then(
          (response) => {
            this.$store.commit("auth/setUsuario", response.data);
          },
          () => {
            EventBus.alerta("Erro ao carregar dados do usuário");
          }
        )
        .finally(() => {
          this.carregando = false;
        });
    },

    onLogin(oauth_data) {
      this.$store.commit("auth/setOAuthData", oauth_data);
      this.$store.commit("sistema/exibirMenu", true);
      this.$router.push(this.$route.query.redirect || "/");
      this.carregarDadosUsuario();
      this.carregarDadosSuperintendencia();
      this.carregarPermissoesUsuario();
    },
  },
  mounted() {},

  created() {
    EventBus.$on("App::onLogin", this.onLogin);
  },
};
</script>

<style>
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
