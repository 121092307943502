<template>
  <v-container fluid fill-height>
    <v-row align="stretch" justify="center">
      <v-col xs="12" md="6" lg="5" xl="3" align="center">
        <v-form v-model="valid" method="POST">
          <v-card>
            <v-card-title>Trocar Senha </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="novaSenha"
                      :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(novaSenha, 8)]"
                      label="Nova senha"
                      type="password"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="confirmacaoSenha"
                      :rules="[validacao.obrigatorio, 
                        validacao.tamanhoMinimo(confirmacaoSenha, 8), 
                        validacao.igual(novaSenha, confirmacaoSenha, 'Confirmação não corresponde à senha informada')]"
                      label="Confirmação"
                      type="password"
                      required
                      @keyup.enter="submit"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-row justify="end" class="mr-2">
                <v-btn
                  text
                  color="info"
                  @click="submit"
                  :disabled="!valid || carregando"
                >
                  <v-progress-circular
                    indeterminate
                    v-if="carregando"
                    color="grey"
                    size="20"
                    width="3"
                  ></v-progress-circular>
                  Salvar e continuar
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rotas from "./../rotas-servico";
import Validador from "./../validacao.js";
import EventBus from './../event-bus';

export default {
  data: () => ({
    carregando: false,
    valid: false,
    validacao: Validador,

    novaSenha: '',
    confirmacaoSenha: ''

  }),
  methods: {
    submit() {

      let formData = new FormData();
      formData.append("novaSenha", this.novaSenha);
      formData.append("confirmacaoSenha", this.confirmacaoSenha);

      this.carregando = true;
      return this.$http
        .post(rotas.controleAcesso.trocarSenha.url(), formData)
        .then(
          (response) => {
            EventBus.$emit('App::onLogin', response.data)
            this.carregando = false
          },
          (error) => {
            if (error.status == 422) {
              EventBus.alerta("E-mail não encontrado")
            } else {
              EventBus.alerta(error.message)

            }
            this.carregando = false;
          }
        );
    },
  },
};
</script>