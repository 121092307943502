<template>
  <crud
    v-if="urlBase"
    ref="crud"
    nomeEntidade="Usuário"
    nomeEntidadePlural="Usuários"
    :headers="cabecalhos"
    :resource-url="urlBase"
    :query-params="parametros"
    itemKey="id"
    :clicou-salvar="salvar"
    @clicou-item="selecionarParaEdicao"
    @clicou-cancelar="cancelar"
    @clicou-novo="novo"
  >
    <template slot="beforeAdd">
      <v-switch
        v-if="podeAlterarUG"
        tabindex="8"
        v-model="parametros.listarDeTodasUGs"
        label="Listar de todas UGs?"
        @change="aplicarListarDeTodasUGs"
      ></v-switch>
    </template>
    <template slot="detalhe">
      <v-card v-if="entidadeAtual">
        <v-card-text>
          <v-tabs style="padding-left: 30px;" v-model="tabAtiva" @change="mudouDeTab">
            <v-tab>Cadastro</v-tab>
            <v-tab>Permissões</v-tab>
            <v-tab>Vinculações Organograma</v-tab>

            <v-tabs-slider color="primary"></v-tabs-slider>

            <!-- TAB CADASTRO -->
            <v-tab-item style="width: 90%; padding-left: 15px; margin-top: 10px;">
              <v-container>
                <v-row>
                  <v-col xs="12">
                    <v-autocomplete
                      label="Unidade Gestora"
                      :items="unidadesGestoras"
                      :loading="carregandoUnidadesGestoras"
                      v-model="entidadeAtual.iddivisaoorganogramaunidadegestora"
                      item-text="nome"
                      item-value="id"
                      :rules="[validacao.obrigatorio]"
                      :required="true"
                      :disabled="!podeAlterarUG"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="12" md="6">
                    <v-text-field
                      label="Nome"
                      v-model="entidadeAtual.name"
                      :disabled="!podeCadastrar"
                      :rules="[validacao.obrigatorio, validacao.min(entidadeAtual.name, 4)]"
                      required
                    />
                  </v-col>
                  <v-col xs="12" md="6">
                    <v-text-field
                      label="CPF"
                      v-model="entidadeAtual.cpf"
                      type="number"
                      :rules="[validacao.obrigatorio, validacao.numero, validacao.min(entidadeAtual.cpf, 11)]"
                      required
                      counter="11"
                      maxlength="11"
                      :disabled="entidadeAtual.id ? true : false"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="12" md="6">
                    <v-text-field
                      label="E-mail"
                      v-model="entidadeAtual.email"
                      :disabled="!podeCadastrar"
                      :rules="[validacao.obrigatorio, validacao.email, validacao.min(entidadeAtual.email, 6)]"
                      required
                    />
                  </v-col>
                  <v-col xs="12" md="6">
                    <v-text-field
                      label="Telefone"
                      v-model="entidadeAtual.telefone"
                      :disabled="!podeCadastrar"
                      :rules="[validacao.obrigatorio, validacao.min(entidadeAtual.telefone, 10)]"
                      required
                      counter="15"
                      maxlength="15"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <!-- TAB PERMISSOES -->
            <v-tab-item style="width: 90%; padding-left: 30px;">
              <v-container style="margin-top: 10px; margin-bottom: 5px;">
                <lista-dupla-selecao-filtravel
                  :disponiveis="permissoesDisponiveis"
                  :selecionados="permissoesSelecionadas"
                  comSubtitulo
                  @change="mudouListaDuplaPermissoes"
                  propriedadeValor="id"
                  propriedadeTitulo="permissao"
                  propriedadeSubtitulo="descricao"
                />
              </v-container>
            </v-tab-item>
            <!-- TAB DIVISOES ORGANOGRAMAS -->
            <v-tab-item style="width: 90%; padding-left: 30px;">
              <v-container style="margin-top: 10px; margin-bottom: 5px;">
                <lista-dupla-selecao-filtravel
                  :disponiveis="divisoesDisponiveis"
                  :selecionados="divisoesSelecionadas"
                  comSubtitulo
                  @change="mudouListaDuplaDivisoes"
                  propriedadeValor="id"
                  propriedadeTitulo="nome"
                  propriedadeSubtitulo="sigla"
                />
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </template>
  </crud>
</template>

<script>
import rotas from "../../rotas-servico/";
import CRUD from "./../ApiCrud";
import ListaDuplaSelecaoFiltravel from './../ListaDuplaSelecaoFiltravel';
import EventBus from './../../event-bus'

const PERMISSAO_CADASTRAR = "AUTH_USUARIO_CADASTRAR";
const PERMISSAO_ALTERAR_UG = "AUTH_USUARIO_ALTERAR_UG";

export default {
  components: {
    crud: CRUD,
    'lista-dupla-selecao-filtravel': ListaDuplaSelecaoFiltravel
  },
  data: () => {
    return {
      cabecalhos: [
        {
          text: "Usuário",
          value: "name"
        },
        {
          text: "E-mail",
          value: "email"
        },
        {
          text: "CPF",
          value: "cpf"
        },
        {
          text: "Telefone",
          value: "telefone"
        }
      ],
      entidadeAtual: null,

      parametros: {
        listarDeTodasUGs: false,
      },


      carregando: false,
      tabAtiva: 0,

      urlBase: rotas.controleAcesso.usuario.listar.url(),

      permissoesDisponiveis: [],
      permissoesSelecionadas: [],

      divisoesDisponiveis: [],
      divisoesSelecionadas: [],

      unidadesGestoras: [],
      carregandoUnidadesGestoras: false,

      validacao: {
        obrigatorio: value => value !== null || "Preenchimento obrigatório.",
        min: (v, min) =>
          (!!v && v.length >= min) ||
          "No mínimo " + min + " caracteres",
        email: v => /.+@.+\..+/.test(v) || "E-mail precisa ser válido",
        numero: v => v !== null && (/^\d+$/.test(v)) || "Use apenas números, sem edição" 
      },
      podeCadastrar: false,
      podeAlterarUG: false,

      passouValidacao: false
    };
  },
  methods: {
    mudouDeTab () {
      if (this.isTabPermissoes()) {
        this.carregarPermissoes();
        //this.formatarPermissoes(this.entidadeAtual);
      }
      if (this.isTabDivisoes()) {
        this.carregarDivisoes();
      }
    },

    isTabPermissoes () {
      return this.tabAtiva == 1;
    },

    isTabDivisoes () {
      return this.tabAtiva == 2;
    },

    limparListaDuplaPermissoes () {
      this.permissoesDisponiveis = [];
      this.permissoesSelecionadas = [];
    },

    limparListaDuplaDivisoes () {
      this.divisoesDisponiveis = [];
      this.divisoesSelecionadas = [];
    },

    selecionarParaEdicao (item) {
      this.limparListaDuplaPermissoes();
      this.limparListaDuplaDivisoes();

      this.divisoesSelecionadas = null;
      this.tabAtiva = 0;
      this.entidadeAtual = item;
      this.formatarPermissoes(this.entidadeAtual);
      this.formatarDivisoes(this.entidadeAtual);
    },
    salvar () {
      return new Promise((resolve, reject) => {
        try {
          let formData = new FormData();
          formData.append("id", this.entidadeAtual.id);
          formData.append("name", this.entidadeAtual.name);
          formData.append("cpf", this.entidadeAtual.cpf);
          formData.append("email", this.entidadeAtual.email);
          formData.append("telefone", this.entidadeAtual.telefone);
          formData.append(
            "iddivisaoorganogramaunidadegestora",
            this.entidadeAtual.iddivisaoorganogramaunidadegestora
          );
          this.permissoesSelecionadas.forEach(permissao => {
            formData.append("permissoes[]", permissao.id);
          });

          this.divisoesSelecionadas.forEach(divisao => {
            formData.append("divisoes[]", divisao.id);
          });
          if (this.entidadeAtual.id != null) {
            formData.append("_method", "PUT");
          }

          let url =
            this.entidadeAtual.id === null
              ? rotas.controleAcesso.usuario.criar.url()
              : rotas.controleAcesso.usuario.editar.url({ id: this.entidadeAtual.id });
          this.$http.post(url, formData).then(
            result => {
              EventBus.mensagem("Usuário atualizado com sucesso")
              resolve(result)
            },
            error => {
              reject(new Error(error.body.message))
            }
          )
        } catch (e) {
          reject(e)
        }
      })
    },

    aplicarListarDeTodasUGs () {
      this.$refs.crud.loadItems();
    },

    cancelar () { },

    novo (item) {
      this.limparListaDuplaPermissoes();
      this.limparListaDuplaDivisoes();
      this.tabAtiva = 0;

      let unidadeGestora = this.$store.getters["auth/superintendencia"];
      this.entidadeAtual = {
        id: null,
        cpf: null,
        iddivisaoorganogramaunidadegestora: unidadeGestora.id,
        permissoes: {},
        divisoes: {}
      };
    },


    /**
     * Carrega todas as permissões cadastradas para listar na aba Permissões
     */
    carregarPermissoes () {
      // console.log('carregarPermissoes()')
      this.carregando = true;
      this.$http.get(rotas.controleAcesso.permissao.usuario.url()).then(
        response => {
          //console.log('RESPONSE', response.body)
          this.permissoesDisponiveis = response.body;
          this.carregando = false;
        },
        error => {
          EventBus.alerta('Erro ao carregar permissões')
          this.carregando = false;
        }
      )
    },

    carregarDivisoes () {
      // console.log('Usuario.carregarDivisoes()')
      this.carregando = true;
      this.divisoesDisponiveis = [];
      this.$http.get(rotas.controleAcesso.organograma.listar.url()).then(
        response => {
          // console.log('response', response)
          this.divisoesDisponiveis = response.body
          this.carregando = false;
        },
        error => {
          console.log(error);
          this.carregando = false;
        }
      );
    },

    carregarUnidadesGestoras () {
      this.unidadesGestoras = [];
      this.carregandoUnidadesGestoras = true
      this.$http.get(rotas.controleAcesso.organograma.unidadeGestora.url()).then(
        response => {
          // console.log("Unidades Gestoras", response);
          this.unidadesGestoras = response.body;
        },
        error => {
          console.log(error);
        }
      )
        .finally(() => {
          this.carregandoUnidadesGestoras = false
        });
    },

    formatarPermissoes (usuario) {
      this.permissoesSelecionadas = [];
      if (Object.keys(usuario.permissoes).length > 0){
        this.permissoesSelecionadas = usuario.permissoes;
      }
    },

    formatarDivisoes (usuario) {
      // console.log("formatarDivisoes()", usuario)
      this.divisoesSelecionadas = []
      usuario.divisoes_organograma.forEach(divisao => {
        divisao.nomediv = divisao.nome
        divisao.sigladiv = divisao.sigla
        this.divisoesSelecionadas.push(divisao)
      });
    },

    formularioValido (valido) {
      this.passouValidacao = valido;
    },

    podeSalvar () {
      return (
        this.podeCadastrar != null &&
        this.podeCadastrar &&
        this.passouValidacao
      );
    },

    mudouListaDuplaPermissoes (disponiveis, selecionados) {
      this.permissoesDisponiveis = disponiveis;
      this.permissoesSelecionadas = selecionados;
    },

    mudouListaDuplaDivisoes (disponiveis, selecionados) {
      this.divisoesDisponiveis = disponiveis;
      this.divisoesSelecionadas = selecionados;
    },

    inicializarPermissoesDoUsuarioAutenticado () {
      let permissoes = this.$store.getters["auth/permissoes"];
      // console.log("inicializarPermissoesDoUsuarioAutenticado()", permissoes)
      this.podeCadastrar = false;
      this.podeAlterarUG = false;

      if (permissoes == null) {
        return;
      }

      permissoes.forEach(permissao => {
        switch (permissao.permissao) {
          case PERMISSAO_CADASTRAR:
            this.podeCadastrar = true;
            break;

          case PERMISSAO_ALTERAR_UG:
            this.podeAlterarUG = true;
            break;

          default:
            break;
        }
      });
    },

  },

  mounted () {
    this.carregarUnidadesGestoras();
    this.inicializarPermissoesDoUsuarioAutenticado();
  }
};
</script>
<style scoped>
.itemLista {
  padding: 3pt;
}
.v-input--selection-controls {
  margin: 0px;
}
</style>
