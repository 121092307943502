export default {
    namespaced: true,

    state: {
        token: null,
        usuario: null,
        token_expira_em: null,
        superintendencia: null,
        menu: null,
        permissoes: null
    },

    getters: {
        token: (state) => {        
            return state.token
        },
        refresh_token: (state) => {
            return state.refresh_token
        },
        usuario: (state) => {
            return state.usuario
        },
        token_expira_em: (state) => {
            return state.token_expira_em
        },
        superintendencia: (state) => {
            return state.superintendencia
        },
        menu: (state) => {
            return state.menu
        },
        permissoes: (state) => {
            return state.permissoes
        }
    },

    mutations: {
        setOAuthData(state, data) {
            // console.log("auth/setOAuthData", data)
            state.token = data.access_token
            state.refresh_token = data.refresh_token
            state.token_expira_em = new Date().getTime() + data.expires_in * 1000
        },
        setUsuario(state, data) {
            state.usuario = data
        },
        setSuperintendencia(state, superintendencia) {
            state.superintendencia = superintendencia
        },
        menu(state, menu) {
            state.menu = menu
        },
        sair(state) {
            state.token = null
            state.usuario = null
            state.token_expira_em = null
            state.menu = null
        },
        setPermissoes(state, permissoes) {
            state.permissoes = permissoes
        }
    }

}