var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.estiloPainelGeo(),attrs:{"id":"painelGeo"}},[_c('v-container',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"fluid":""}},[_c('v-row',{staticClass:"overflow-y-auto",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"xs":"12"}},[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"primary"}},[_c('v-toolbar-title'),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.exibirCamadas = !_vm.exibirCamadas}}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.exibirCamadas ? "layers_clear" : "layers"))])],1)]}}])},[_c('span',[_vm._v(_vm._s(this.dicaExibirCamadas()))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.telaCheia = !_vm.telaCheia}}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.telaCheia ? "fullscreen_exit" : "fullscreen"))])],1)]}}])},[_c('span',[_vm._v(_vm._s(this.dicaTelaCheia()))])])],1)],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"xs":"12","md":_vm.exibirCamadas ? 8 : 12}},[_c('div',{staticClass:"map",attrs:{"id":"map"}})]),(_vm.exibirCamadas)?_c('v-col',{attrs:{"xs":"12","md":"4"}},[_c('v-card',{attrs:{"tile":"","flat":""}},[_c('v-card-title',[_c('h6',{staticClass:"title text-capitalize"},[_vm._v("Importar Geometrias")])]),_c('v-card-subtitle',[_c('input',{ref:"files",attrs:{"type":"file","id":"files","value":"Importar shapefile (.shp)"},on:{"change":function($event){return _vm.handleFiles()}}})]),_c('v-card-text',[_c('div',{staticStyle:{"overflow":"auto"},attrs:{"id":"painelCamadas"}},[(
                  _vm.camadaImportacao && _vm.camadaImportacao.children.length > 0
                )?_c('div',[_c('v-row',[_c('v-col',{attrs:{"xs":"12"}},[_c('v-checkbox',{attrs:{"dense":"","label":_vm.camadaImportacao.name},on:{"change":function($event){return _vm.selecionouCamada(_vm.camadaImportacao)}},model:{value:(_vm.camadaImportacao.selected),callback:function ($$v) {_vm.$set(_vm.camadaImportacao, "selected", $$v)},expression:"camadaImportacao.selected"}})],1)],1),_vm._l((_vm.camadaImportacao.children),function(item){return _c('v-row',{key:item.id,attrs:{"dense":""}},[_c('v-col',{attrs:{"xs":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.selecionouElementoCamada(item)}}},on),[_vm._v(" "+_vm._s(item.selected ? "check_box" : "check_box_outline_blank")+" ")])]}}],null,true)},[_vm._v(" Mostrar no Mapa ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.clicouZoomItemCamada(item)}}},on),[_vm._v("zoom_in")])]}}],null,true)},[_vm._v(" Mostrar no Mapa ")]),_vm._v(" "+_vm._s(item.name + "")+" ")],1)],1)})],2):_vm._e()])]),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":_vm.botaoSalvarGeometriasSelecionadasDesativado()},on:{"click":_vm.salvarGeometrias}},[_vm._v("Salvar Selecionadas")])],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }