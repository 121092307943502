<template>
  <card-grafico 
    titulo="Demandas"
    :carregando="!carregouDemandasAbertasPorSituacao">
          <v-list v-if="possuiDadosExibicao">
              <v-list-item>
                  <v-list-item-icon><v-icon color="blue">move_to_inbox</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ demandasAbertasPorSituacao.recebidas }}</v-list-item-title>
                  <v-list-item-subtitle>recebidas</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                  <v-list-item-icon><v-icon color="green">done</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ demandasAbertasPorSituacao.resolvidas }}</v-list-item-title>
                  <v-list-item-subtitle>respondidas</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                  <v-list-item-icon><v-icon color="green">play_circle_filled</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ demandasAbertasPorSituacao.em_analise }}</v-list-item-title>
                  <v-list-item-subtitle>em análise</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                  <v-list-item-icon><v-icon color="orange">alarm</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ demandasAbertasPorSituacao.atrasadas }}</v-list-item-title>
                  <v-list-item-subtitle>atrasadas</v-list-item-subtitle>
              </v-list-item>
              <v-list-item>
                  <v-list-item-icon><v-icon color="red">gavel</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ demandasAbertasPorSituacao.sentencas_judiciais }}</v-list-item-title>
                  <v-list-item-subtitle>sentenças</v-list-item-subtitle>
              </v-list-item>
              
          </v-list>
          <div v-else>Sem dados para exibição
          </div>
  </card-grafico>
</template>

<script>
import rotas from "./../../../../rotas-servico";
import Utils from './../../../../Utils'
import CardGrafico from "./../../../../components/relatorios/CardGrafico.vue";
export default {
  components: {
    CardGrafico
  },
  props: {
    unidadeGestora: {
      type: Object
    }

  },
  data() {
    return {
      carregouDemandasAbertasPorSituacao: false,
      demandasAbertasPorSituacao: null,
      dataDe: null,
      dataAte: null,
    };
  },

  methods: {
    carregarRelatorio(dateDe, dateAte) {
      this.dataDe = Utils.toIsoDate(dateDe);
      this.dataAte = Utils.toIsoDate(dateAte);
      this.carregarDemandasAbertasPorSituacao();
    },
    async carregarDemandasAbertasPorSituacao() {
      this.carregouDemandasAbertasPorSituacao = false;
      this.demandasAbertasPorSituacao = {};

      let url = rotas.demandas.relatorio.abertasPorSituacao.url();
      if (this.dataDe != null && this.dataAte != null) {
        url = url + "/" + this.dataDe + "/" + this.dataAte;
        if(this.unidadeGestora != null && this.unidadeGestora.id != null) {
          url = url + "/" + this.unidadeGestora.id
        }
      }
      return this.$http
        .get(url)
        .then((res) => {
          res.data.forEach((element) => {
            this.demandasAbertasPorSituacao = element;
          });
        })
        .catch((err) => {
          console.log(err);
        }).finally(() => {
          this.carregouDemandasAbertasPorSituacao = true;
        });
    },
  },

  watch: {

    unidadeGestora(val) {
      this.carregarDemandasAbertasPorSituacao()

    }

  },

  computed: {
    possuiDadosExibicao() {
      if(this.demandasAbertasPorSituacao == null) {
        return false
      }
      for (const key in this.demandasAbertasPorSituacao) {
        if(this.demandasAbertasPorSituacao[key] > 0) {
          return true
        }
      }
      return false
    }
  }
};
</script>

<style>
</style>