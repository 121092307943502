<template>
    <v-container fluid>
        <v-layout d-flex align-content-start flex-wrap>
            <!-- Demandas Jurídicas e de Controle -->
            <v-flex xs12 sm6 md4 pa-2>
                <v-card class="align-stretch" to="/atendimentos/atender">
                    <v-img :src="imgAtendimento" aspect-ratio="2.75" style="vertical-align: bottom" />
                    <v-card-title primary title>
                        Atendimento
                    </v-card-title>
                </v-card>
            </v-flex>
            <!-- Demandas Jurídicas e de Controle -->
            <v-flex xs12 sm6 md4 pa-2>
                <v-card class="align-stretch" to="/painel-demandas">
                    <v-img :src="imgDemandasJudiciaisControle" aspect-ratio="2.75" />
                    <v-card-title primary title>
                        Demandas Jurídicas e de Controle
                    </v-card-title>
                </v-card>
            </v-flex>
            <!-- Geovisualização -->
            <v-flex xs12 sm6 md4 pa-2>
                <v-card class="align-stretch" to="geo/visualizador">
                    <v-img :src="imgGeoVisualizador" aspect-ratio="2.75" />
                    <v-card-title primary title>
                        Geovisualizador
                    </v-card-title>
                </v-card>
            </v-flex>
            <!-- Base de Conhecimento -->
            <!--
            <v-flex xs12 sm6 md4 pa-2>
                <v-card class="align-stretch" href="http://conhecimento.spu.nossa.floripa.br" target="_blank">
                    <v-img src="/images/baseconhecimento.jpg" aspect-ratio="2.75" />
                    <v-card-title primary title>
                        Base de Conhecimento
                    </v-card-title>
                </v-card>
            </v-flex>
            -->
        </v-layout>
        <!--
        -->
        <v-row align="stretch">
            <v-col xs="12" md="4">
            </v-col>
            <v-col xs="12" md="4">
            </v-col>
            <v-col xs="12" md="4">
            </v-col>
        </v-row>
        
    </v-container>

</template> 

<script>
import imgDemandasJudiciaisControle from './../assets/juridicas.jpg'
import imgGeoVisualizador from './../assets/geo.jpg'
import imgAtendimento from './../assets/atendimento.jpg'

export default {
    data() {
        return {
            imgDemandasJudiciaisControle,
            imgGeoVisualizador,
            imgAtendimento
        }
    },
}
</script>
