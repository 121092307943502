<template>
  <v-card flat color="grey lighten-3">
      <v-card-title>
          Período
      </v-card-title>
    <v-card-text>
      <!-- Date De -->
      <v-row align="center">
        <v-col>
          <v-menu
            ref="exibirMenuDataDe"
            :close-on-content-click="false"
            :nudge-right="40"
            v-model="exibirMenuDataDe"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                tabindex="5"
                mask="##/##/####"
                return-masked-value
                slot="activator"
                v-model="dataDeInput"
                label="Demandas recebidas de:"
                hint="DD/MM/AAAA"
                persistent-hint
                prepend-icon="event"
                @blur="dataDePicker = toIsoDate(dataDeInput)"
                v-on="on"
              />
            </template>

            <v-date-picker
              v-model="dataDePicker"
              no-title
              @input="exibirMenuDataDe = false"
              locale="pt-br"
            />
          </v-menu>
        </v-col>
      </v-row>
      <!-- Date Ate -->
      <v-row align="center">
        <v-col>
          <v-menu
            exibirMef="exibirMenuDataAte"
            :close-on-content-click="false"
            :nudge-right="40"
            v-model="exibirMenuDataAte"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                tabindex="5"
                mask="##/##/####"
                return-masked-value
                slot="activator"
                v-model="dataAteInput"
                label="até:"
                hint="DD/MM/AAAA"
                persistent-hint
                prepend-icon="event"
                @blur="dataAtePicker = toIsoDate(dataAteInput)"
                v-on="on"
              />
            </template>

            <v-date-picker
              v-model="dataAtePicker"
              no-title
              @input="exibirMenuDataAte = false"
              locale="pt-br"
            />
          </v-menu>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- Botão Aplicar -->
    <v-card-actions>
        <v-row align="center">
        <v-col>
            <v-btn
            @click="aplicarParametros"
            :disabled="!computedAplicarParametrosValido"
            >
            <v-icon>done</v-icon>
            Aplicar
            </v-btn>
        </v-col>
        </v-row>
    </v-card-actions>

    <v-row align="center">
      <v-col v-if="this.mensagemParametros.length > 0" class="red--text small">
        {{ mensagemParametros }}
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        {{ mensagemParametrosAplicados }}
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment'
import Utils from './../Utils'
export default {

  props: {

    dataDe: {
      type: Date,
    },

    dataAte: {
      type: Date,
    },

    callbackPeriodoAlterado: {
      type: Function,
      required: true
    }

  },
  data() {
    return {
      dataDeInput: null,
      dataDePicker: null,
      exibirMenuDataDe: false,

      dataAteInput: null,
      dataAtePicker: null,
      exibirMenuDataAte: false,

      mensagemParametrosAplicados: null,
    };
  },

  methods: {
    async aplicarParametros() {
      this.mensagemParametrosAplicados = "";
      if (this.dataDeInput != null && this.dataAteInput != null) {
        let periodo = `de ${this.dataDeInput} a ${this.dataAteInput}`;
        this.mensagemParametrosAplicados =
          "Estatísticas para o período: " + periodo;
      }
      this.callbackPeriodoAlterado(
        new Date(Utils.toIsoDate(this.dataDeInput)), 
        new Date(Utils.toIsoDate(this.dataAteInput)))
    },

    toIsoDate(val) {
      return Utils.toIsoDate(val)
    }

  },

  watch: {

    dataDe(val) {
      this.dataDeInput = Utils.formatarData(val)
    },

    dataAte(val) {
      this.dataAteInput = Utils.formatarData(val)
    },

    dataDePicker(val) {
      this.dataDeInput = Utils.formatarData(val)
    },

    dataAtePicker(val) {
      this.dataAteInput = Utils.formatarData(val)
    },

  },

  computed: {

    computedAplicarParametrosValido() {
      this.mensagemParametros = "";
      // validando o período
      if (this.dataDeInput == null && this.dataAteInput == null) {
        return true;
      }
      if (this.dataDeInput != null && this.dataAteInput != null) {
        let dataDe = moment(this.dataDeInput, "DD/MM/YYYY")
        let dataAte = moment(this.dataAteInput, "DD/MM/YYYY")
        if (dataAte.diff(dataDe) <= 0) {
          this.mensagemParametros = "Data fim do período deve ser posterior à data de início.";
          return false;
        }
        return true
      }
      this.mensagemParametros = "Parâmetros inválidos.";
      return false;
    },



  },
};
</script>

<style>
</style>