<template>
  <crud
    v-if="urlBase"
    ref="crud"
    nomeEntidade="Organograma"
    nomeEntidadePlural="Divisões do Organograma"
    :headers="cabecalhos"
    :resource-url="urlBase"
    :query-params="parametros"
    itemKey="id"
    @clicou-item="selecionarParaEdicao"
    :clicou-salvar="salvar"
    @clicou-cancelar="cancelar"
    @clicou-novo="novo"
  >
    <template slot="beforeAdd">
      <v-switch
        v-if="podeCadastrarUnidadeGestora"
        tabindex="8"
        v-model="parametros.listarDeTodasUGs"
        label="Listar de todas UGs?"
        @change="aplicarListarDeTodasUGs"
      ></v-switch>
    </template>
    <template slot="detalhe">
      <v-card-text>
        <div v-if="entidadeAtual">
          <v-tabs v-model="tabAtiva" @change="mudouDeTab">
            <v-tab>Cadastro</v-tab>
            <v-tab :disabled="abaUsuariosHabilitada">Usuários</v-tab>
            <v-tabs-slider color="primary"></v-tabs-slider>

            <!-- tab Cadastro -->
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col xs="8">
                    <v-autocomplete
                      label="Divisão Pai"
                      :items="divisoesRaiz"
                      v-model="entidadeAtual.iddivisaoorganogramapai"
                      item-text="nome"
                      item-value="id"
                      :rules="entidadeAtual.id == null ? [validacao.obrigatorio] : []"
                      :required="entidadeAtual.iddivisaoorganogramapai == null ? false : true"
                      :loading="carregandoDivisoesRaiz"
                      :disabled="!this.podeCadastrarUnidadeGestora"
                    />
                  </v-col>
                  <v-col xs="4">
                    <v-switch
                      tabindex="8"
                      v-model="entidadeAtual.unidadegestora"
                      label="Unidade Gestora"
                      :disabled="!this.podeCadastrarUnidadeGestora"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="10" md="8">
                    <v-text-field
                      label="Nome"
                      v-model="entidadeAtual.nome"
                      :rules="[validacao.obrigatorio]"
                      required
                      :disabled="!podeEditarCadastro"
                    />
                  </v-col>
                  <v-col xs="2" md="4">
                    <v-text-field
                      label="Sigla"
                      v-model="entidadeAtual.sigla"
                      :rules="[validacao.obrigatorio]"
                      required
                      :disabled="!podeEditarCadastro"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <!-- fim da tab Cadastro -->
            <!-- Usuarios -->
            <v-tab-item>
              <v-container>
                <lista-dupla-selecao-filtravel
                  :disponiveis="usuariosDisponiveis"
                  :selecionados="usuariosSelecionados"
                  comSubtitulo
                  @change="mudouListaDuplaUsuarios"
                  propriedadeValor="id"
                  propriedadeTitulo="name"
                  propriedadeSubtitulo="cpf"
                />
              </v-container>
            </v-tab-item>
          </v-tabs>
        </div>
      </v-card-text>
    </template>
  </crud>
</template>
<script>
import rotas from "../../rotas-servico/";
import CRUD from "./../ApiCrud";
import ListaDuplaSelecaoFiltravel from './../ListaDuplaSelecaoFiltravel';
import EventBus from './../../event-bus'

const AUTH_ORGANOGRAMA_CADASTRAR_UNIDADE_GESTORA = "AUTH_ORGANOGRAMA_CADASTRAR_UNIDADE_GESTORA";
const AUTH_ORGANOGRAMA_CADASTRAR = "AUTH_ORGANOGRAMA_CADASTRAR";

export default {
  components: {
    crud: CRUD,
    'lista-dupla-selecao-filtravel': ListaDuplaSelecaoFiltravel
  },
  data: () => {
    return {
      cabecalhos: [
        {
          text: "Divisão Pai",
          value: "nomepai"
        },
        {
          text: "Nome",
          value: "nomediv"
        },
        {
          text: "Sigla",
          value: "sigladiv"
        }
      ],
      tabAtiva: 0,
      paginas: [],
      paginaAtual: [],

      usuarios: [],
      divisoesRaiz: [],
      carregandoDivisoesRaiz: false,

      entidadeAtual: null,
      carregando: false,
      validacao: {
        obrigatorio: value => !!value || "Preenchimento obrigatório.",
        min11: v =>
          (!!v && v.length >= 11) || "No mínimo 11 caracteres",
        min2: v => (!!v && v.length >= 2) || "No mínimo 2 caracteres",
        email: v => /.+@.+\..+/.test(v) || "E-mail precisa ser válido"
      },

      urlBase: rotas.controleAcesso.organograma.listar.url(),
      usuariosDisponiveis: [],
      usuariosSelecionados: [],

      podeEditar: false,
      podeCadastrarUnidadeGestora: false,
      parametros: {
        listarDeTodasUGs: false,
      },
    };
  },
  methods: {
    mudaPagina (page) {
    },

    selecionarParaEdicao (item) {
      this.tabAtiva = 0;
      this.entidadeAtual = item;
      this.$http.get(rotas.controleAcesso.organograma.obter.url({ id: item.id }))
        .then(response => {
          this.entidadeAtual = response.body
        },
          error => {
            console.log(error);
            EventBus.alerta("Erro ao obter organograma para edição. Por favor, recarregue a página e tente novamente.")
          })
      this.carregarDivisoes();
      this.limparListaDuplaUsuarios();
      this.formatarUsuarios(this.entidadeAtual);
    },

    salvar () {
      return new Promise((resolve, reject) => {
        try {
          let formData = new FormData();
          if (this.entidadeAtual.id) {
            formData.append("id", this.entidadeAtual.id);
          }
          formData.append("nome", this.entidadeAtual.nome);
          formData.append("sigla", this.entidadeAtual.sigla);
          if (this.entidadeAtual.iddivisaoorganogramapai != null) {
            formData.append("iddivisaoorganogramapai", this.entidadeAtual.iddivisaoorganogramapai);
          }
          formData.append("unidadegestora", this.entidadeAtual.unidadegestora ? 1 : 0);

          this.usuariosSelecionados.forEach(usuario => {
            formData.append("usuarios[]", usuario.id);
          });

          if (this.entidadeAtual.id != null) {
            formData.append("_method", "PUT");
          }
          let url =
            this.entidadeAtual.id === null
              ? rotas.controleAcesso.organograma.criar.url()
              : rotas.controleAcesso.organograma.editar.url({
                id: this.entidadeAtual.id
              })

          this.$http.post(url, formData).then(
            response => {
              EventBus.mensagem("Organograma atualizado com sucesso")
              resolve(response)
            },
            error => {
              reject(new Error(error.body.message))
            }
          );
        } catch (e) {
          reject(e)
        }
      })
    },

    cancelar () {
      this.tabAtiva = 0
    },

    novo (item) {
      this.tabAtiva = 0;
      this.entidadeAtual = {
        id: null,
        iddivisaoorganogramapai: this.unidadeGestora.id,
        nomepai: this.unidadeGestora.nome,
        usuarios: [],
      };
      this.carregarDivisoes();
      this.limparListaDuplaUsuarios();
    },

    carregarUsuarios () {
      // console.log("carregarUsuario()")
      this.carregando = true;
      this.usuariosDisponiveis = [];
      this.$http.get(rotas.controleAcesso.usuario.listar.url()).then(
        response => {
          // console.log(response)
          this.usuariosDisponiveis = response.body
          this.carregando = false;
        },
        error => {
          console.log(error);
          this.carregando = false;
        }
      );
    },

    carregarDivisoes () {
      this.divisoesRaiz = [];
      this.divisoesRaiz.push({
        id: this.entidadeAtual.iddivisaoorganogramapai,
        nome: this.entidadeAtual.nomepai
      })
      this.carregandoDivisoesRaiz = true
      this.$http.get(rotas.controleAcesso.organograma.listar.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.divisoesRaiz.push(element);
            });
          },
          error => {
            console.log(error);
            EventBus.alerta("Erro ao carregar divisões do organograma")
          }
        )
        .finally(() => {
          this.carregandoDivisoesRaiz = false
        })
    },

    mudouListaDuplaUsuarios (disponiveis, selecionados) {
      this.usuariosDisponiveis = disponiveis;
      this.usuariosSelecionados = selecionados;
    },

    limparListaDuplaUsuarios () {
      this.usuariosDisponiveis = [];
      this.usuariosSelecionados = [];
    },

    formatarUsuarios (divisaoOrganograma) {
      this.usuariosSelecionados = [];
      if (Array.isArray(divisaoOrganograma.usuarios)) {
        divisaoOrganograma.usuarios.forEach(usuario => {
          usuario.id = usuario.pivot.idusuario
          this.usuariosSelecionados.push(usuario)
        });
      }
    },

    mudouDeTab () {
      if (this.isTabUsuarios()) {
        this.carregarUsuarios();
      }
    },

    isTabUsuarios () {
      return this.tabAtiva == 1;
    },

    aplicarListarDeTodasUGs () {
      this.$refs.crud.loadItems();
    },

    inicializarPermissoesDoUsuarioAutenticado () {
      this.podeEditar = false;
      this.podeCadastrarUnidadeGestora = false;

      let permissoes = this.$store.getters["auth/permissoes"];
      if (permissoes == null) {
        return;
      }
      permissoes.forEach(permissao => {
        switch (permissao.permissao) {
          case AUTH_ORGANOGRAMA_CADASTRAR_UNIDADE_GESTORA:
            this.podeCadastrarUnidadeGestora = true;
            break;

          case AUTH_ORGANOGRAMA_CADASTRAR:
            this.podeEditar = true;

          default:
            break;
        }
      });
    },

  },
  computed: {
    abaUsuariosHabilitada () {
      return this.entidadeAtual == null || this.entidadeAtual.id == null
    },
    unidadeGestora () {
      return this.$store.getters["auth/superintendencia"];
    },
    podeEditarCadastro() {
      if (this.entidadeAtual == null) {
        return false
      }
      
      if(this.entidadeAtual.unidadegestora && !this.podeCadastrarUnidadeGestora) {
        return false;
      }

      return true
    }
  },
  mounted () {
    this.inicializarPermissoesDoUsuarioAutenticado();
  }
};
</script>
<style scoped>
.container {
  margin: 10pt;
}
.v-input--selection-controls {
  margin: 10px;
}
</style>
