<template>
  <crud
    v-if="urlBase"
    ref="crud"
    nomeEntidade="Geo Camada"
    nomeEntidadePlural="Geo Camadas"
    :headers="cabecalhos"
    :resource-url="urlBase"
    itemKey="id"
    @clicou-item="selecionarParaEdicao"
    :clicou-salvar="salvar"
    @clicou-cancelar="cancelar"
    @clicou-novo="novo"
    :for-each-item-callback="this.paraCadaItemDoResultado"
  >
    <template slot="detalhe">
      <div v-if="entidadeAtual">
        <v-container fluid>
          <v-row>
            <v-col xs="12" md="6">
              <v-text-field
                label="Título"
                v-model="entidadeAtual.titulo"
                :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(entidadeAtual.titulo, 8)]"
                required
                counter="100"
                maxlength="100"
              />
            </v-col>
            <v-col xs="12" md="6">
              <v-text-field
                label="Rótulo"
                v-model="entidadeAtual.rotulo"
                :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(entidadeAtual.rotulo, 3)]"
                required
                counter="150"
                maxlength="150"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col xs="12" md="3">
              <v-checkbox label="Ativa?" v-model="entidadeAtual.ativa"></v-checkbox>
            </v-col>
            <v-col xs="12" md="3">
              <v-checkbox label="Dados Estáticos?" v-model="entidadeAtual.estatica"></v-checkbox>
            </v-col>
            <v-col xs="12" md="6">
              <v-text-field
                label="Cor (em inglês ou código RGB Hexadecimal)"
                v-model="entidadeAtual.cor"
                counter="100"
                maxlength="100"
              />
            </v-col>
          </v-row>

          <template v-if="entidadeAtual.estatica">
            <v-row>
              <v-col xs="12" md="6">
                <v-text-field
                  label="Propiedade Título"
                  v-model="entidadeAtual.propriedadetitulo"
                  counter="100"
                  maxlength="100"
                />
              </v-col>
              <v-col xs="12" md="6">
                <v-text-field
                  label="Propriedade SubTítulo"
                  v-model="entidadeAtual.propriedadesubtitulo"
                  counter="100"
                  maxlength="100"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="12">
                <arquivo-input
                  label="Arquivo:"
                  mimes="text/plain,application/octet-stream,application/json"
                  disco="public"
                  diretorio="static/geo"
                  uploadURL="/api/arquivo/upload"
                  :arquivo="entidadeAtual.arquivo_geo"
                  @arquivo-selecionado="arquivoSelecionado"
                />
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row>
              <v-col xs="12" md="6">
                <v-text-field
                  label="Tabela Referência"
                  v-model="entidadeAtual.tabelareferencia"
                  counter="100"
                  maxlength="100"
                />
              </v-col>
              <v-col xs="12" md="6">
                <v-text-field
                  label="Coluna Id da Referência"
                  v-model="entidadeAtual.colunaidreferencia"
                  counter="100"
                  maxlength="100"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="12" md="6">
                <v-text-field
                  label="Coluna Título da Referência"
                  v-model="entidadeAtual.colunatituloreferencia"
                  counter="100"
                  maxlength="100"
                />
              </v-col>
              <v-col xs="12" md="6">
                <v-text-field
                  label="Coluna Subtítulo da Referência"
                  v-model="entidadeAtual.colunasubtituloreferencia"
                  counter="100"
                  maxlength="100"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col xs="12" md="6">
                <v-text-field
                  label="Rota Front-end"
                  v-model="entidadeAtual.rotafrontend"
                  counter="100"
                  maxlength="100"
                />
              </v-col>
            </v-row>
          </template>
        </v-container>
      </div>
    </template>
  </crud>
</template>

<script>
import rotas from './../../rotas-servico/'
import CRUD from './../ApiCrud'
import Validador from './../../validacao'
import EventBus from './../../event-bus'
//import ArquivoInput from './../ArquivoInput'

export default {
  components: {
    "crud": CRUD,
  },

  data () {
    return {
      urlBase: rotas.geo.camada.listar.url(),
      validacao: Validador,

      cabecalhos: [
        {
          text: 'Título',
          value: 'titulo'
        },
        {
          text: 'Ativa',
          value: 'txtAtiva'
        },
        {
          text: 'Estática',
          value: 'txtEstatica'
        }
      ],

      entidadeAtual: null,

    }
  },

  methods: {
    selecionarParaEdicao (item) {
      if (item.id) {
        let url = rotas.geo.camada.obter.url({
          id: item.id
        })
        this.$http.get(url).then(
          response => {
            // console.log(response)
            this.entidadeAtual = response.body
          },
          error => {
            console.log(error.body)
            EventBus.alerta(error.body.message)
          });
      } else {
        this.entidadeAtual = item
      }
      // console.log("GeoCamada.selecionarParaEdicao", this.entidadeAtual)
    },

    salvar () {
      return new Promise((resolve, reject) => {
        try {
          let url = rotas.geo.camada.criar.url();
          let formData = new FormData()
          if (this.entidadeAtual.id != null) {
            url = rotas.geo.camada.editar.url({
              id: this.entidadeAtual.id
            });
            formData.append('_method', 'PUT')
            formData.append('id', this.entidadeAtual.id)
          }
          formData.append('titulo', this.entidadeAtual.titulo)
          formData.append('rotulo', this.entidadeAtual.rotulo)
          formData.append('ativa', this.entidadeAtual.ativa ? 1 : 0)
          formData.append('estatica', this.entidadeAtual.estatica ? 1 : 0)
          if (this.entidadeAtual.estatica) {
            if (this.entidadeAtual.arquivo_geo != null && this.entidadeAtual.arquivo_geo.id != null) {
              formData.append('idArquivoGeo', JSON.stringify(this.entidadeAtual.arquivo_geo.id))
            }
            if (this.entidadeAtual.propriedadetitulo != null) {
              formData.append('propriedadetitulo', this.entidadeAtual.propriedadetitulo)
            }
            if (this.entidadeAtual.propriedadesubtitulo != null) {
              formData.append('propriedadesubtitulo', this.entidadeAtual.propriedadesubtitulo)
            }
          } else {
            if (this.entidadeAtual.tabelareferencia != null) {
              formData.append('tabelareferencia', this.entidadeAtual.tabelareferencia)
            }
            if (this.entidadeAtual.colunaidreferencia != null) {
              formData.append('colunaidreferencia', this.entidadeAtual.colunaidreferencia)
            }
            if (this.entidadeAtual.colunatituloreferencia != null) {
              formData.append('colunatituloreferencia', this.entidadeAtual.colunatituloreferencia)
            }
            if (this.entidadeAtual.colunasubtituloreferencia != null) {
              formData.append('colunasubtituloreferencia', this.entidadeAtual.colunasubtituloreferencia)
            }
            if (this.entidadeAtual.rotafrontend != null) {
              formData.append('rotafrontend', this.entidadeAtual.rotafrontend)
            }
          }

          if (this.entidadeAtual.cor != null) {
            formData.append('cor', this.entidadeAtual.cor)
          }
          this.$http.post(url, formData)
            .then(
              () => {
                EventBus.mensagem('Geo Camada salva com sucesso!')
                resolve()
              },
              error => {
                reject(new Error(error.body.message))
              }
            )
        } catch (e) {
          reject(e)
        }
      })
    },

    cancelar () {
    },

    novo () {
      this.entidadeAtual = {
      }
    },

    paraCadaItemDoResultado (item) {
      item.txtAtiva = item.ativa ? 'Ativada' : 'Desativada'
      item.txtEstatica = item.estatica ? 'Estática' : 'Dinâmica'
      return item
    },

    arquivoSelecionado (arquivo) {
      // console.log('GeoCamada.arquivoSelecionado()', arquivo)
      this.entidadeAtual.arquivo_geo = arquivo
    },

  },

}
</script>