import Rota from './Rota'

class Recurso {

    constructor(recurso, opcoes = {}, prefixo_path = Rota.separador()) {
        this.nome = recurso
        this._path = prefixo_path + Rota.separador() + (opcoes.path ? opcoes.path : this.nome)
        if(opcoes.rotasPadrao == null || opcoes.rotasPadrao) {
            this.construirRotasPadrao()
        }
        this.construirRotas(opcoes.rotas)
        this.url = Rota.prototype.url
    }

    construirRotasPadrao() {
        this.obter = new Rota('obter', { path: Rota.parametro('id') }, this._path)
        this.criar = new Rota('criar', { path: '' }, this._path)
        this.listar = new Rota('listar', { path: '' }, this._path)
        this.editar = new Rota('editar', { path: Rota.parametro('id')}, this._path)
        this.apagar = new Rota('apagar', { path: Rota.parametro('id')}, this._path)
    }

    construirRotas(rotas = []) {
        if(Array.isArray(rotas)) {
            rotas.forEach(rota => {
                this[rota.nome] = new Rota(rota.nome, rota, this._path)
            })
        }
    }

}

class Modulo {

    constructor(modulo, opcoes = {}, rotasServico) {

        this._nome = modulo
        this._rotasServico = rotasServico
        this.path = this.urlBase() + (opcoes.path ? opcoes.path : this.nome)
        
    }

    urlBase() {
        const r = this._rotasServico
        return r.urlBase
    }

    get nome() {
        return this._nome
    }

    construirRecursos(recursos = []) {
        if(Array.isArray(recursos)) {
            recursos.forEach(recurso => {
                this[recurso.nome] = new Recurso(recurso.nome, recurso, this.path)
                
            });
        }
    }

    construirRotas(rotas = []) {
        if(Array.isArray(rotas)) {
            rotas.forEach(rota => {
                this[rota.nome] = new Rota(rota.nome, rota, this.path)
            })
        }
    }
}

class RotasServico {

    constructor(options = {}) {
        this._urlBase = '/'
        if(options.url) {
            this._urlBase = options.url
        }

        this._modulos = {}
    }

    adicionarModulo(modulo) {
        this._modulos[modulo.nome] = modulo
    }

    get urlBase() {
        return this._urlBase
    }


    fetch(rotas) {

        let rotasF = {}
        rotas.modulos.forEach(modulo => {
            
            const novoModulo = new Modulo(modulo.nome, modulo, this)
            novoModulo.construirRecursos(modulo.recursos)
            novoModulo.construirRotas(modulo.rotas)

            this.adicionarModulo(novoModulo)
        });
        return rotasF;
    }

}

export default RotasServico