import Rota from './Rota'
import RotasServico from './RotasServico'

const _rotas = new RotasServico({
    url: process.env.VUE_APP_API_URL,
    // url: process.env.NODE_ENV == 'production' ? '/api/' : process.env.API_URL ? process.env.API_URL : '/api/',
})
//const cors = require('cors');
//app.use(cors());

_rotas.fetch({
    modulos: [
        // core
        {
            nome: 'core',
            rotas: [
                {
                    nome: 'info'
                }
            ]

        },
        // módulo de atendimento
        {
            nome: 'atendimentos',
            recursos: [
                {
                    nome: 'atendimento',
                    rotas: [
                        {
                            nome: 'porData',
                            path: 'por-data/' + Rota.parametro('data')
                        }
                    ],
                },
                {
                    nome: 'assunto'
                },
                {
                    nome: 'comentario'
                },
                {
                    nome: 'pendencia',
                    path: 'atendimento/' + Rota.parametro('atendimentoid') + '/pendencia'
                }
            ],
            rotas: [
                {
                    nome: 'atendente'
                }
            ]

        },
        // controle de acesso
        {
            nome: 'controleAcesso',
            path: 'auth',
            rotas: [
                {
                    nome: 'userData',
                    path: 'user'
                },
                {
                    nome: 'refresh',
                },
                {
                    nome: 'login',
                },
                {
                    nome: 'recuperarSenha',
                    path: 'recuperar-senha'
                },
                {
                    nome: 'trocarSenha',
                    path: 'trocar-senha'
                },
                {
                    nome: 'trocarEmail',
                    path: 'trocar-email'
                },
            ],
            recursos: [
                {
                    nome: 'menu'
                },
                {
                    nome: 'usuario'
                },
                {
                    nome: 'permissao',
                    rotas: [
                        {
                            nome: 'usuario'
                        }
                    ]
                },
                {
                    nome: 'organograma',
                    path: 'divisao-organograma',
                    rotas: [
                        {
                            nome: 'unidadeGestora',
                            path: 'unidade-gestora'
                        },
                        {
                            nome: 'unidadeGestoraDoUsuario',
                            path: 'unidade-gestora-usuario'

                        }
                    ]
                }
            ]
        },
        // módulo de demandas
        {
            nome: 'demandas',
            recursos: [
                {
                    nome: 'demanda',
                    rotas: [
                        {
                            nome: 'concluir',
                            path: 'concluir/' + Rota.parametro('id')
                        },
                        {
                            nome: 'cancelar'
                        },
                        {
                            nome: 'aguardarAssinatura',
                            path: 'aguardar-assinatura'
                        },
                        {
                            nome: 'aguardarAR',
                            path: 'aguardar-AR'
                        },
                        {
                            nome: 'resolver'
                        },
                        {
                            nome: 'porProcedimento',
                            path: 'por-procedimento/' + Rota.parametro('idprocedimentoexterno')
                        },
                        {
                            nome: 'pdf',
                            path: 'pdf/' + Rota.parametro('ids')
                        }
                    ]
                },
                {
                    nome: 'distribuicao'
                },
                {
                    nome: 'procedimentoExterno'
                },
                {
                    nome: 'tipoDocumento'
                },
                {
                    nome: 'situacaoDemanda'
                },
                {
                    nome: 'tipoProcedimentoExterno'
                },
                {
                    nome: 'poloProcedimentoExterno'
                },
                {
                    nome: 'demandante'
                },
                {
                    nome: 'cargo'
                },
                {
                    nome: 'orgao',
                },
                {
                    nome: 'naturezaOrgao',
                    path: 'natureza-orgao'
                },
                {
                    nome: 'relatorio',
                    rotasPadrao: false,
                    rotas: [
                        {
                            nome: 'entradaSaidaDiaria',
                            path: 'entrada-saida-diaria'
                        },
                        {
                            nome: 'abertasPorNaturezaOrgao',
                            path: 'abertas-natureza'
                        },
                        {
                            nome: 'abertasPorDemandante',
                            path: 'abertas-demandante'
                        }, {
                            nome: 'abertasPorDistribuicao',
                            path: 'abertas-distribuicao'
                        }, 
                        {
                            nome: 'abertasPorSituacao',
                            path: 'estatistica-periodo'
                        },
                        {
                            nome: 'abertasPorDivisaoOrganograma',
                            path: 'acompanhamento'
                        }
                    ]
                }
            ],
            rotas: [
                {
                    nome: 'entidadeAtribuivel'
                }
            ]
        },

        {
            nome: 'correspondencia',
            path: 'correspondencia',
            rotasPadrao: true,
            rotas: [
                { nome: 'pesquisaMunicipio', path: 'pesquisaMunicipio' + Rota.parametro('search')},
            ]
        },
        
        // módulo destinações
        {
            nome: 'destinacoes',
            path: 'destinacoes',
            rotasPadrao: true,
            rotas: [
                {
                    nome: 'destinacoes',
                    path: 'destinacoes'
                },
                {
                    nome: 'consultar',
                    path: 'destinacoes/'+ Rota.parametro('id')
                },
                {nome: 'editar', path: 'destinacoes/'+ Rota.parametro('id')},
                {nome: 'acao_judicial', path: 'acao_judicial'},
                {nome: 'consulta_geral', path: 'consulta_geral'},
                {nome: 'grupo_imovel', path: 'grupo_imovel'},
                {nome: 'categoria', path: 'categoria'},
                {nome: 'conceituacao', path: 'conceituacao'},
                {nome: 'conflito', path: 'conflito'},
                {nome: 'condicao_urbanizacao', path: 'cond_urbanizacao'},
                {nome: 'existe_processo', path: 'existe_processo'},
                {nome: 'existe_processo_destinacao', path: 'existe_processo_destinacao'},
                {nome: 'instrumento_destinacao', path: 'instrumento_destinacao'},
                {nome: 'tipo_ocupacao', path: 'tipo_ocupacao'},
                {nome: 'programa_especifico', path: 'programa_especifico'},
                {nome: 'entrada_imovel', path: 'entrada_imovel'},
                {nome: 'linha_programa', path: 'linha_programa'},
                {nome: 'estado_benfeitoria', path: 'estado_benfeitoria'},
                {nome: 'possui_benfeitoria', path: 'possui_benfeitoria'},
                {nome: 'util_especifica', path: 'util_especifica'},
                {nome: 'natureza', path: 'natureza'},
                {nome: 'proprietario_presumido', path: 'propr_presumida'},
                {nome: 'sit_incorporacao', path: 'sit_incorporacao'},
                {nome: 'situacao_ocupacao', path: 'sit_ocupacao'},
                {nome: 'tipo_interessado', path: 'tipo_interessado'},
                {nome: 'tipo_logradouro', path: 'tipo_logradouro'},                
/*
                {
                    nome: 'abertasPorNaturezaOrgao',
                    path: 'abertas-natureza'
                },
                {
                    nome: 'abertasPorDemandante',
                    path: 'abertas-demandante'
                }, {
                    nome: 'abertasPorDistribuicao',
                    path: 'abertas-distribuicao'
                }, 
                {
                    nome: 'abertasPorSituacao',
                    path: 'estatistica-periodo'
                },
                {
                    nome: 'abertasPorDivisaoOrganograma',
                    path: 'acompanhamento'
                }
*/                
            ]                
        },

        // módulo geo
        {
            nome: 'geo',
            recursos: [
                {
                    nome: 'camada',
                    rotas: [
                        {
                            nome: 'obterReferencia',
                            path: Rota.parametro('tabelareferenciada') + '/referencia/' + Rota.parametro('idreferenciado')
                        }
                    ]
                },
                {
                    nome: 'referencia',
                    rotas: [
                        {
                            nome: 'porCamada',
                            path: 'por-camada/' + Rota.parametro('idcamada')
                        },
                    ]
                }
            ]
        },
        // módulo de gestão de praias
        {
            nome: 'gestao_praias',
            path: 'gestao-praias',
            recursos: [
                {
                    nome: 'pgi'
                },
                {
                    nome: 'unidadePaisagem',
                    path: 'unidade-paisagem'
                }
            ]
        },
        
        // módulo de correspondencia
/*        
        {
            nome: 'correspondencia',
            path: 'correspondencia',
            recursos: [
                { nome: 'listar'},
                { nome: 'destinatarios'},
                { nome: 'setores'},
                { nome: 'tipos'},
                { nome: 'tipoPostagem'},
                { nome: 'logradouros'},
                { nome: 'pesquisaMunicipio'},
                { nome: 'descartaCorresp'},
                { nome: 'descartaDest'},
                { nome: 'criarDestinatario'},
                { nome: 'criarCorrespondencia'},
                { nome: 'inserirLista'},
                { nome: 'postagem'},
                { nome: 'gerarLista'},
                { nome: 'gerarEtiqueta'},
                { nome: 'concluirLista'},
                { nome: 'receberAR'},
                { nome: 'descartaDest'},
                { nome: 'descartarDestLista'}
            ]

        },
        //  módulo de Patrimonio
        {
            nome: 'patrimonio',
            rotas: [
                { nome: 'listar', rotasPadrao: false, path: 'listar'},
                { nome: 'divisao', rotasPadrao: false, path: 'divisao'},
                { nome: 'tabelas', rotasPadrao: false, path: 'tabelas'},
                { nome: 'locais', rotasPadrao: false, path: 'locais'},
                { nome: 'gerarRelatorio', rotasPadrao: false, path: 'gerarRelatorio'},
                { nome: 'gerarTermo', rotasPadrao: false, path: 'gerarTermo' + Rota.parametro('dados')},
                { nome: 'criar', rotasPadrao: false, path: 'criar/' + Rota.parametro('dados')},
                { nome: 'editar', rotasPadrao: false,  path: 'editar/' + Rota.parametro('dados')},
                { nome: 'criarLocal', rotasPadrao: false, path: 'criarLocal' + Rota.parametro('dados')}
            ]
        }
        */
    ]
})
//console.log("Rotas", _rotas._modulos.patrimonio)
export default _rotas._modulos