<template>
  <crud
    v-if="urlBase"
    ref="crud"
    nomeEntidade="Permissão"
    nomeEntidadePlural="Permissões"
    :headers="cabecalhos"
    :resource-url="urlBase"
    :query-params="parametros"
    itemKey="id"
    :exibirBotaoCadastrar="false"
    @clicou-item="selecionarParaEdicao"
    :clicou-salvar="salvar"
    @clicou-cancelar="cancelar"
    @clicou-novo="novo"
  >
    >
    <template slot="beforeAdd">
      <v-switch
        v-if="podeListarDeTodasUGs"
        tabindex="8"
        v-model="parametros.listarDeTodasUGs"
        label="Listar de todas UGs?"
        @change="aplicarListarDeTodasUGs"
      ></v-switch>
    </template>

    <template slot="detalhe">
      <v-card-text>
        <div v-if="entidadeAtual">
          <v-tabs v-model="tabAtiva" @change="mudouDeTab">
            <v-tab>Cadastro</v-tab>
            <v-tab :disabled="abaUsuariosHabilitada">Usuários</v-tab>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <!-- tab Cadastro -->
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col xs="10" md="8">
                    <v-text-field
                      label="Permissão"
                      v-model="entidadeAtual.permissao"
                      :rules="[validacao.obrigatorio]"
                      :disabled="true"
                      required
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="10" md="8">
                    <v-text-field
                      label="Descrição"
                      v-model="entidadeAtual.descricao"
                      :rules="[validacao.obrigatorio]"
                      required
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <!-- fim da tab Cadastro -->
            <!-- Usuarios -->
            <v-tab-item>
              <v-container>
                <lista-dupla-selecao-filtravel
                  :disponiveis="usuariosDisponiveis"
                  :selecionados="usuariosSelecionados"
                  comSubtitulo
                  @change="mudouListaDuplaUsuarios"
                  propriedadeValor="id"
                  propriedadeTitulo="name"
                  propriedadeSubtitulo="cpf"
                />
              </v-container>
            </v-tab-item>
          </v-tabs>
        </div>
      </v-card-text>
    </template>
  </crud>
</template>
<script>
import rotas from "../../rotas-servico/";
import EventBus from './../../event-bus'
import CRUD from "./../ApiCrud";
import ListaDuplaSelecaoFiltravel from './../ListaDuplaSelecaoFiltravel';

const AUTH_ORGANOGRAMA_CADASTRAR_UNIDADE_GESTORA = "AUTH_ORGANOGRAMA_CADASTRAR_UNIDADE_GESTORA";

export default {
  components: {
    crud: CRUD,
    'lista-dupla-selecao-filtravel': ListaDuplaSelecaoFiltravel
  },
  data: () => {
    return {
      cabecalhos: [
        {
          text: "Permissão",
          value: "permissao"
        },
        {
          text: "Descrição",
          value: "descricao"
        }
      ],
      tabAtiva: 0,
      paginas: [],
      paginaAtual: [],
      usuarios: [],
      divisoesRaiz: [],
      entidadeAtual: null,
      carregando: false,

      validacao: {
        obrigatorio: value => !!value || "Preenchimento obrigatório.",
        min11: v =>
          (!!v && v.length >= 11) || "No mínimo 11 caracteres",
        min2: v => (!!v && v.length >= 2) || "No mínimo 2 caracteres",
        email: v => /.+@.+\..+/.test(v) || "E-mail precisa ser válido"
      },

      urlBase: rotas.controleAcesso.permissao.listar.url(),
      usuariosDisponiveis: [],
      usuariosSelecionados: [],
      podeListarDeTodasUGs: false,
      parametros: {
        listarDeTodasUGs: false,
      },

    };
  },

  methods: {
    mudaPagina (page) {
    },

    selecionarParaEdicao (item) {
      this.carregarItens();
      this.limparListaDuplaUsuarios();
      this.tabAtiva = 0;
      this.entidadeAtual = item;
      this.formatarUsuarios(this.entidadeAtual);
    },

    carregarUsuarios () {
      this.carregando = true;
      this.usuariosDisponiveis = [];
      this.$http.get(rotas.controleAcesso.usuario.listar.url()).then(
        response => {
          this.usuariosDisponiveis = response.body
          this.carregando = false;
        },
        error => {
          console.log(error);
          this.carregando = false;
        },
      );
    },

    salvar () {
      return new Promise((resolve, reject) => {
        try {
          let formData = new FormData();

          formData.append("permissao", this.entidadeAtual.permissao);
          formData.append("descricao", this.entidadeAtual.descricao);
          this.usuariosSelecionados.forEach(usuario => {
            formData.append("usuarios[]", usuario.id);
          });

          if (this.entidadeAtual.id != null) {
            formData.append("_method", "PUT");
          }
          let url =
            this.entidadeAtual.id === null
              ? rotas.controleAcesso.permissao.criar.url()
              : rotas.controleAcesso.permissao.editar.url({ id: this.entidadeAtual.id });
          this.$http.post(url, formData).then(
            response => {
              EventBus.mensagem("Permissão atualizada com sucesso")
              resolve(response)
            },
            error => {
              reject(new Error(error.body.message))
            }
          );
        } catch (e) {
          reject(e)
        }
      })
    },
    cancelar () { },
    novo () {
      this.entidadeAtual = {
        id: null,
        usuarios: []
      };
      this.formatarUsuarios(this.entidadeAtual);
    },
    carregarItens () {
      this.carregando = true;
      this.registros = [];
      this.$http.get(rotas.controleAcesso.permissao.listar.url()).then(
        response => {
          response.body.forEach(element => {
            this.registros.push(element);
          });
        },
        error => {
          console.log(error);
        }
      );
      this.carregando = false;
    },

    mudouListaDuplaUsuarios (disponiveis, selecionados) {
      this.usuariosDisponiveis = disponiveis;
      this.usuariosSelecionados = selecionados;
    },

    limparListaDuplaUsuarios () {
      this.usuariosDisponiveis = [];
      this.usuariosSelecionados = [];
    },

    formatarUsuarios (permissao) {
      this.usuariosSelecionados = [];
      if (Array.isArray(permissao.usuarios)) {
        permissao.usuarios.forEach(usuario => {
          usuario.id = usuario.pivot.idusuario
          this.usuariosSelecionados.push(usuario)
        });
      }
    },

    mudouDeTab () {
      if (this.isTabUsuarios()) {
        this.carregarUsuarios();
      }
    },

    isTabUsuarios () {
      return this.tabAtiva == 1;
    },

    aplicarListarDeTodasUGs () {
      this.$refs.crud.loadItems();
    },

    inicializarPermissoesDoUsuarioAutenticado () {
      let permissoes = this.$store.getters["auth/permissoes"];
      this.podeListarDeTodasUGs = false;

      if (permissoes == null) {
        return;
      }

      /*            permissoes.forEach(permissao => {
                      switch (permissao.permissao) {
                          case AUTH_ORGANOGRAMA_CADASTRAR_UNIDADE_GESTORA:
                              this.podeListarDeTodasUGs = true;
                              break;                    
                          default:
                              break;
                      }
                  });
      */
    },
  },
  computed: {
    abaUsuariosHabilitada () {
      return this.entidadeAtual == null || this.entidadeAtual.id == null
    }
  },
  mounted () {
    this.inicializarPermissoesDoUsuarioAutenticado();
  }
};
</script>
<style scoped>
.container {
  margin: 10pt;
}
.v-input--selection-controls {
  margin: 10px;
}
</style>
