<template>
  <crud v-if="urlBase" ref="crud"
      nomeEntidade="Plano de Gestão Integrada" nomeEntidadePlural="Planos de Gestão Integrada"
      :headers="cabecalhos" 
      :resource-url="urlBase"
      itemKey="id"
      @clicou-item="selecionarParaEdicao" 
      @clicou-salvar="salvar"
      @clicou-cancelar="cancelar"
      @clicou-novo="novo">

      <template slot="topFormArea" v-if="entidadeAtual">
        PGI {{ entidadeAtual.municipio + '/' + entidadeAtual.uf + ' - ' + entidadeAtual.ano }}
      </template>

      <template slot="detalhe">
        <div v-if="entidadeAtual">
          <v-layout row wrap>
            <v-flex xs12 pt-6>
              <v-card fluid>
                  <v-card-text>
                      <v-tabs v-model="tabAtiva" @change="mudouDeTab">
                          <v-tab>Quadro Geral</v-tab>
                          <v-tab>Diagnóstico</v-tab>
                          <v-tab>Unidades de Paisagem</v-tab>

                          <v-tabs-slider color="primary"></v-tabs-slider>

                          <!-- TAB CADASTRO -->
                          <v-tab-item>
                            
                            <v-container fluid mx-4 pt-8>
                              <v-layout row wrap>
                                <v-flex xs2>
                                  <v-text-field label="Ano" v-model="entidadeAtual.ano" 
                                      :rules="[validacao.obrigatorio]" required 
                                      counter="4" maxlength="4"/>
                                </v-flex>
                                <v-flex xs2>
                                  <v-autocomplete label="UF" clearable
                                      :items="ufs" v-model="entidadeAtual.uf" 
                                      :rules="[validacao.obrigatorio]" required 
                                      item-text="uf" item-value="uf"
                                      />
                                      <!--:loading="carregandoNaturezas"  -->
                                </v-flex>
                                <v-flex xs8>
                                  <v-autocomplete label="Município" clearable
                                      :items="municipios" v-model="entidadeAtual.municipio" 
                                      :rules="[validacao.obrigatorio]" required 
                                      item-text="municipio" item-value="municipio"
                                      />
                                      <!--:loading="carregandoNaturezas"  -->
                                </v-flex>
                                <v-flex xs12>
                                  <v-textarea tabindex="10" 
                                    name="input-7-4"
                                    label="Características físico-naturais:"
                                    v-model="entidadeAtual.caracteristicasfisiconaturais"
                                    :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(entidadeAtual.caracteristicasfisiconaturais, 8)]" required>
                                  </v-textarea>
                                </v-flex>
                                <v-flex xs12>
                                  <v-textarea tabindex="10" 
                                    name="input-7-4"
                                    label="Características sócio-econômicas:"
                                    v-model="entidadeAtual.caracteristicassocioeconomicas"
                                    :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(entidadeAtual.caracteristicassocioeconomicas, 8)]" required>
                                  </v-textarea>
                                </v-flex>
                              </v-layout>
                            </v-container>

                          </v-tab-item>

                          <!-- TAB DIAGNOSTICO -->
                          <v-tab-item>
                            <v-container fluid ma-7 pa-7>
                              <v-layout row wrap>
                                <v-flex xs4>
                                  <v-switch tabindex="8" label="Possui plano diretor?"></v-switch>
                                </v-flex>
                                <v-flex xs12>
                                    <h3>Legislação Correlata</h3>
                                </v-flex>
                                <v-flex xs12>
                                  <v-textarea tabindex="10" 
                                    name="input-7-4"
                                    label="Código de Obras:"
                                    v-model="entidadeAtual.caracteristicasfisiconaturais"
                                    :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(entidadeAtual.caracteristicasfisiconaturais, 8)]" required>
                                  </v-textarea>
                                </v-flex>
                                <v-flex xs12>
                                  <v-textarea tabindex="10" 
                                    name="input-7-4"
                                    label="Tombamentos:"
                                    v-model="entidadeAtual.caracteristicasfisiconaturais"
                                    :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(entidadeAtual.caracteristicasfisiconaturais, 8)]" required>
                                  </v-textarea>
                                </v-flex>
                                <v-flex xs12>
                                  <v-textarea tabindex="10" 
                                    name="input-7-4"
                                    label="Legislação Ambiental:"
                                    v-model="entidadeAtual.caracteristicasfisiconaturais"
                                    :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(entidadeAtual.caracteristicasfisiconaturais, 8)]" required>
                                  </v-textarea>
                                </v-flex>
                                <v-flex xs12>
                                  <v-textarea tabindex="10" 
                                    name="input-7-4"
                                    label="Segurança Nacional:"
                                    v-model="entidadeAtual.caracteristicasfisiconaturais"
                                    :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(entidadeAtual.caracteristicasfisiconaturais, 8)]" required>
                                  </v-textarea>
                                </v-flex>
                                
                              </v-layout>
                            </v-container>
                          </v-tab-item>

                          <!-- TAB UNIDADES DE PAISAGEM -->
                          <v-tab-item>
                            <v-container fluid ma-7 pa-7>
                              <v-layout row wrap>
                                <v-flex xs12>
                                  <crud ref="crudUnidadesPaisagem"
                                    nomeEntidade="Unidade de Paisagem" nomeEntidadePlural="Unidades de Paisagem"
                                        :headers="unidadesPaisagem.cabecalhos"
                                        :items="entidadeAtual.unidadesPaisagem"
                                        item-key="id"
                                        :resource-url="unidadesPaisagem.urlBase">
                                  </crud>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-tab-item>
                      </v-tabs>
                  </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </div>
      </template>

  </crud>
</template>

<script>
import CRUD from './../../../components/ApiCrud.vue'
import rotas from './../../../rotas-servico'
import Validador from './../../../validacao'

const PROCEDIMENTO_TAB_GERAL = 0;
const PROCEDIMENTO_TAB_DIAGNOSTICO = 1;
const PROCEDIMENTO_TAB_UNIDADES_PAISAGEM = 2;

export default {
  components: {
    'crud': CRUD
  },

  data: () => {
    return {
      cabecalhos: [
        {
          text: 'Ano',
          value: 'ano'
        },
        {
          text: 'UF',
          value: 'uf'
        },
        {
          text: 'Município',
          value: 'municipio'
        }
      ],
      urlBase: rotas.gestao_praias.pgi.listar.url(),
      entidadeAtual: null,
      validacao: Validador,
      tabAtiva: PROCEDIMENTO_TAB_GERAL,

      //unidades paisagem
      unidadesPaisagem: {
        
        cabecalhos: [
          {
            text: 'Unidade de Paisagem',
            value: 'nome'
          }
        ],
        urlBase: rotas.gestao_praias.unidadePaisagem.listar.url(),
        entidadeAtual: null,
      },


      // mock
      ufs: [
        {
          id: 1,
          uf: 'DF'
        },
        {
          id: 2,
          uf: 'PR'
        },
        {
          id: 3,
          uf: 'SC'
        },
        {
          id: 4,
          uf: 'SP'
        },
      ],
      municipios: [
        {
          id: 1,
          municipio: 'Florianópolis'
        },
        {
          id: 2,
          municipio: 'Itajaí'
        }
      ]
    }
  },

  methods: {
    
    salvar() {

    },

    selecionarParaEdicao(item) {
      console.log('Item selecionado: ', item)
      this.entidadeAtual = item
    },

    cancelar() {

    },

    novo() {

    },

    mudouDeTab() {
    },

  }

}
</script>

<style>

</style>