<template>
<div>
    <div class="map-container">
      <div class="info-container" :style="{ display: selectedFeature ? 'block' : 'none' }">
        <button @click="closeFeatureInfo">Fechar</button>
        <div class="feature-info" v-html="selectedFeatureInfo"></div>
      </div>
      <div ref="map" class="map"  style="height: 700px; float: left;"></div>
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default {
  name: 'MapComponent',
  data() {
    return {
      mapWidthPercentage: 100, 
      map: null,
      layers: [],
      layerControls: {},
      selectedCategories: {},
      categories: {},
      selectedFeatureInfo: '',
      selectedFeature: null,
      originalZoom: null,
      originalCenter: null,
    };
  },
  mounted() {
    console.log("geojsonLayer1");
    this.initMap();
  },
  methods: {
    initMap() {
      console.log("entrei initMap")
      this.map = L.map(this.$refs.map, {minZoom:5, maxZoom:16}).setView([-15.6893903,-56.2697908], 5);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {minZoom:5, maxZoom: 16,}).addTo(this.map);
      this.geojsonLayer = L.geoJSON().addTo(this.map);
      const currentZoom = this.map.getZoom();
      console.log('Nível de Zoom Atual:', currentZoom);
      const geojsonFeature1 = {
        type: 'Feature',
        properties: {
          category: 'Camada 1',
          "Classe" : "DOMINIAL",
          "Número do RIP" : "0888010000105",
          "Id. Utilização" : "001",
          "Data de cadastramento" : "22-03-2005",
          "UF" : "SC",
          "Município" : "BALNEARIO ARROIO DO SILVA",
          "Endereço" : "AV BEIRA MAR S/N EM FRENTE AO N 547 CEP: 88914000",
          "Bairro" : "AREIAS BRANCAS",
          "Conceituação" : "MARINHA",
          category : "TERRENO",
          subcategory : "OCUPACAO                      ",
          "Situação da Utilização" : "EM USO",
          "Proprietário Oficial" : "União (Adm. Pub. Fed. direta)",
          "Data do Início da Utilização" : "01-01-1990",
          "Área do Terreno Total" : "         455,70",
          "Área da União" : "         455,70",
          "Nível Precisão Imóvel" : "Manual - Na rua ou na via de acesso rural"
        },
        geometry: {
            type: 'Polygon',
          coordinates: [
            [
              [-48.540344,-27.316646],[-48.539572,-27.32328],[-48.537941,-27.326483],[-48.535023,-27.331591],[-48.532534,-27.334031],
              [-48.534336,-27.33365],[-48.537941,-27.333421],[-48.542404,-27.332888],[-48.544722,-27.324729],[-48.543606,-27.319543],
              [-48.542833,-27.317713],[-48.540344,-27.316646]
            ]
        ]
        }
      };

      const geojsonFeature2 = {
        type: 'Feature',
        properties: {
          category1: 'Camada 2',
          "Classe" : "DOMINIAL",
          "Número do RIP" : "0888010000377",
          "Id. Utilização" : "002",
          "Data de cadastramento" : "19-03-2007",
          "UF" : "SC",
          "Município" : "BALNEARIO ARROIO DO SILVA",
          "Endereço" : "R FRANCISCO LEONARDELLI S/N  CEP: 88914000",
          "Bairro" : "BALNEARIO ERECHIM",
          "Conceituação" : "ACRESCIDO DE MARINHA",
          category : "CASA",
          subcategory: "OCUPACAO                      ",
          "Situação da Utilização" : "EM USO",
          "Proprietário Oficial" : "União (Adm. Pub. Fed. direta)",
          "Data do Início da Utilização" : "01-11-2017",
          "Área do Terreno Total" : "         592,14",
          "Área da União" : "         592,14",
          "Nível Precisão Imóvel" : "Manual - Na rua ou na via de acesso rural"
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [-48.627403,-27.219029],[-48.627318,-27.21869],[-48.627613,-27.218624],[-48.627918,-27.218576],
              [-48.628026,-27.218905],[-48.630847,-27.218237],[-48.630831,-27.218104],[-48.631679,-27.217894],
              [-48.63184,-27.218924],[-48.634458,-27.22441],[-48.636281,-27.230115],[-48.629973,-27.230706],
              [-48.626298,-27.219311],[-48.627403,-27.219029]
            ]   
        ]
        }
      };

      const geojsonFeature3 = {
        type: 'Feature',
        properties: {
          category1: 'Camada 3',
          "Classe" : "DOMINIAL",
          "Número do RIP" : "0888010002906",
          "Id. Utilização" : null,
          "Data de cadastramento" : "11-05-2022",
          "UF" : "SC",
          "Município" : "BALNEARIO ARROIO DO SILVA",
          "Endereço" : "R LETICIA ALESSIO BERTI S/N LOTE 01, QUADRA 01 CEP: 88914000",
          "Bairro" : "BALNEARIO SALINA",
          "Conceituação" : "MARINHA",
          category: "TERRENO",
          subcategory: "SEM REGISTRO DE UTILIZAÇÃO",
          "Situação da Utilização" : "SEM REGISTRO",
          "Proprietário Oficial" : "União (Adm. Pub. Fed. direta)",
          "Data do Início da Utilização" : null,
          "Área do Terreno Total" : "         389,06",
          "Área da União" : "         389,06",
          "Nível Precisão Imóvel" : "Manual - No Bairro ou na localidade rural"
        },
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [-48.609352,-27.570069],[-48.578796,-27.583155],[-48.57605,-27.593501],[-48.581543,-27.602324],[-48.587036,-27.6078],
              [-48.594589,-27.602324],[-48.619308,-27.595935],[-48.609352,-27.570069]
            ]
          ]
        }
      };

      this.loadGeoJSONData(geojsonFeature1);
      this.loadGeoJSONData(geojsonFeature2);
      this.loadGeoJSONData(geojsonFeature3);
      this.map.on('zoomend', () => {
        // Acessa o nível de zoom atual usando this.map.getZoom()
        const currentZoom = this.map.getZoom();
        
        // Exibe o nível de zoom no console
        console.log('Nível de Zoom Atual:', currentZoom);
      });
    },    
    loadGeoJSONData(geojson) {
      const category = geojson.properties.category;
      const subcategory = geojson.properties.subcategory;

      if (!this.layerControls[category]) {
        this.layerControls[category] = L.control.layers(null, null, {
          position: 'topright',
        });

        const controlHeader = L.DomUtil.create('div', 'control-header');
        controlHeader.innerHTML = `<strong>${category}</strong>`;
        controlHeader.addEventListener('click', () => this.toggleSelectAll(category));
        this.layerControls[category].addTo(this.map);
        this.layerControls[category]._container.insertBefore(controlHeader, this.layerControls[category]._container.firstChild);
      }

      const layer = L.geoJSON(geojson, {
        onEachFeature: (feature, layer) => {
          //layer.bindPopup(`Category: ${category}<br>Subcategory: ${subcategory}`);
          layer.on('click', (event) => this.showFeatureInfo(event, feature));
        },
      });

      // Adicione a camada ao controle de camadas
      this.layerControls[category].addOverlay(layer, subcategory);

      // Marque todas as camadas da categoria por padrão
      const overlayInputs = this.layerControls[category]._overlaysList.querySelectorAll('input[type="checkbox"]');
      overlayInputs.forEach(input => {
        input.checked = true;
        this.layerControls[category]._onInputClick();
      });

      // Inicialize o estado selecionado como verdadeiro
      this.selectedCategories[category] = true;
    },
    showFeatureInfo(event, feature) {
      const featureInfo = Object.entries(feature.properties)
        .map(([key, value]) => `<strong>${key}:</strong> ${value}`)
        .join('<br>');
      const clickedLayer = event.target;
      this.originalZoom = this.map.getZoom();
      this.originalCenter = this.map.getCenter();
      this.selectedFeatureInfo = featureInfo;
      this.selectedFeature = feature;
      this.map.fitBounds(clickedLayer.getBounds());
    },

    adjustMapZoomAndCenter(layer) {
      const bounds = layer.getBounds();
      this.map.fitBounds(bounds);
    },
    closeFeatureInfo() {
      // Voltar para o nível e centro originais do mapa
      //console.log(this.selectedFeatureInfo)
      if (this.originalZoom !== null && this.originalCenter !== null) {
        this.map.setView(this.originalCenter, this.originalZoom);
      }

      // Limpar os valores originais
      this.originalZoom = null;
      this.originalCenter = null;

      // Limpar as informações da feature
      this.selectedFeatureInfo = '';
      this.selectedFeature = null;

    },
    toggleSelectAll(category) {
      this.selectedCategories[category] = !this.selectedCategories[category];
      const overlayInputs = this.layerControls[category]._overlaysList.querySelectorAll('input[type="checkbox"]');
      overlayInputs.forEach(input => {
        input.checked = this.selectedCategories[category];
        this.layerControls[category]._onInputClick();
      });
    },
   
},
}
</script>
<style>
.control-header {
  background-color: #f4f4f4;
  padding: 5px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  margin-top: 5px;
  cursor: pointer;
}

.control-header:hover {
  background-color: #ddd;
}

.control-header:active {
  background-color: #bbb;
}

.control-header:active .invert-selection {
  text-decoration: underline;
}

.control-option {
  cursor: pointer;
  margin-top: 5px;
  color: blue;
  text-decoration: underline;
}

.invert-selection {
  color: black;
  text-decoration: none;
}
.leaflet-control-layers-toggle {
  display: none !important;
}
.map-container {
  display: flex;
}

.map {
  flex: 3;
  width: 75%;
}

.info-container {
  flex: 1;
  width: 25%;
  padding: 20px;
  box-sizing: border-box;
}

.feature-info {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
}
.info-container {
  display: none;
}

.info-container.active {
  display: block;
}
</style>