<template>
    <div>
        <v-snackbar :value="mensagem" color="success" bottom>{{ mensagem }}</v-snackbar>
        <v-snackbar :value="aviso" color="amber" bottom><span class="grey--text text--darken-4">{{ aviso }}</span></v-snackbar>
        <v-snackbar :value="alerta" color="error" bottom>{{ alerta }}</v-snackbar>
    </div>
</template>

<script>
import EventBus from './../../event-bus'
export default {
    methods: {
        limparMensagemAposIntervalo () {
            return new Promise(() => {
                setTimeout(() => {
                    // console.log(this.$store.getters['sistema/alerta'])
                    this.$store.commit('sistema/limpar')
                }, 5000)
            })
        },
    },
    computed: {
        mensagem () {
            this.limparMensagemAposIntervalo();
            return this.$store.getters['sistema/mensagem'];
        },
        aviso () {
            this.limparMensagemAposIntervalo();
            return this.$store.getters['sistema/aviso'];
        },
        alerta () {
            this.limparMensagemAposIntervalo();
            return this.$store.getters['sistema/alerta'];
        },
    },
    created() {
        EventBus.$on('mensagem', (mensagem) => {
            this.$store.commit("sistema/mensagem", mensagem);
        });
        EventBus.$on('mensagem-aviso', (mensagem) => {
            this.$store.commit("sistema/aviso", mensagem);
        });
        EventBus.$on('mensagem-alerta', (mensagem) => {
            this.$store.commit("sistema/alerta", mensagem);
        });
  }
}
</script>
