<template>
    <v-app-bar clipped-left color="green darken-2" dark app fixed>
        <v-app-bar-nav-icon v-if="usuario" @click.stop="mostraEscondeMenu" />
        <v-toolbar-title class="ml-0 pl-0">
            <v-toolbar-title>
                <div>SIGA</div>
                <div v-if="superintendencia" class="font-weight-light" style="font-size: 9pt;">
                    {{ superintendencia.nome }}
                </div>
            </v-toolbar-title>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <div v-if="usuario">
            <v-btn
                v-if="exibirTempoSessao"
                small
                rounded
                @click="refreshToken"
                :color="cor_token_valido_ate_fundo"
                :outlined="!tempoSessaoTerminando"
                :text-color="cor_token_valido_ate"
            >
                <small>Sessão expira em {{ token_valido_ate }}</small>
            </v-btn>
            <!-- menu do usuário -->
            <v-menu offset-y>
                <template v-slot:activator="{on, attrs}">
                    <v-btn icon 
                        v-bind="attrs"
                        v-on="on">
                        <v-icon>account_box</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item dense two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{ usuario.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ usuario.email }}<br></v-list-item-subtitle>
                            <v-list-item-subtitle><br></v-list-item-subtitle>
                            <v-list-item-subtitle>Sessão expira em {{ token_valido_ate }}</v-list-item-subtitle>
                            <v-list-item-action>
                                <v-list-item-action-text>
                                    <router-link to="trocar-senha">Trocar Senha</router-link>
                                </v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-btn icon title="Sair" @click="sair">
                <v-icon>exit_to_app</v-icon>
            </v-btn>
        </div>
        <div v-else>
            <v-btn color="primary" title="Entrar" @click="entrar">
                Entrar
                <v-icon>launch</v-icon>
            </v-btn>
        </div>
    </v-app-bar>
</template>

<script>
import jwt from 'jsonwebtoken'
import rotas from "../../rotas-servico/";
import EventBus from './../../event-bus'
export default {
    components: {},
    data: () => {
        return {
            drawer: null,
            token_valido_ate: new Date(),
            cor_token_valido_ate: "white",
            cor_token_valido_ate_fundo: "white",
            tempoSessaoTerminando: false,
            exibirTempoSessao: false
        };
    },
    methods: {
        sair() {
            this.$store.commit("auth/sair");
            if (this.$router.history.current.path != "/") {
                this.$router.push("/");
            }
        },
        entrar() {
            this.$router.push("/login");
        },
        mostraEscondeMenu() {
            this.$store.commit("sistema/exibirMenu");
        },
        refreshToken() {
            let refresh_token = this.$store.getters["auth/refresh_token"]
            if(refresh_token) {
                this.$http.post(rotas.controleAcesso.refresh.url(), {
                    'refresh_token' : refresh_token
                }).then(
                    response => {
                        if(response.body && response.body.error) {
                            EventBus.alerta('E-mail e/ou senha inválidos')
                        } else {
                            EventBus.$emit('App::onLogin', response.data)
                        }
                    },
                    () => {
                        EventBus.alerta("Erro ao reiniciar tempo de sessão")
                    }
                );
            } else {
                this.$http.post("/oauth/refresh-token")
                    .then(
                        response => {
                            let accessToken = response.body
                            let decoded = jwt.decode(accessToken)
                            EventBus.$emit('App::onLogin', { 
                                access_token: accessToken,
                                expires_in: new Date(decoded.exp * 1000)
                            })
                        },
                        () => {
                            EventBus.alerta("Erro ao reiniciar tempo de sessão")
                        })
            }
        },
        formatDate(date) {
            if (!date) return null;

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        atualizaTempoValidadeToken() {
            let  token_expira_em = this.$store.getters["auth/token_expira_em"];
            const dif = token_expira_em - new Date().getTime()
            var theMinutes = new Date(dif).getTime() / (1000 * 60);
            if (dif < 0) {
                this.$store.commit("auth/sair", null);
                if (this.$router.currentRoute.meta.requiresAuth) {
                    this.$router.push("/login");
                }
                return;
            }
            
            this.cor_token_valido_ate = "white";
            this.cor_token_valido_ate_fundo = "white";
            this.tempoSessaoTerminando = false;

            let difDate = new Date(dif);
            let min = Math.trunc(difDate.getTime() / (1000 * 60));

            //console.log('min ', min)
            if (min > 60) {
                this.exibirTempoSessao = false;
                this.token_valido_ate =
                        Math.trunc(min) + " minutos";
            } else {
                this.exibirTempoSessao = true;
                if (min > 0) {
                    this.token_valido_ate =
                        min + " minuto" + (min > 1 ? "s" : "");
                } else {
                    this.tempoSessaoTerminando = true;
                    this.cor_token_valido_ate = "white";
                    this.cor_token_valido_ate_fundo = "red";
                    let seg = difDate.getSeconds();
                    this.token_valido_ate =
                        seg + " segundo" + (seg > 1 ? "s" : "");
                }
            }

            //this.exibirTempoSessao = true;
        }
    },
    computed: {
        usuario() {
            const usuario = this.$store.getters["auth/usuario"]
            return usuario;
        },
        superintendencia() {
            return this.$store.getters["auth/superintendencia"];
        }
    },
    created() {
        setInterval(this.atualizaTempoValidadeToken, 1000);
    }
};
</script>
