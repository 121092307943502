import Dashboard from './components/Dashboard'
import Demanda from './components/Demanda'
import ProcedimentoExterno from './components/ProcedimentoExterno'
import Demandante from './components/Demandante'
import Orgao from './components/Orgao'
import NaturezaOrgao from './components/NaturezaOrgao'

export default {
    rotas: {

        frontend: [
            {
                path: '/painel-demandas',
                name: 'Demandas',
                component: Dashboard,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [{
                        text: 'Início',
                        href: '/#/'
                    },
                    {
                        text: 'Demandas',
                        href: '/#/painel-demandas'
                    },
                    {
                        text: 'Painel'
                    },
                    ]
                }
            },
            {
                path: '/demanda',
                name: 'Demanda',
                component: Demanda,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [{
                        text: 'Início',
                        href: '/#/'
                    },
                    {
                        text: 'Demandas',
                        href: '/#/painel-demandas'
                    },
                    {
                        text: 'Demandas'
                    }
                    ]
                }
            },
            {
                path: '/demanda/:id',
                name: 'DemandaEditor',
                component: Demanda,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [{
                        text: 'Início',
                        href: '/#/'
                    },
                    {
                        text: 'Demandas',
                        href: '/#/painel-demandas'
                    },
                    {
                        text: 'Demanda'
                    },
                    ]
                }
            },
            {
                path: '/procedimento',
                name: 'ProcedimentoExterno',
                component: ProcedimentoExterno,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [{
                        text: 'Início',
                        href: '/#/'
                    },
                    {
                        text: 'Demandas',
                        href: '/#/painel-demandas'
                    },
                    {
                        text: 'Procedimentos Externos'
                    },
                    ]
                }
            },
            {
                path: '/procedimento/:id',
                name: 'ProcedimentoExternoEditor',
                component: ProcedimentoExterno,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [{
                        text: 'Início',
                        href: '/#/'
                    },
                    {
                        text: 'Demandas Judiciais',
                        href: '/#/painel-demandas'
                    },
                    {
                        text: 'Procedimentos Externos'
                    },
                    ]
                }
            },
            {
                path: '/demandante',
                name: 'Demandante',
                component: Demandante,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [{
                        text: 'Início',
                        href: '/#/'
                    },
                    {
                        text: 'Demandas',
                        href: '/#/painel-demandas'
                    },
                    {
                        text: 'Demandantes'
                    },
                    ]
                }
            },
            {
                path: '/orgao',
                name: 'Orgao',
                component: Orgao,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [{
                        text: 'Início',
                        href: '/#/'
                    },
                    {
                        text: 'Demandas',
                        href: '/#/painel-demandas'
                    },
                    {
                        text: 'Órgãos'
                    },
                    ]
                }
            },
            {
                path: '/natureza-orgao',
                name: 'NaturezaOrgao',
                component: NaturezaOrgao,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [{
                        text: 'Início',
                        href: '/#/'
                    },
                    {
                        text: 'Demandas',
                        href: '/#/painel-demandas'
                    },
                    {
                        text: 'Naturezas dos Órgãos'
                    },
                    ]
                }
            },
        ]

    }
}