<template>

    <v-layout row wrap>
      <v-flex 12>
        <v-card>
          <v-card-text>
            <div>
              <v-tabs>
                <v-tab @click="alterarVisibilidadeMapa(false)">teste</v-tab>
                <v-tab @click="alterarVisibilidadeMapa(true)">GeoPosição</v-tab>
                <v-tab-item> teste </v-tab-item>
                <v-tab-item></v-tab-item>
              </v-tabs>
              <div v-show="mostrarMapa" id="map" ref="map" style="height: 700px; width: 100%;"></div>
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
</template>

<script>

import L from 'leaflet';
import 'vue2-leaflet-draw-toolbar';
import "leaflet/dist/leaflet.css";
const WatermarkControl = L.Control.extend({
  onAdd: function(map) {
    const watermark = L.DomUtil.create('div', 'watermark-control');
    watermark.innerHTML = 'SPU-SC';
    return watermark;
  }
});
export default {
  name: 'MapEditor',

  data() {
    return {
      aplicarListarDeTodasUGs:'',
      parametrosConsultaAPI:'',
      usuarioTemVisaoTotal: false,
//      urlBase: rotas.destinacoes.destinacoes.url(),
      mostrarMapa: true,
      showMap: false,
      map: null,
      drawnItems: null,
      polygonInfo: '',
      editMode: false,
      editControl: null,
      canDrawPolygon: true,
      cabecalhos: [
        {
          text: 'Tipo',
          value: 'tipoprocedimentoexterno'
        },
        {
          text: 'Procedimento',
          value: 'procedimento'
        },
        {
          text: 'Resumo',
          value: 'resumo'
        }
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      //this.showMap = true;
      this.initMap();
      this.addGeoJSONToEditLayer();
    });    

   
  },
  methods: {
    novo(){
      this.showMap = true;
      if (this.$refs.map) {
        this.initMap();
        this.addGeoJSONToEditLayer();
      }
    },

    initMap() {
      //this.map = L.map(this.$refs['mapElement'],  {minZoom:5, maxZoom:20,})
      console.log("entrei map")
      this.map = L.map(this.$refs.map, {minZoom:5, maxZoom:18})
      //this.map = L.map(this.$refs.map.$el, {minZoom:5, maxZoom:18})
      
      //.setView([51.505, -0.09], 13);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
      
      const watermarkControl = new WatermarkControl({ position: 'topright' });
      watermarkControl.addTo(this.map);

      this.drawnItems = new L.FeatureGroup().addTo(this.map);

      const drawControlGeral = new L.Control.Draw({
        edit: {
          featureGroup: this.drawnItems,
        },
        draw: {
          rectangle: false, 
          polyline: false, 
          marker: false,
          circle: false,
          circlemarker: false,
          polygon:{
              allowIntersection: false, 
              drawError: {
                color: 'orange',
                timeout: 2000,
                message: '<strong>Nicht erlauben<strong>',
              },
              showArea: true,
              metric: true, //m2
              repeatMode: false,
              shapeOptions: {
                polylineID: false,
                color: '#3388ff',
                fillColor: '#3388ff',
                weight: 3,
                fillOpacity: 0.2,
                lineCap: 'round',
                lineJoin: 'bevel',
                dashArray: '',
                opacity: 1,
              },
            },
        },
      });
      const drawControlCreated = new L.Control.Draw({
          edit: {
            featureGroup: this.drawnItems,
          },
          draw: false,
          rectangle: false, 
          polyline: false, 
          marker: false,
          circle: false,
          circlemarker: false
        });

      this.map.addControl(drawControlCreated);

      this.map.on('draw:created', (e) => {
        const layer = e.layer;
        this.drawnItems.addLayer(layer);
        console.log(layer.toGeoJSON())
        console.log("falta tratar criação... dizer que tem coisa para ser salva.")
        this.canDrawPolygon = false
        this.map.removeControl(drawControlGeral);
        this.map.addControl(drawControlCreated);
      });

      this.map.on('draw:edited', (e) => {
        const layers = e.layers;
        layers.eachLayer((layer) => {
          console.log(layer)
          console.log("falta tratar edição... dizer que tem coisa para ser salva.")

        
        });
      });
      this.map.on('draw:deleted', (e) => {
        const layers = e.layers;
        layers.eachLayer((layer) => {
          console.log("deletou")
          console.log("falta tratar deleção... dizer que tem coisa para ser removida no BD.")
          console.log(layer)
        })
        this.map.removeControl(drawControlCreated);
        this.map.addControl(drawControlGeral);
      });
      //const customButton = L.control({ position: 'topright' });
      const customButton = L.control({ position: 'topleft' });

      customButton.onAdd = () => {
        const div = L.DomUtil.create('div', 'custom-button');
        div.innerHTML = '<button onclick="alert(\'Tratar opção Salvar/persistir\')">Salvar</button>';
        return div;
      };

      customButton.addTo(this.map);
      this.mostrarMapa = false;
    },
   
    addGeoJSONToEditLayer() {
     
      var geojsonPolygon =
          [{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type":"Polygon","coordinates":[[[-48.627403,-27.219029],[-48.627318,-27.21869],[-48.627613,-27.218624],[-48.627918,-27.218576],[-48.628026,-27.218905],[-48.630847,-27.218237],[-48.630831,-27.218104],[-48.631679,-27.217894],[-48.63184,-27.218924],[-48.634458,-27.22441],[-48.636281,-27.230115],[-48.629973,-27.230706],[-48.626298,-27.219311],[-48.627403,-27.219029]]]}}]}];
      const layer = L.geoJSON(geojsonPolygon, {
        onEachFeature: (feature, layer) => {
          this.drawnItems.addLayer(layer);
          this.map.fitBounds(layer.getBounds()); 
          //layer.editing.enable(); 
        },
      });      
    },      

    savePolygon() {
      this.drawnItems.eachLayer((layer) => {
        const polygon = layer.toGeoJSON();
        polygon.properties = {
          info: this.polygonInfo,
        };
        console.log(polygon);
        
      });
      //console.log(polygon);
        console.log(this.polygonInfo);
//      this.polygonInfo = ''; 
    },
    alterarVisibilidadeMapa(visibilidade) {
      console.log(visibilidade)
      this.mostrarMapa = visibilidade;
    },
  },
};
</script>
<style>
/* Estilos para o botão customizado */
.custom-button {
  background-color: white;
  border: 2px solid gray;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
}
div#map {border: 2px solid #8080800d;}
/* Ajuste a posição vertical dos controles */
.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}
.watermark {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
}
.leaflet-control-attribution {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
}
.watermark-control {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
}
</style>
