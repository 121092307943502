export default {
    base() {
        //return process.env.API_URL ? process.env.API_URL : '/api'
        //return process.env.NODE_ENV == 'production' ? '/api' : process.env.API_URL ? process.env.API_URL : '/api'
        return process.env.NODE_ENV == 'production' ? '/api/' : process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '/api/'
    },
    rotas() {
        return {
            atendimento: {
                listar: this.base() + '/atendimento',
                criar: this.base() + '/atendimento',
                editar: this.base() + '/atendimento/',
                assunto: {
                    listar: this.base() + '/atendimento/assunto',
                },
                comentario: {
                    criar: this.base() + '/atendimento/comentario',
                }
            },

            demarcacao: {
                listar: this.base() + '/demarcacao/demarcacao',
                criar: this.base() + '/demarcacao/demarcacao',
                editar: this.base() + '/demarcacao/demarcacao/',
                tipo: {
                    listar: this.base() + '/demarcacao/tipo'
                },
                situacao: {
                    listar: this.base() + '/demarcacao/situacao'
                },
                trecho: {
                    listar: this.base() + '/demarcacao/trecho'
                },
            },

            autenticacao: {
                login: this.base() + '/user/login',
                recuperarSenha: '/password/email',
                trocarSenha: this.base() + '/user/trocar-senha',
                refresh: this.base() + '/user/refresh'
            },

            cargo: {
                listar: this.base() + '/cargo',
                criar: this.base() + '/cargo',
                editar: this.base() + '/cargo/'
            },

            geo: {
                camada: {
                    listar: this.base() + '/geo/camada',
                    criar: this.base() + '/geo/camada',
                    obter: this.base() + '/geo/camada/',
                    editar: this.base() + '/geo/camada/',
                    referencia: {
                        listar: this.base() + '/geo/camada/[idCamada]/referencia',
                        obter: this.base() + '/geo/camada/[tabelaReferenciaCamada]/referencia/[idReferenciado]',
                        criar: this.base() + '/geo/referencia',
                        editar: this.base() + '/geo/referencia/',
                    }
                }
            },

            orgao: {
                listar: this.base() + '/orgao',
                listarNaturezas: this.base() + '/natureza-orgao',
                criar: this.base() + '/orgao',
                editar: this.base() + '/orgao/'
            },

            tipoDocumento: {
                listar: this.base() + '/tipoDocumento'

            },
            situacaoDemanda: {
                listar: this.base() + '/situacaoDemanda'
            },

            procedimentoExterno: {
                listar: this.base() + '/procedimentoExterno',
                buscar: this.base() + '/procedimentoExterno/',
                criar: this.base() + '/procedimentoExterno',
                editar: this.base() + '/procedimentoExterno/'
            },

            tipoProcedimentoExterno: {
                listar: this.base() + '/tipoProcedimentoExterno'
            },

            poloProcedimentoExterno: {
                listar: this.base() + '/poloProcedimentoExterno'
            },

            /* ---- CONFIGURAÇÔES ---- */

            usuario: {
                listar: this.base() + '/usuario',
                criar: this.base() + '/usuario',
                editar: this.base() + '/usuario/',
            },

            permissao: {
                listar: this.base() + '/usuario',
                criar: this.base() + '/usuario',
                editar: this.base() + '/permissao/',
                permissao: this.base() + '/permissao',
                divisao: this.base() + '/divisaoOrganograma',
                usuario: this.base() + '/permissao/usuario'
            },

            perfil: {
                listar: this.base() + '/permissao',
                criar: this.base() + '/permissao',
                editar: this.base() + '/permissao/',
                //        permissao: this.base() + '/permissao',
                //        divisao: this.base() + '/divisaoOrganograma',
            },
            patrimonio: { 
                patimonio: this.base() + 'patrimonio',
                listar: this.base() + 'patrimonio/listar',
                divisao: this.base() + 'patrimonio/divisao',
                tabelas: this.base() + 'patrimonio/tabelas',
                gerarRelatorio: this.base() + 'patrimonio/gerarRelatorio',
                gerarTermo: this.base() + 'patrimonio/gerarTermo/',
                criar: this.base() + 'patrimonio/criar',
                editar: this.base() + 'patrimonio/editar/',
                criarLocal: this.base() + 'patrimonio/criarLocal',
                editarLocal: this.base() + 'patrimonio/editarLocal/',
            },
            correspondencia: {
                listar: this.base() + 'correspondencia/listar',
                destinatarios: this.base() + 'correspondencia/destinatarios/',
                setores: this.base() + 'correspondencia/setores',
                tipos: this.base() + 'correspondencia/tipos',
                tipoPostagem: this.base() + 'correspondencia/tipoPostagem',
                logradouros: this.base() + 'correspondencia/logradouros',
                pesquisaMunicipio: this.base() + 'correspondencia/pesquisaMunicipio',
                descartaCorresp: this.base() + 'correspondencia/descartaCorresp/',
                descartaDest: this.base() + 'correspondencia/descartaDest/',
                criarDestinatario: this.base() + 'correspondencia/criarDestinatario',
                criarCorrespondencia: this.base() + 'correspondencia/criarCorrespondencia',
                inserirLista: this.base() + 'correspondencia/inserirLista',
                postagem: this.base() + 'correspondencia/postagem',
                gerarLista: this.base() + 'correspondencia/gerarLista',
                gerarEtiqueta: this.base() + 'correspondencia/gerarEtiqueta/',
                concluirLista: this.base() + 'correspondencia/concluirLista',
                receberAR: this.base() + 'correspondencia/receberAR/',
                descartarDestLista: this.base() + 'correspondencia/descartarDestLista/',
            },
            organograma: {
                listar: this.base() + '/divisao-organograma',
                criar: this.base() + '/divisao-organograma',
                editar: this.base() + '/divisao-organograma/',
                unidadeGestora: this.base() + '/auth/divisao-organograma/unidade-gestora-usuario',
                unidadesGestoras: this.base() + '/auth/divisao-organograma/unidade-gestora'
            },

            arquivo: {
                listar: this.base() + '/arquivo',
                upload: this.base() + '/arquivo/upload'
            },

        }
    }
}