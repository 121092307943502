<template>
<div class="painelGeoTelaCheia2">
    <div class="map-container" style="margin-top: -30px;">
      <div class="info-container" style = "margin-right: 5px; padding-top: 0; width: 40%; height: 100%; padding-right: 0; padding-left: 0;" :style="{ display: selectedFeature ? 'block' : 'none' }">
        <div id='divFeatures' class="d-flex justify-end" 
            style="margin-bottom: -1px; background-color: rgb(249, 247, 247); 
            border: 1px solid rgb(213, 210, 210); border-radius: 5px 5px 0px 0px; width: 100%;">
          <b style="width: 95%;"><div style='color: rgb(3, 104, 164); font-size: 1.1vw;' v-html="denominacaoDestinacao"></div></b>
          <a style=" color: #2f83d6;" @click="closeFeatureInfo"><v-icon title="Fechar"  style=" color: #2f83d6; font-size: 1vw;">mdi-close</v-icon> </a>
          <a style=" color: #2f83d6;"><v-icon smal @click="clicouAbrirCadastro()" title="Abrir Cadastro" style=" color: #2f83d6; font-size: 0.9vw;">launch</v-icon></a>
        </div>
        <div class="feature-info" v-html="selectedFeatureInfo" style="padding: 3px; font-size: 0.7vw;width: 100%;height: 90.4%;"></div>
      </div>
      <div :class="{'telaCheiaVisual': !selectedFeature, 'telaCheiaVisualNao': selectedFeature }" > 
        <div :style="{ display: selectedFeature ? 'none' : 'block' }">
          <input v-model="termoPesquisa" class="termoDeBusca" placeholder="Informe o local de pesquisa" @keyup.enter="pesquisarVisualizador"> 
          <a @click="pesquisarVisualizador">
            <i style="font-size: 1vw; margin-left: -36px; color: rgb(25, 118, 210);" class="fa fa-search" aria-hidden="true"></i>
          </a>
          <a @click="pesquisarLimpar">
            <i style="font-size: 1vw; margin-left: -18px; color: rgb(25, 118, 210);" class="fa fa-times" aria-hidden="true"></i>
          </a>

        </div>
        <div id= "fotoCapa" :style="{ display: selectedFeature ? 'block' : 'none' }" style=" height: 35vh;  border-radius: 5px;  border: 1px solid rgb(204, 204, 204);padding-top: 5px;  margin-bottom: 5px;">
          <img  style='width: 100%; height: 17vw;' v-html="fotoCapa"></div>

        <div  style=" border-radius: 5px; border: 1px solid #ccc; padding: 5px; ">
          <div ref="map"  class="map leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom" 
          :class="{'selectedFeature': selectedFeature, 'selectedFeatureNao': !selectedFeature }"  style="width:100%; height: 100;"></div>
        </div>
        <div class="nota" ><p style="font-size:0.6vw;">
          <b>Nota:</b> a geolocalização disponibilizada não tem valor cartográfico ou topográfico compatível com rigores de medições de áreas de propriedades, sendo aqui utilizadas como fonte de planejamento para a gestão do Programa de Democratização dos Imóveis da União.</p></div>
      </div>
    </div>
    
  </div>
</template>
<script>

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import rotas from '../../rotas-servico'
import 'font-awesome/css/font-awesome.css';
export default {
  name: 'MapComponent',
  data() {
    return {
      map: null,
      termoPesquisa: '',
      resposta: null,
      layers: [],
      marcador:null,
      layerControls: {},
      selectedCategories: {},
      categories: {},
      selectedFeatureInfo: '',
      mostrarBusca: false,
      denominacaoDestinacao: '',
      fotoCapa: 'bbb',
      selectedFeature: false,
      clickedLayer:'',
      originalZoom: null,
      originalCenter: null,
      local: [],
      idImovel:null,
      markerLayer:null,
      markerLayerVisible: true,
    };
  },
  mounted() {
    this.initMap();
    this.carregarDados();
/*    
    var layersControlContainer = document.querySelector('.leaflet-control-layers');
      console.log(layersControlContainer)
      if (layersControlContainer) {
        console.log('entrei')
        //layersControlContainer.classList.remove('classe-personalizada-imovel')
        //layersControlContainer.classList.remove('leaflet-control-layers-expanded')
        //layersControlContainer.classList.remove('leaflet-control')
        layersControlContainer.classList.add('classe-personalizada-visual');
        console.log(layersControlContainer)
      }
*/
    var toggleMarkersButton = document.querySelector('#toggleMarkersButton');
    if (toggleMarkersButton){
      toggleMarkersButton.addEventListener('click', this.toggleMarkers);
    }
  },
  methods: {
    pesquisarVisualizador() {      
      let termoFormatado = this.termoPesquisa.replace(/ /g, '+');
      const verCEP = termoFormatado.replace(/[^0-9]/g, '')
      if ((termoFormatado.length < 11) && (verCEP.length == 8) ){
        var Httpreq = new XMLHttpRequest();
        const urlCEP = `https://viacep.com.br/ws/${verCEP}/json/`
        Httpreq.open("GET",urlCEP,true);
        Httpreq.onload = () => { 
          if (Httpreq.status === 200) {
            const respostaTexto = Httpreq.responseText;
            const objetoJSON = JSON.parse(respostaTexto);
            if (objetoJSON.erro){
              alert('O CEP informado não existe: '+ this.termoPesquisa)
            }
            else{
              const logradouro = objetoJSON.logradouro;
              const complemento = objetoJSON.complemento;
              const bairro = objetoJSON.bairro;
              const localidade = objetoJSON.localidade;
              const uf = objetoJSON.uf;
              const respostaCEP = (logradouro +' '+ ' '+ bairro +' '+ localidade +' '+ uf).replace(/ /g, '+');
              termoFormatado = respostaCEP
              this.pesquisaNominatim(termoFormatado)
            }
          } else {
            console.error('Ocorreu um erro na requisição.');
          }
        };          
        Httpreq.send();
      }
      else{
        this.pesquisaNominatim(termoFormatado)
      }
    },
    pesquisarLimpar(){
      if (this.marcador!=null) {
        this.termoPesquisa = '';
        this.map.removeLayer(this.marcador);
      }
    },
    async pesquisaNominatim(termoFormatado){
      if (this.marcador) {
        this.map.removeLayer(this.marcador);
      }
      const codigoPais = 'BR';
      const url = `https://nominatim.openstreetmap.org/search?format=json&q=${termoFormatado}&countrycodes=${codigoPais}`;
      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          if (data.length > 0) {
            const primeiroResultado = data[0];
            const latitude = parseFloat(primeiroResultado.lat);
            const longitude = parseFloat(primeiroResultado.lon);
            const novaCoordenada = L.latLng(latitude, longitude);
            this.marcador = L.marker(novaCoordenada).addTo(this.map);
            this.marcador.bindPopup("Atenção! Este ponto pode não representar o local pesquisado.").openPopup();
            this.marcador.setIcon(L.icon({ iconUrl: 'img/icon-mark-location-where.png', iconSize: [48, 48] }));
            this.marcador.dragging.enable();
            this.marcador.on('dragend', (e) => {
                const novaCoordenada = e.target.getLatLng();
            });
            this.map.setView(novaCoordenada, 17);
            this.coordenadaAtual = novaCoordenada;
          } else {
            alert('Nenhum resultado encontrado. Por favor, refine sua pesquisa.')
            console.log('Nenhum resultado encontrado 1')
            this.resposta = 'Nenhum resultado encontrado';
          }
        } else {
          
          console.log('Nenhum resultado encontrado 2')
          this.resposta = 'Erro ao fazer a solicitação';
        }
      } catch (erro) {
        console.error('Erro ao fazer a solicitação:', erro);
        console.log('Nenhum resultado encontrado 3')
        this.resposta = 'Erro ao fazer a solicitação';
      }  
    },
    clicouAbrirCadastro() {
      let rotafrontend = "/destinacoes/destinacoes/"+this.idImovel;
      this.$router.push(rotafrontend)
    },
    initMap() {
      
      this.map = L.map(this.$refs.map, {minZoom:4, maxZoom:20,}).setView([-14.746856493115015, -56.33391004209294], 4);

      let googleRua = L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',  { subdomains: ['mt0', 'mt1', 'mt2', 'mt3'], attribution: 'Google Ruas', maxZoom: 20 })

      L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          }).addTo(this.map);
      
      const overlays = {};
      const baseLayers = {
        '<img src="img/mapa_osm3.jpg" style="border-radius: 4px; margin-left: 3px; margin-top: 1px;" width="36" height="36" alt="Google Ruas" title="Ruas" alt="Google Rua">': googleRua.addTo(this.map),
        '<img src="img/mapa_esri3.jpg"  style="border-radius: 4px;"  width="36" height="36" title="Satélite" alt="Google">': L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {attribution:'Google Satélite', maxZoom: 20, subdomains: ['mt0', 'mt1', 'mt2', 'mt3']}),
        '<img src="img/mapa_hibrido.jpg"  style="border-radius: 4px;"  width="36" height="36" title="Híbrido" alt="Google">': L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {attribution:'Google Híbrido', maxZoom: 20, subdomains: ['mt0', 'mt1', 'mt2', 'mt3']}),
      };
      const layerControl = L.control.layers(baseLayers, overlays, {
        position: 'bottomright',
        collapsed: false
      }).addTo(this.map);

 
      var resetZoomControl = L.Control.extend({
        options: {
          position: 'topleft'
        },
        onAdd: function () {
          var container = L.DomUtil.create('div', 'leaflet-control-resetview leaflet-bar leaflet-control');
          container.innerHTML = 
	          '<a id="resetZoomButton" class="leaflet-bar-part leaflet-bar-part-single" :class="leaflet-disabled" title="Zoom original" href="#" role="button">'+
            '<i  style="font-size: 1rem;  vertical-align: middle;  height: 1.4rem; "class="fa fa-refresh" aria-hidden="true"></i></a>';
          return container;
        }
      });
      var self = this; // Armazena a referência a 'this' em uma variável
      this.map.addControl(new resetZoomControl());
      document.getElementById('resetZoomButton').addEventListener('click', function () {
        self.map.setView([-14.746856493115015, -56.33391004209294], 4);
      });

      

      var googleLogoControl = L.control({ position: 'bottomleft' });
      googleLogoControl.onAdd = function (map) {
        var div = L.DomUtil.create('div', 'google-logo-control');
        div.innerHTML = '<img src="img/logo_google_white.png" alt="Google Logo" style="width: 4rem; margin-bottom: -10px; margin-left: 10px;">';
        return div;
      };
      googleLogoControl.addTo(this.map);
    },

    pesquisaInput(){
      const textInput = document.createElement('input');
      textInput.type = 'text';
      textInput.placeholder = 'Digite um CEP ou endereço para reposicionar o mapa';
      }, 
    carregarDados(){
      this.pesquisaInput()
      let apUrl = process.env.NODE_ENV == 'local'? process.env.VUE_APP_API_URL.replace('api/',''):process.env.VUE_APP_API_URL;
      this.$http
        .get(rotas.destinacoes.consulta_geral.url())
        .then(response => {
          let geojson = []
          this.markerLayer = L.layerGroup();
          
          response.data.forEach(element => {
            let propr_presumida_id = ''
            let categoria_id = ''
            let natureza_id = ''
            let cond_urbanizacao_id = ''
            let tipo_interessado_id = ''
            let util_especifica_id = ''
            
            let capa = 0;
            
            if (element.foto_capa !=null){
              capa = element.foto_capa
            }
            if (element.fotos.length==0){
              element.fotos[0] = 'blank.jpg'
            }

            this.fotoCapa = element.fotos[capa];
            if (element.propr_presumida_id){
              propr_presumida_id = element.propr_presumida.nome
            }

            if (element.categoria_id){
              categoria_id = element.categoria.nome
            }

            if (element.natureza_id){
              natureza_id = element.natureza.nome
            }

            if (element.tipo_interessado_id){
              tipo_interessado_id = element.tipo_interessado.nome+""
            }

            if (element.util_especifica_id){
              util_especifica_id = element.util_especifica.nome
            }

            if (element.cond_urbanizacao_id){
              cond_urbanizacao_id = element.cond_urbanizacao.nome
            }
            var dt = new Date(element.created_at);
            let dtForm = dt.toLocaleString("pt-BR");
            let tj = ["TJAC 01","TJAL 02","TJAP 03","TJAM 04","TJBA 05","TJCE 06","TJDF 07","TJES 08","TJGO 09","TJMA 10","TJMT 11","TJMS 12","TJMG 13","TJPA 14","TJPB 15","TJPR 16","TJPE 17","TJPI 18","TJRJ 19","TJRN 20","TJRS 21","TJRO 22","TJRR 23","TJSC 24","TJSE 25","TJSP 26","TJTO 27"]
            let procIncorp = (element.no_processo_incorporacao+'').replace('null','')
            let procDest = (element.no_processo_destinacao+" ").replace('null','')
            if (element.no_processo_incorporacao && element.no_processo_incorporacao.length == 17){
              const parte1 = element.no_processo_incorporacao.slice(0, 5);
              const parte2 = element.no_processo_incorporacao.slice(5, 11);
              const parte3 = element.no_processo_incorporacao.slice(11, 15);
              const parte4 = element.no_processo_incorporacao.slice(15, 17);
              procIncorp = parte1+'.'+parte2+'/'+parte3+'-'+parte4
            }

            if (element.no_processo_destinacao && element.no_processo_destinacao.length == 17){
              const parte1 = element.no_processo_destinacao.slice(0, 5);
              const parte2 = element.no_processo_destinacao.slice(5, 11);
              const parte3 = element.no_processo_destinacao.slice(11, 15);
              const parte4 = element.no_processo_destinacao.slice(15, 17);
              procDest = parte1+'.'+parte2+'/'+parte3+'-'+parte4
            }

            let endereco = "";
            let descEnd = element.descricao_logradouro ? " "+element.descricao_logradouro :"";
            let no_logradouro = element.no_logradouro ? ", "+element.no_logradouro : "";
            let complLog = element.complemento_logradouro ? " - "+ element.complemento_logradouro : "";
            let bairro = element.bairro ? '<br>'+element.bairro:'';
            if (element.cep){
              element.cep=("00000000"+element.cep).slice(-8)
            }
            let cep =element.cep ? "<br>CEP: "+element.cep.slice(0, 5)+"-"+element.cep.slice(5, 8):'';

            if (element.tipo_logradouro){
              endereco =  element.tipo_logradouro.nome + descEnd + no_logradouro + complLog + bairro + cep;
            }
            let no_judic = " ("+element.acao_judicial.nome+")"
            if (!element.no_processo_judicial){element.no_processo_judicial = ''}
            if (element.acao_judicial_id != 5 && element.acao_judicial_id != 6){
              no_judic = element.no_processo_judicial + no_judic
            }
            else{
              no_judic = element.acao_judicial.nome
            }
            if (element.acao_judicial_id == 4 ){
              let numero = element.no_processo_judicial.replace(/[^0-9]/g, '');

              element.no_processo_judicial = 
                numero.slice(0, 7) + '-'+ numero.slice(7, 9) + '.'+ numero.slice(9, 13)+ '.'+ 
                numero.slice(13, 14) + '.'+ numero.slice(14, 16) + '.'+ numero.slice(16, 20);

              let tr = numero.substring(13, 14);
              let uf = numero.substring(14, 16);  
              if (tr == 1){
                no_judic = element.no_processo_judicial + " (STF)"
              }
              else
              if (tr == 2){
                no_judic = element.no_processo_judicial + " (CNJ)"
              }
              else
              if (tr == 3){
                no_judic = element.no_processo_judicial + " (STJ)"
              }
              else
              if (tr == 4){
                no_judic = element.no_processo_judicial + " (TRF/"+uf+")"
              }
              else

              if (tr == 5){
                if (uf == '00'){
                  no_judic = element.no_processo_judicial + " (TST)"
                }
                else{
                  no_judic = element.no_processo_judicial + " (TRT/"+uf+")"
                }
              }
              else
              if (tr == 6){
                if (uf == '00'){
                  no_judic = element.no_processo_judicial + " (TSE)"
                }
                else{
                  no_judic = element.no_processo_judicial + " (TRE/"+tj[uf-1].substring(2,4)+")"
                }
              }
              else
              if (tr == 7){
                if (uf == '00'){
                  no_judic = element.no_processo_judicial + " (STM)"
                }
                else{
                  no_judic = element.no_processo_judicial + " (CJM/"+uf+")"
                }
              }
              else
              if (tr == 8){
                no_judic = element.no_processo_judicial + " (TJ/"+tj[uf-1].substring(2,4)+")"
              }
              else
              if (tr == 9){
                no_judic = element.no_processo_judicial + " (JM/"+tj[uf-1].substring(2,4)+")"
              }
            }
            let numeroFormatado = element.area_terreno.replace(/[^0-9.]/g, '');
            const [parteInteira, parteDecimal] = numeroFormatado.split('.');
            let parteInteiraFormatada = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            let area_terreno = parteInteiraFormatada +','+ parteDecimal;

            numeroFormatado = (element.area_benfeitoria+" ").replace(/[^0-9.]/g, '');
            let area_benfeitoria = 'Não informada'
            if(numeroFormatado.length > 0){
              const [parteInteiraB, parteDecimalB] = numeroFormatado.split('.');
              parteInteiraFormatada = parteInteiraB.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
              area_benfeitoria = parteInteiraFormatada +','+ parteDecimalB;
            }

            numeroFormatado = element.gasto_anual_imovel.replace(/[^0-9.]/g, '');
            const [parteInteiraG, parteDecimalG] = numeroFormatado.split('.');
            parteInteiraFormatada = parteInteiraG.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            let gasto_anual_imovel = parteInteiraFormatada +','+ parteDecimalG;
            
            numeroFormatado = element.valor_estimado.replace(/[^0-9.]/g, '');
            const [parteInteiraT, parteDecimalT] = numeroFormatado.split('.');
            parteInteiraFormatada = parteInteiraT.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            let valor_estimado = parteInteiraFormatada +','+ parteDecimalT;

            let coords = this.ajustarCoordenadas (element.coordenadas)
            let feature = {
              type: "Feature",
              geometry: {
                type: 'Polygon',
                coordinates: this.ajustarCoordenadas (element.coordenadas)
              },
              properties: {
                "Município / UF":element.municipio.cidade+" / "+element.municipio.estado.descricao,
                "Endereço": endereco,
                "Categoria": categoria_id,
                "Área estimada do terreno (m²)": area_terreno,
                "Área estimada da benfeitoria (m²)": area_benfeitoria ,
                "Valor estimado (R$)": valor_estimado,
                "Gasto Manutenção (R$)": gasto_anual_imovel,
                "Propriedade": propr_presumida_id,
                "Grupo do imóvel": element.grupo_imovel.nome,
                "Situação da incorporação": (element.sit_incorporacao.nome+" ").replace('null',''),
                "Processo de incorporação": procIncorp,
                "Ação Judicial / Controle": no_judic,
                "Situação da ocupação": element.sit_ocupacao.nome,
                "Linha do Programa": element.linha_programa.nome,
                "Utilização Específica": element.util_especifica.nome,
                "Processo de destinação":procDest,
                "Interessado": (element.nome_interessado+" ").replace('null',''),
                "Instrumento de destinação": (element.instrumento_destinacao.nome+" ").replace('null',''),
                "Conflito": element.conflito.nome,
                "Observações":(element.obs+" ").replace('null','')
              },
              dados:{
                "foto": element.fotos[capa],
                id: element.id,
                'denominacaoDestinacao': element.denominacao,
                "UF": element.municipio.estado.descricao,
              }
            };
            geojson = feature;
            this.loadGeoJSONData(geojson);
          });
        })
        .catch(error => {
          console.error("Erro na requisição:", error);
        });
      },   
  loadGeoJSONData(geojson) {
    const category = 'Brasil';
    const marcadores = "Marcadores"
    const subcategory = geojson.dados.UF;

    if (!this.layerControls[category]) {
      this.layerControls[category] = L.control.layers(null, null, {
        position: 'topright',
      });
      const controlHeader = L.DomUtil.create('div', 'control-header');
      controlHeader.innerHTML = `<strong>${category}</strong>`;
      controlHeader.addEventListener('click', () => this.toggleSelectAll(category));
      this.layerControls[category].addTo(this.map);
      this.layerControls[category]._container.insertBefore(controlHeader, this.layerControls[category]._container.firstChild);
    }

    let existingLayer = false;
    for (const key in this.layerControls[category]._layers) {
        const layer = this.layerControls[category]._layers[key];
        if (layer.name == subcategory){
          existingLayer = layer;
        }
    }
   
    if (existingLayer) {
      const layerToAdd = L.geoJSON(geojson, {
        onEachFeature: (feature, layer) => {
          layer.on('click', (event) => this.showFeatureInfo(event, feature));
          layer.on('map-container-resize', function () {
            var map = this.map;
            setTimeout(function(){map.invalidateSize()}, 10)
          });
        },
      });
      existingLayer.layer.addLayer(layerToAdd);
    } else {
      const layer = L.geoJSON(geojson, {
        onEachFeature: (feature, layer) => {
          layer.on('click', (event) => this.showFeatureInfo(event, feature));
          layer.on('map-container-resize', function () {
            var map = this.map;
            setTimeout(function(){map.invalidateSize()}, 10)
          });

        },
      });

      this.layerControls[category].addOverlay(layer, subcategory);
    }

    const overlayInputs = this.layerControls[category]._overlaysList.querySelectorAll('input[type="checkbox"]');
    
    overlayInputs.forEach(input => {
      input.checked = true;
      this.layerControls[category]._onInputClick();
    });
    this.selectedCategories[category] = true;

    if (typeof this.layerControls[marcadores] === 'undefined') {
      this.layerControls[marcadores] = L.control.layers().addTo(this.map);
      var toggleButton = L.control({ position: 'bottomright' });
      toggleButton.onAdd = function (map) {
        var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom div-custom-button');
        container.innerHTML = '<button id="toggleMarkersButton" class="custom-button"><i class="fa fa-map-marker fa-2x custom-icon"></i><span style="font-size: 12px; width: 108px; margin-left: -1px;">Destacar polígonos</span></button>';
        return container;
      };
      toggleButton.addTo(this.map);
      var toggleMarkersButton = document.getElementById('toggleMarkersButton');
      toggleMarkersButton.addEventListener('click', this.toggleMarkers);
    }
    if (geojson.geometry.coordinates[0].length > 0){
      let lng = geojson.geometry.coordinates[0][0][0]
      let lat = geojson.geometry.coordinates[0][0][1]
      let latLng = [lat, lng]
      const marker = L.marker([lat, lng]).addTo(this.markerLayer);
      marker.setIcon(L.icon({ iconUrl: 'img/icon-mark-location-red.png', iconSize: [16, 18] }));
      this.markerLayer.addTo(this.map);
    }
  },      

    ajustarCoordenadas (coords) {
     
      let result = coords.replace("GEOMETRYCOLLECTION (POLYGON ((", "");
      result = result.replace(")))", "");
      result = result.replaceAll(', ',',');
      result = result.split(",");
      let result3 = [];
      let result2 = [];
      result.forEach(function(item) {
        result2 = item.split(" ")
        if (result2.length == 2){
          result2[0]= Number(result2[0])
          result2[1]= Number(result2[1])
          result3.push(result2)
        }
      });
      let result4 = [];
      result4.push(result3)
      return (result4)

    },
    showFeatureInfo(event, feature) {
      this.selectedFeature = true;
      if (this.originalZoom==null){this.originalZoom = this.map.getZoom()};
      if (this.originalCenter==null){this.originalCenter = this.map.getCenter()};
      //this.originalZoom = this.map.getZoom()
      //this.originalCenter = this.map.getCenter()
      var map = this.map;
      setTimeout(function(){ map.invalidateSize()}, 10)

      let apuUrl = process.env.VUE_APP_API_URL.replace('api/','')
      this.idImovel = feature.dados.id;
      let featureInfo = ''
      this.denominacaoDestinacao = feature.dados.denominacaoDestinacao
      if (feature.dados.foto) {
            const imageElement = document.createElement('img');
            imageElement.src = apuUrl + 'destinacoes/'+feature.dados.foto;
            imageElement.style = 'height: 32vh;max-width: 100%; '; 
            document.getElementById("fotoCapa").innerHTML = `<div style=' height: 32vh; max-width: 96%;; margin: auto; text-align: center; background-color: white;'>${imageElement.outerHTML}</div><br>`;
          }
      featureInfo += "<table style='font-family: verdana;'>"
      featureInfo += Object.entries(feature.properties)
        .map(([key, value]) => `<tr style= "height: 0.7vh"><td style= "height: 0.7vh"><h4 style='width: 15vw;  display: inline-block;  text-align: right; font-size:0.7vw;'>${key}:</h4></td><td><h4 style='font-weight: normal; font-size:0.7vw; padding-left: 15px; display: inline-block;'> ${value}</h4></td></tr>`).join('');
      featureInfo += '</table>'
      
      this.clickedLayer = event.target;
      var bound =    this.clickedLayer

      this.selectedFeatureInfo = featureInfo;
       
      map = this.map;
      setTimeout(function(){map.fitBounds(bound.getBounds())}, 10); 
      setTimeout(function(){ map.invalidateSize()}, 10)
      setTimeout(function(){map.fitBounds(bound.getBounds())}, 10); 

      

    },

    adjustMapZoomAndCenter(layer) {
      const bound = layer.getBounds();
      var map = this.map;
      setTimeout(function(){map.fitBounds(bound.getBounds())}, 10);
      setTimeout(function(){map.invalidateSize()}, 10)
      setTimeout(function(){map.fitBounds(bound.getBounds())}, 10);
    },
    closeFeatureInfo(event) {
      if (this.originalZoom != null && this.originalCenter != null) {
        var map = this.map;
        setTimeout(function(){map.invalidateSize()}, 10)
        this.map.setView(this.originalCenter, this.originalZoom);
      }
      var map = this.map;
      setTimeout(function(){map.invalidateSize()}, 10)
      this.originalZoom = null;
      this.originalCenter = null;
      this.selectedFeatureInfo = '';
      this.selectedFeature = false;
      this.idImovel = null;
      
    },
    toggleMarkers() {
      if (this.markerLayerVisible) {
        this.map.removeLayer(this.markerLayer);
      } else {
        this.map.addLayer(this.markerLayer);
      }
      this.markerLayerVisible = !this.markerLayerVisible;
    },
    toggleSelectAll(category) {
      this.selectedCategories[category] = !this.selectedCategories[category];
      const overlayInputs = this.layerControls[category]._overlaysList.querySelectorAll('input[type="checkbox"]');
      overlayInputs.forEach(input => {
        input.checked = this.selectedCategories[category];
        this.layerControls[category]._onInputClick();
      });
    },
 },
}
</script>
<style>

div.selectedFeature {height: 380px;}
div.selectedFeatureNao {height: 700px;}
div.selectedFeature > div.leaflet-control-container > div.leaflet-top { display: none}
div.selectedFeature > div.leaflet-control-container > div.leaflet-bottom > div.leaflet-control-layers { display: none !important}
div.selectedFeature > div.leaflet-control-container > div.leaflet-bottom > div.div-custom-button { display: none}
div.telaCheiaVisual{width: 100%;}
div.telaCheiaVisualNao{width: 50%;}

.watermark {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
}
.leaflet-control-attribution {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  
}
.leaflet-attribution-flag{
  display: none !important;
}
.watermark-control {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
}
.control-header {
  background-color: #f4f4f4;
  padding: 5px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  margin-top: 5px;
  cursor: pointer;
}

.control-header:hover {
  background-color: #ddd;
}

.control-header:active {
  background-color: #bbb;
}

.control-header:active .invert-selection {
  text-decoration: underline;
}

.control-option {
  cursor: pointer;
  margin-top: 5px;
  color: blue;
  text-decoration: underline;
}

.invert-selection {
  color: black;
  text-decoration: none;
}
.leaflet-control-layers-toggle {
  display: none !important;
}
.map-container {
  display: flex;
}

.map {
  flex: 3;
  width: 75%;
}

.info-container {
  flex: 1;
  width: 25%;
  padding: 20px;
  box-sizing: border-box;
}

.feature-info {
  padding: 10px;
  background-color: white !important;
  border-radius: 0px 0px 5px 5px;
  border: 2px solid #d7d0d0;
}
.info-container {
  display: none;
}
.leaflet-control-layers-expanded {
  margin-right: 0 !important;
}
.info-container.active {
  display: block;
}
.custom-button {
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 0 !important;
  border: 0 !important;
  margin-right: 0 !important;
  display: flex;
  align-items: center; 
  height: 22px;
  margin-left: -2px;
  padding: 0px !important;
  margin-top: -3px;  
}
.div-custom-button {
  margin-right: 0 !important;
  padding: 0;
  width: 136px;
  height: 28px;
}
.custom-icon {
  color: red;
  font-size: 20px; 
  margin-right: 5px; 
}
.leaflet-control-layers-base > label > span > .leaflet-control-layers-selector {
  display: none;
}


    .leaflet-control-custom {
      background-color: white;
      border: 2px solid #ccc;
      padding: 5px;
      border-radius: 5px;
    }
  
.leaflet-control-layers-label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px; 
}

.leaflet-control-custom {
      background-color: white;
      border: 2px solid #ccc;
      padding: 5px;
      border-radius: 5px;
    }

    
    .layer-menu {
      display: none;
      position: absolute;
      background-color: white;
      border: 1px solid #ccc;
      padding: 5px;
      border-radius: 5px;
    }

  
    .layer-item {
    display: inline-block ;
    margin-right: 10px; 
  }


.layer-button img {
  width: 36px;
  height: 36px;
}
  .layer-button {
  
  background-color: #007bff; 
  color: #fff; 
  border: none;
  
  cursor: pointer; 
  
}
.leaflet-control-layers-base > label {
  width: 40px;
  display: inline;
  margin-left: 1px;
}
div.leaflet-right{
  margin-right: 5px;
}
div.control-header{
  margin-top: 0;
}
div.leaflet-bottom > .leaflet-control-layers{
  background-color: rgb(238, 246, 248);
  width: 136px !important;
  height: 46px;
  padding-top: 2px !important;
  padding-bottom: 0 !important;
  padding-left: 1px !important;
  padding-right: 3px !important;
}
.classe-personalizada-visual {
  background-color: rgb(238, 246, 248);
  width: 136px !important;
  height: 46px;
  padding-top: 2px !important;
  padding-bottom: 0 !important;
  padding-left: 1px !important;
  padding-right: 3px !important;
}
label > span > span {
  width: 40px;
  display: inline;
  margin-left: 1px;
  margin-top: 1px;
}
.custom-control > button{
  margin-left: -15px;
  color: gray;
}

.custom-control > input{
  border-radius: 6px;
  width: 350px;
  height: 35px;
  background-color: #fbfbfb;
  border-style: solid;
}
.termoDeBusca{
  min-width: 50%;
  max-width: 700px;
  border: solid 2px #d5d2d2;
  border-radius: 4px;
  margin-bottom: 5px;
  padding-left: 5px;
  background-color: #f9f7f7;
}
.map-container {
  position: relative;
  z-index: 1; 
}
#map {
  width: 100%;
  height: 100%; 
  z-index: 1; 
}
ul.v-breadcrumbs {
  padding-top: 5px;
  padding-bottom: 0px;
}
div.painelGeoTelaCheia {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 10px;
  width: 99%;
  height: 100%;
  background-color: #cecece;
}

</style>