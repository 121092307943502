<template>
  <div>
    <div v-if="mensagem">
      {{ mensagem }}
    </div>
  </div>
</template>

<script>
import jwt from 'jsonwebtoken'
import EventBus from './../../event-bus'
export default {
  data() {
    return {
      // state: null,
      accessToken: null,
      mensagem: null,
    };
  },

  methods: {
    inicializar() {
      this.mensagem = 'Aguarde... '
      /*
      this.state = this.$route.params.state
        ? this.$route.params.state
        : null;
      */
      this.accessToken = this.$route.params.access_token
        ? this.$route.params.access_token
        : null;
      
      let decoded = jwt.decode(this.accessToken)
      EventBus.$emit('App::onLogin', { 
        access_token: this.accessToken,
        expires_in: new Date(decoded.exp * 1000)
      })

    },

    /*
    solicitarAutorizacao() {
      let state = cryptoRandomString({ length: 40 });
      this.$store.commit("auth/setState", state);

      let query = queryString.stringify({
        response_type: "code",
        client_id: this.clientId,
        scope: this.scopes,
        redirect_uri: this.urlCallback,
        nonce: cryptoRandomString({ length: 40 }),
        state: state,
      });

      window.location = this.urlAutorizacao + "?" + query;
    },
    */

    /*
    solicitarAccessToken() {
      this.mensagem = "Aguardando autenticação do usuário...";

      // verificar o state com o state salvo no store
      if (this.state != this.$route.params.state) {
        this.mensagem = "Parâmetro state é inválido";
        return;
      }

      /*
      let formData = new FormData();
      formData.append("grant_type", "authorization_code");
      formData.append("redirect_uri", this.urlCallback);
      formData.append("code", this.codigoAutorizacao);
      formData.append("state", this.state);
      // */
      /*
      let formData = {
        grant_type: "authorization_code",
        redirect_uri: this.urlCallback,
        code: this.codigoAutorizacao,
        state: this.state,
      }
      // */

      /*
      let config = {
        headers: {
          //'Content-Type': "application/x-www-form-urlencoded",
          Authorization:
            "Basic " + btoa(this.clientId + ":" + this.clientSecret),
        },
        emulateJSON: true,
      }

      this.$http
        .post(this.urlObtencaoToken, formData, config)
        .then(
          (response) => {
            console.log("sucesso", response);
            this.mensagem = response;
          },
          (error) => {
            console.log("erro", error);
            this.mensagem = error;
          }
        )
        .finally(() => {
          this.mensagem = this.mensagem + "<br>acabou";
        });

      /*
            $response = Http::withHeaders([
            'Authorization' => 'Basic ' . base64_encode(env('OAUTH2_GOVBR_CLIENT_ID') . ":" . env('OAUTH2_GOVBR_CLIENT_SECRET'))
        ])
            //'Content-Type' => 'application/x-www-form-urlencoded',
            ->asForm()
            ->post('https://sso.staging.acesso.gov.br/token', [
                'grant_type' => 'authorization_code',
                'redirect_uri' => env('OAUTH2_GOVBR_REDIRECT_URL'),
                'code' => $request->code,
                'state' => $state,
            ]);
      this.query = "[ta dando certo]";
    },
    */
  },

  mounted() {
    this.inicializar();
  },
};
</script>

<style>
</style>