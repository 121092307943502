<template>
  <card-grafico
    titulo="Entrada x Saída Diária (últimos 30 dias)"
    :carregando="!carregouDemandasEntradaSaidaDiaria"
  >
    <grafico-barra
      :chartdata="demandasEntradaSaidaDiaria"
      :options="opcoesRelatorioEntradaSaidaDiaria"
      style="height: 200px; position: 'relative'"
    />
  </card-grafico>
</template>

<script>
import CardGrafico from "./../../../../components/relatorios/CardGrafico.vue";
import GraficoBarra from "./../../../../components/relatorios/GraficoBarra.vue";
import rotas from "./../../../../rotas-servico";
export default {
  components: {
    CardGrafico,
    GraficoBarra,
  },
  props: {
    unidadeGestora: {
      type: Object,
    },
  },
  data() {
    return {
      carregouDemandasEntradaSaidaDiaria: false,
      demandasEntradaSaidaDiaria: null,
      opcoesRelatorioEntradaSaidaDiaria: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
        },
      },
    };
  },

  methods: {
    carregarRelatorio(dateDe, dateAte) {
      //this.dataDe = Utils.toIsoDate(dateDe);
      //this.dataAte = Utils.toIsoDate(dateAte);
      this.carregarDemandasEntradaSaidaDiaria();
    },
    async carregarDemandasEntradaSaidaDiaria() {
      this.carregouDemandasEntradaSaidaDiaria = false;
      this.demandasEntradaSaidaDiaria = {
        labels: [],
        datasets: [
          {
            label: "Criadas",
            backgroundColor: "red",
            data: [],
          },
          {
            label: "Respondidas",
            backgroundColor: "green",
            data: [],
          },
        ],
      };
      let url = rotas.demandas.relatorio.entradaSaidaDiaria.url();
      if (this.unidadeGestora != null && this.unidadeGestora.id != null) {
        url = url + "/" + this.unidadeGestora.id;
      }
      return this.$http
        .get(url)
        .then((res) => {
          // console.log(res.data)
          res.data.forEach((registroDiario) => {
            /*
            if (
              registroDiario.criadas +
              registroDiario.respondidas +
              registroDiario.canceladas
            ) {
              */
            var dia = new Date(registroDiario.dia + " 00:00:01");
            this.demandasEntradaSaidaDiaria.labels.push(
              dia.getDate() + "/" + (dia.getMonth() + 1)
            );
            this.demandasEntradaSaidaDiaria.datasets[0].data.push(
              registroDiario.criadas
            );
            this.demandasEntradaSaidaDiaria.datasets[1].data.push(
              registroDiario.respondidas
            );
            //}
          });
          this.carregouDemandasEntradaSaidaDiaria = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  watch: {
    unidadeGestora(val) {
      this.carregarDemandasEntradaSaidaDiaria();
    },
  },
};
</script>

<style>
</style>