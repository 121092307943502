import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueResource from 'vue-resource'
import $ from 'jquery'
import VueUploadComponent from 'vue-upload-component';

Vue.component('file-upload', VueUploadComponent);


Vue.config.productionTip = false
Vue.use(VueResource)

const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.http.interceptors.push((request) => {
    // console.log(request)
    let token = store.getters['auth/token']
    request.headers.set('Accept', 'application/json')
    request.headers.set('Authorization', 'Bearer ' + token)

    request.headers.set('X-CSRF-TOKEN', $('meta[name="csrf-token"]').attr('content'));

    return function (response) {
        if (response.headers.get('authorization') != null) {
            let newToken = response.headers.get('authorization');
            newToken = newToken.substring("Bearer ".length)
            // console.log('Atualizando o token para: ' + newToken) // 'Bearer '
            store.commit('auth/setToken', newToken)
        }

        switch (response.status) {
            case 401:
                if (response.body.message.includes('trocar a senha')) {
                    if (router.currentRoute.name !== 'trocar-senha') {
                        router.push('trocar-senha')
                    }
                    this.$store.commit('sistema/aviso', 'É necessário trocar sua senha')
                    break;
                }
                if (response.body.message.includes('trocar o email')) {
                    if (router.currentRoute.name !== 'trocar-email') {
                        router.push('trocar-email')
                    }
                    this.$store.commit('sistema/aviso', 'É necessário trocar seu e-mail')
                    break;
                }

                store.commit('auth/sair', null)
                break;

            case 501:
                this.$store.commit('sistema/alerta', 'O serviço da aplicação está indisponível')
                break;

            case 503:
                this.$store.commit('sistema/alerta', 'Ocorreu um erro interno no serviço da aplicação')
                break;

            default:
                break;
        }

        return response;
    }
})


window.onload = () => {
    new Vue({
        router,
        store,
        vuetify,
        /*
        components: {
            'spu-menu': require('./components/layout/Menu')
        },
        */
        render: h => h(App)
    }).$mount('#siga')
}
