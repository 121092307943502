import Atendimento from './components/Atendimento'
import IniciarAtendimento from './components/IniciarAtendimento'
export default {
    rotas : {

        frontend : [
            {
                path: '/atendimentos/atendimento',
                name: 'Atendimento',
                component: Atendimento,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [{
                            text: 'Início',
                            href: '/#/'
                        },
                        {
                            text: 'Atendimento'
                        },
                    ]
                }
            },
            {
                path: '/atendimentos/atendimento/:id',
                name: 'AtendimentoEditor',
                component: Atendimento,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [{
                        text: 'Início',
                        href: '/#/'
                    },
                    {
                        text: 'Atendimento'
                    },
                ]
                }
            },
            {
                path: '/atendimentos/atender',
                name: 'Atender',
                component: IniciarAtendimento,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [{
                            text: 'Início',
                            href: '/#/'
                        },
                        {
                            text: 'Atendimento'
                        },
                        {
                            text: 'Atender'
                        },
                    ]
                }
            }

        ]

    }
}