<template>
  <v-container fill-height fluid>
    <v-layout row wrap>
      <v-flex xs12 fill-height v-if="exibirGrid" transition="slide-x-reverse-transition">
        <v-card :color="color">
          <v-card-title style="width: 99%;">
            <v-toolbar-title>{{ nomeEntidadePlural }}</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Buscar"
              single-line
              :error-messages="searchMessage()"
              @keyup.enter="doSearch()"
              @click:append="doSearch()"
              @focus="searchFocused = true"
              @blur="searchFocused = false"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-flex>
              <slot name="beforeAdd"></slot>
            </v-flex>
            <v-spacer></v-spacer>
            <slot v-if="exibirBotaoCadastrar" name="addButtonArea">
              <v-btn color="primary" dark class="mb-2" @click="cadastrarNovo">
                <v-icon>add</v-icon>
                {{ verboAdicionarEntidade }} {{ nomeEntidade }}
              </v-btn>
            </slot>
            <v-container>
              <v-layout row wrap></v-layout>
            </v-container>
          </v-card-title>

          <slot name="beforeTable"></slot>

          <v-data-table
            :headers="headers"
            :items="computedItems"
            v-model="selected"
            :options="options"
            show-select
            :item-key="itemKey"
            :loading="carregando"
            loading-text="Carregando registros..."
            :hide-default-header="true"
            :hide-default-footer="true"
          >
            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>

            <template slot="body.prepend">
              <tr style="border-bottom: thin solid rgba(0, 0, 0, 0.12)">
                <th v-if="imprimir">
                  <v-checkbox v-model="selectAll" primary hide-details @click="select()"></v-checkbox>
                </th>
                <th
                  v-for="(header, index) in headers"
                  :key="index"
                  :style="header.text == 'id' ? '' : estiloDaColuna(header)"
                  role="columnheader"
                  @click="toggleSort(index)"
                  class="text-start sortable"
                  :class="header.class == 'text-end destinacao-top' ? 'text-end ' : ''"
                >
                  <h4 v-if="header.type == 'filter'">
                    <v-layout align-center row fill-height>
                      <v-autocomplete
                        label
                        tabindex="2"
                        clearable
                        v-model="columnFilters[index]"
                        :items="columnFilterItems[index]"
                      ></v-autocomplete>
                    </v-layout>
                  </h4>
                  <span else>{{ headers[index].text }}</span>
                  <v-icon
                    v-if="
                      headers[index].sortable &&
                      headers[index].value == options.order
                    "
                    small
                  >
                    {{
                    options.ascending ? "arrow_upward" : "arrow_downward"
                    }}
                  </v-icon>
                </th>
              </tr>
            </template>

            <template slot="item" slot-scope="props">
              <tr>
                <td v-if="imprimir">
                  <v-checkbox v-model="selected" :value="props.item" primary hide-details @change='updateCheckall()'></v-checkbox>
                </td>
                <td
                  v-for="(header, index2) in headers"
                  :key="index2"
                  :style="estiloDaColuna(header)"
                  v-on:click="clicouItem(props.item)"
                  :class="header.type == 'hidden' ? 'hidden' : '' + header.class"
                  
                >
                  <!-- hidden -->
                  <div v-if="header.type == 'hidden'"></div>

                  <!-- checkbox -->
                  <div v-else-if="header.type == 'checkbox'">
                    <v-checkbox disabled v-model="props.item[header.value]" :color="header.color"></v-checkbox>
                  </div>

                  <!-- boolean-icon -->
                  <div v-else-if="header.type == 'boolean-icon'">
                    <v-icon v-if="props.item[header.value]" :color="header.color">{{ header.icon }}</v-icon>
                  </div>

                  <!-- chip -->
                  <div v-else-if="header.type == 'chip'">
                    <v-chip
                      v-for="(chip, chipIndex) in props.item[header.value]"
                      :key="chipIndex"
                      small
                    >
                      <small>{{ chip.value }}</small>
                    </v-chip>
                  </div>

                  <!-- date -->
                  <div v-else-if="header.type == 'date'">
                    <v-badge v-if="props.item[header.icon]" :color="header.color">
                      <template slot="badge">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon dark small v-on="on">
                              {{
                              props.item[header.icon]
                              }}
                            </v-icon>
                          </template>
                          {{ props.item.iconTooltip }}
                        </v-tooltip>
                      </template>
                      <span>{{ formatDate(props.item[header.value]) }}</span>
                    </v-badge>
                    <span v-else>
                      {{
                      formatDate(props.item[header.value])
                      }}
                    </span>
                  </div>

                  <!-- outros -->
                  <div v-else>
                    <v-badge v-if="props.item[header.icon]" :color="props.item[header.color]" left>
                      <template slot="badge">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon dark small v-on="on">
                              {{
                              props.item[header.icon]
                              }}
                            </v-icon>
                          </template>
                          {{ props.item[header.iconTooltip] }}
                        </v-tooltip>
                      </template>
                      {{ props.item[header.value] }} <span>aqui</span>
                      <div
                        v-if="props.item[header.subheader]"
                        class="caption grey--text"
                      >{{ props.item[header.subheader] }}</div>
                      <div
                        v-if="props.item[header.subheader2]"
                        class="caption grey--text"
                      >{{ props.item[header.subheader2] }}</div>
                    </v-badge>
                    <div v-else>
                      <span v-if="typeof props.item[header.value] === 'number'"
                        v-html="props.item[header.value]"
                      ></span>
                      <span v-else v-html="props.item[header.value] ? props.item[header.value].replace(/(?:\r\n|\r|\n)/g, '<br>') : ''"></span>
                      
                      <div
                        v-if="props.item[header.subheader]"
                        class="caption grey--text"
                      >{{ props.item[header.subheader] }}</div>
                      <div
                        v-if="props.item[header.subheader2]"
                        class="caption grey--text"
                      >{{ props.item[header.subheader2] }}</div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>

            <template slot="footer">
              <td colspan="2">&emsp;</td>
              <td v-if="imprimir" colspan="2">
                <span>&emsp;</span>
                <v-spacer></v-spacer>
                <span v-if="totalSelect > 0">
                  <v-btn :disabled='isPrinting' @click="print">
                    <v-icon>print</v-icon>Imprimir {{totalSelect}} Demandas
                  </v-btn>
                </span>
                <span v-else>
                  <v-btn :disabled=true >
                    <v-icon>print</v-icon>Imprimir</v-btn>
                </span>
              </td>
              <td :colspan="headers.length - 2">
                <slot name="footer"></slot>
              </td>
            </template>

            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >Sua busca por "{{ search }}" não retornou resultados.</v-alert>
          </v-data-table>

          <v-card-actions style="width: 95%; padding-bottom: 20px; margin: auto;">
            <v-container>
              <v-row v-if="paginationInfo" align="center" justify="center">
                <v-col xs="12" md="8" class="text-center caption">{{ paginationInfo }}</v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col xs="12" md="2">
                  <div class="v-data-footer">Registros por página:</div>
                </v-col>
                <v-col xs="12" md="1">
                  <v-select
                    :items="options.itemsPerPageOptions"
                    :value="options.itemsPerPage"
                    @input="paginationPerPageValuedChanged"
                    dense
                    light
                  />
                </v-col>
                <v-col xs="12" md="4">
                  <div class="v-data-footer">Exibindo itens 
                    {{(options.current_page * options.itemsPerPage)- options.itemsPerPage + 1}} a 
                    {{options.current_page == options.last_page ? options.total : options.current_page * options.itemsPerPage}} de {{options.total}}
                  
                  </div>
                </v-col>
                <v-col xs="9">
                  <div class="v-data-footer__select">
                    <v-pagination
                      v-if="options"
                      :value="options.current_page"
                      :length="options.last_page"
                      total-visible="7"
                      @input="paginationPageValueChanged"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex v-else fill-height transition="slide-x-transition">
        <v-form v-model="valid">
          <v-card :color="color">
            <v-card-title style="width: 99%;">
                <v-toolbar-title>
                {{
                (entidade.id == null
                ? verboAdicionarEntidade
                : verboAlterarEntidade) +
                " " +
                nomeEntidade
                }}
              </v-toolbar-title>
              <!-- {{ entidade[itemKey] ? '#' + entidade[itemKey] : ''}} -->
              <v-divider class="mx-2" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <slot name="topFormArea"></slot>
              <v-spacer></v-spacer>
              <v-btn @click="cancelar">
                <v-icon>arrow_back</v-icon>Voltar
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-container fluid class="pa-4 pl-8">
                <slot name="detalhe"></slot>
              </v-container>
            </v-card-text>
            <v-card-actions style="width: 99%;">
              <v-spacer></v-spacer>
              <v-btn color="grey ligthen" @click="cancelar">
                <v-icon>arrow_back</v-icon>Voltar
              </v-btn>
              <slot name="beforeSaveButton"></slot>
              <v-btn
                color="primary"
                @click="salvar"
                :disabled="!valid || !podeSalvar"
                :loading="salvando"
              >
                <v-icon>save_alt</v-icon>Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from "vue";
import EventBus from '../event-bus';
import Money from './../../node_modules/v-money';
Vue.use(Money, {precision: 2});
export default {
  data: () => {
    return {
      exibirGrid: true,
      entidade: { id: null },
      search: "",
      searchFocused: false,
      selected: [],
      selectAll: false,
      isPrinting: false,
      totalSelect:0,
      valid: false,

      options: {
        search: "",
        current_page: 1,
        itemsPerPage: 10,
        ascending: true,
        itemsPerPageOptions: [
          10,
          25,
          50,
          100,
          200,
          { text: "Todos", value: 999999 },
        ],
      },
      paginationInfo: "",
      origem:-1,
      items: [],
      columnFilters: [],
      columnFilterItems: [],
      carregando: false,
      salvando: false,
    };
  },
  props: {
    color: {
      type: String,
      default: 'white'
    },
    exibirPrimeiraTela: {
      type: Boolean,
      default: true,
    },
    exibirBotaoCadastrar: {
      type: Boolean,
      default: true,
    },

    voltarParaPrimeiraTelaAoSalvar: {
      type: Boolean,
      default: true,
    },

    nomeEntidade: String,

    nomeEntidadePlural: String,

    verboAdicionarEntidade: {
      type: String,
      default: "Cadastrar",
    },

    verboAlterarEntidade: {
      type: String,
      default: "Editar",
    },

    headers: {
      type: Array,
      required: true,
    },

    imprimir: {
      type: Boolean,
      default: false,
    },

    podeSalvar: {
      type: Boolean,
      default: true,
    },

    "resource-url": {
      type: String,
      required: true,
    },
    "rota-edicao": {
      type: String,
      required: false,
      default: null
    },
    "item-key": {
      type: String,
      required: true,
    },
    "for-each-item-callback": {
      type: Function,
    },
    "query-params": {
      type: Object,
      default: () => {
        return {};
      },
    },
    /**
     * Função a ser executada quando o usuário salva o registro.
     */
    clicouSalvar: {
      type: Function,
    }
  },

  methods: {
    clicouItem (item) {
      this.entidade = item;
      this.$emit("clicou-item", item);
      this.exibirGrid = false;
    },
    cadastrarNovo () {
      (this.entidade = { id: null }), this.$emit("clicou-novo");
      this.exibirGrid = false;
    },
    async salvar () {
      this.salvando = true
      await this.clicouSalvar()
        .then(
          () => {
            if (this.voltarParaPrimeiraTelaAoSalvar) {
              this.exibirGrid = true;
              this.loadItems()
            }
          }
        )
        .catch(e => {
          console.log(e)
          EventBus.alerta(e)
        })
        .finally(() => {
          this.salvando = false
        })
    },
    cancelar () {
      this.$emit("clicou-cancelar");
      this.exibirGrid = true;
    },
    select(){
      this.selected = []; 
      this.totalSelect = 0;
      if (this.selectAll) {
          for (let i in this.items) {
              this.selected.push(this.items[i]);
          }
          //this.selectAll = true;
          this.totalSelect = this.selected.length;
      }
    },
    updateCheckall(){
      this.totalSelect = this.selected.length;
      if(this.items.length == this.selected.length){
          this.selectAll = true;
      }else{
          this.selectAll = false;
      }
    },

    async print () {
      this.isPrinting = true;
      await this.$emit("clicou-imprimir", this.selected);
    },

    formatDate (date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    estiloDaColuna (cabecalhoColuna) {
      let estilo = "cursor: pointer; ";
      if (cabecalhoColuna.type == "hidden") {
        estilo = estilo + "display: none; ";
        estilo = estilo + "width: 0px; ";
      }
      if (cabecalhoColuna.type == "number") {
        //console.log("achei")
        estilo = estilo + "text-align: right !important; padding-right: 16px !important;";
      }
      return estilo;
    },

    toggleSort (index) {
      if (this.headers[index].sortable) {
        let sortByColumnChanged = this.setSortByColumn(index);
        if (sortByColumnChanged) {
          this.options.ascending = true;
        } else {
          this.options.ascending = !this.options.ascending;
        }

        this.loadItems();
      }
    },

    setSortByColumn (index) {
      if (!this.headers[index]) {
        return false;
      }
      if (this.headers[index].origem && this.headers[index].origem.length> 0){
        this.origem = index;
      }
      let newSortedColumn = this.headers[index].value;
      let sortByColumnChanged = this.options.order != newSortedColumn;
      this.options.order = newSortedColumn;
      return sortByColumnChanged;
    },

    initialize () {
      this.setSortByColumn(0);
      this.loadItems();
    },

    getRequestQuery () {
      //console.log("getRequestQuery", this.options);
      this.options.current_page = this.options.current_page
        ? this.options.current_page
        : 1;

      let firstOp = this.resourceUrl.includes("?") ? "&" : "?";
      let parametros = firstOp + "page=" + this.options.current_page;

      this.options.per_page = this.options.per_page
        ? this.options.per_page
        : 10;
      parametros += "&per_page=" + this.options.per_page;
      //console.log("getRequestQuery order ", this.options.order);

      if (this.options.order) {
        if (this.origem > -1 && this.headers[this.origem].origem.length > 0){
          parametros += "&order=" +  this.headers[this.origem].origem;
        }
        else{
          parametros += "&order=" + this.options.order;
        }
      }

        parametros += "&ascending=" + (this.options.ascending ? "true" : "false");

      if (this.options.search) {
        parametros += "&search=" + this.options.search;
      }

      parametros = this.applyQueryParams(parametros);

      return parametros;
    },

    applyQueryParams (parametros) {
      if (
        Object.keys(this.queryParams).map((key) => {
          parametros += "&" + key + "=" + this.queryParams[key];
        })
      )
        return parametros;
    },

    async loadItems () {
      let parametros = this.getRequestQuery();
      let url = this.resourceUrl + parametros;
      this.items = []
      this.selected = []
      this.totalSelect = 0,
      this.selectAll = false
      this.carregando = true;
      this.$http
        .get(url)
        .then(
          (response) => {
            this.items = [];
            this.fillPagination(response.body);
            response.body.data.forEach(element => {
              if (this.forEachItemCallback) {
                element = this.forEachItemCallback(element);
              }
              if(this.resourceUrl.match('destinacoes/destinacoes')){

                let numeroFormatado = element.area_terreno.replace(/[^0-9.]/g, '');
                var dt = new Date(element.created_at);
                element.form_created_at = 'Cadastrado em: '+dt.toLocaleString("pt-BR").substr(0, 10);
                const [parteInteira, parteDecimal] = numeroFormatado.split('.');
                let parteInteiraFormatada = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                element.area = parteInteiraFormatada +','+ parteDecimal;

                element.util_especifica_nome = element.util_especifica.nome
                element.municipio_cidade=element.municipio.cidade;
                element.estado_descricao = element.municipio.estado.descricao;
                element.categoria_nome=element.categoria.nome;
                if (element.natureza_id){
                  element.natureza_nome=element.natureza.nome;
                }else{
                  element.natureza_nome = ''
                }
                element.linha_programa_nome=element.linha_programa.nome;
                
                element.propr_presumida_nome=element.propr_presumida.nome;
              }
              this.items.push(element);
              this.fillColumnFilterItems(element);
            });
            this.carregando = false;
          },
          (error) => {
            this.carregando = false;
            console.log(error);
          }
        )
        .finally(() => {
          this.carregando = false;
        });

    },

    fillPagination (apiResponse) {
      // console.log("CRUD.fillPagination()", apiResponse);
      this.options.current_page = apiResponse.current_page;
      this.options.last_page = apiResponse.last_page;
      this.options.from = Number.parseInt(apiResponse.from);
      this.options.to = Number.parseInt(apiResponse.to);
      this.options.total = Number.parseInt(apiResponse.total);

      this.options.itemsPerPage = Number.parseInt(apiResponse.per_page);

      Vue.nextTick(this.updatePaginationInfo);
    },

    paginationPageValueChanged (newPage) {
      // console.log("paginationPageValueChanged", newPage);
      this.options.current_page = newPage;
      this.loadItems();
    },

    paginationPerPageValuedChanged (newPerPage) {
      // console.log("paginationPerPageValuedChanged", newPerPage);
      this.options.per_page = newPerPage;

      let paginas = Math.floor(this.options.total / this.options.per_page);
      paginas =
        paginas + (this.options.total % this.options.per_page > 0 ? 1 : 0);
      if (this.options.current_page > paginas) {
        this.options.current_page = paginas;
      }

      this.loadItems();
    },

    searchMessage () {
      if (this.search == null || this.search == this.options.search) {
        return null;
      }
      if (this.searchFocused) {
        return "Pressione ENTER para realizar a pesquisa";
      } else {
        return "Clique na lupa para realizar a pesquisa";
      }
    },

    resetPagination () {
      this.options.current_page = 1;
      this.options.last_page = 1;
      this.options.total = 0;
    },

    doSearch () {
      this.resetPagination();
      this.options.search = this.search;
      this.loadItems();
    },
    updatePaginationInfo: function () {
      let info = "";
      if (this.carregando) {
        return;
      }
      if (
        this.items == null ||
        this.items.last_page == 0 ||
        this.options.total == 0
      ) {
        info = "Nenhum registro encontrado";
      } else {
        if (this.items.length == 1 || this.options.total == 1) {
          info = "Exibindo o único registro encontrado";
        } else {
          info =
            "Exibindo registros de " +
            this.options.from +
            " a " +
            this.options.to +
            " do total de " +
            this.options.total +
            " encontrados";
        }
      }
      if (this.options.search != null && this.options.search != "") {
        info = info + ' para a pesquisa por "' + this.options.search + '"';
      }
      this.paginationInfo = info + ".";
    },

    showGridPanel () {
      this.exibirGrid = true;
    },

    showDetailsPanel () {
      this.exibirGrid = false;
    },
    fillColumnFilterItems(element) {
      this.headers.forEach((columnHeader, index) => {
      if (!Array.isArray(this.columnFilterItems[index])) {
        this.columnFilterItems[index] = [];
      }
      const propertyPath = columnHeader.value.split('.');
      let propertyValue = element;

      for (const prop of propertyPath) {
        if (propertyValue.hasOwnProperty(prop)) {
          propertyValue = propertyValue[prop];
        } else {
          propertyValue = null;
          break; // Se uma propriedade não existir, pare de procurar
        }
      }
      this.columnFilterItems[index].push(propertyValue);
    });

  },
    thereAreActiveFilters () {
      return this.columnFilters.some((filter) => {
        if (filter != null) {
          return true;
        }
      });
    },

    itemMatchsFilters (item) {
      //console.log("itemMatchesFilters", item);
      return this.columnFilters.some((filter, columnIndex) => {
        filter = filter.toLowerCase()
        let itemProperty = this.headers[columnIndex].value;
        let itemPropertyValue = item[itemProperty];
        console.log("Verificando se o item " + item.id + " contém o valor '" + filter + "'  no atributo '" + itemProperty + "', que no contém '" + itemPropertyValue + "' ")
        if (typeof itemPropertyValue == 'string') {
          //console.info("é uma string")
          let match = (" " + itemPropertyValue.toLowerCase() + " ").includes(filter)
          console.log("deu match?", match)
          if (match) {
            return true;
          }
        }
        return false;
      });
    },
  },

  computed: {
    computedItems () {
      if (!this.thereAreActiveFilters()) {
        return this.items;
      }
      return this.items.filter(this.itemMatchsFilters);
    },
  },

  watch: {
    exibirPrimeiraTela: function (val) {
      // console.log("CRUD.watch:exibirPrimeiraTela", val);
      this.exibirGrid = val;
    },

    valid: function (val) {
      // console.log("CRUD.watch:valid", val);
      this.$emit("validou-formulario", val);
    },

    exibirGrid: function (val) {
      // console.log("CRUD.watch:exibirGrid", val);
      this.$emit("modou-tela", val);
    },

    resourceUrl: function (val) {
      // console.log("CRUD.watch:resourceUrl", val);
      this.loadItems();
    },

    "options.total": function (val, oldVal) {
      // console.log("CRUD.watch:options", val, oldVal);
      this.updatePaginationInfo();
    },

    columnFilters: function (val) {
      //this.loadItems()
    }
  },

  mounted () {
    if (this.$route.params[this.itemKey] && this.$route.name == this.rotaEdicao) {
      this.exibirGrid = false;
    }
    this.initialize();
  },
};
</script>

<style>
.hidden {
  display: none;
}
</style>
