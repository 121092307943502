<template>
  <crud
    v-if="urlBase"
    ref="crud"
    nomeEntidade="Natureza do Órgão"
    nomeEntidadePlural="Naturezas dos Órgãos"
    :headers="cabecalhos"
    :resource-url="urlBase"
    itemKey="id"
    @clicou-item="selecionarParaEdicao"
    :clicou-salvar="salvar"
    @clicou-cancelar="cancelar"
    @clicou-novo="novo"
  >
    <template slot="detalhe">
      <div v-if="entidadeAtual">
        <v-text-field
          label="Natureza"
          v-model="entidadeAtual.natureza"
          :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(entidadeAtual.natureza, 5)]"
          required
          counter="100"
          maxlength="100"
        />
      </div>
    </template>
  </crud>
</template>

<script>
import rotas from '../../../rotas-servico'
import CRUD from '../../../components/ApiCrud.vue'
import Validador from '../../../validacao';
import EventBus from './../../../event-bus'

export default {
  components: {
    'crud': CRUD
  },
  data: () => {
    return {
      cabecalhos: [
        {
          text: 'Natureza',
          value: 'natureza'
        }
      ],
      entidadeAtual: null,
      validacao: Validador,

      carregandoNaturezas: false,

      urlBase: rotas.demandas.naturezaOrgao.listar.url()
    }
  },
  methods: {
    selecionarParaEdicao (item) {
      this.carregarNaturezas()
      this.entidadeAtual = item
    },
    salvar () {
      return new Promise((resolve, reject) => {
        try {
          let formData = new FormData()
          formData.append('natureza', this.entidadeAtual.natureza)
          if (this.entidadeAtual.id != null) {
            formData.append('_method', 'PUT')
          }

          let url = this.entidadeAtual.id === null ?
            rotas.demandas.naturezaOrgao.criar.url() :
            rotas.demandas.naturezaOrgao.editar.url({
              id: this.entidadeAtual.id
            });

          this.$http.post(url, formData)
            .then(
              response => {
                EventBus.mensagem('Natureza do Órgão cadastrado com sucesso!')
                resolve(response)
              },
              error => {
                reject(new Error(error.body.message))
              }
            )
        } catch (e) {
          reject(e)
        }
      })
    },
    cancelar () {
      this.$refs.crud.loadItems()
    },
    novo (item) {
      this.carregarNaturezas()
      this.entidadeAtual = {
        id: null
      }
    },

    carregarNaturezas () {
      this.carregandoNaturezas = true
      this.$http
        .get(this.urlBase + "?per_page=-1")
        .then(
          response => {
            this.carregandoNaturezas = false
          },
          error => {
            console.log(error)
            this.carregandoNaturezas = false
          }
        )
    },
  },
  mounted () {
  }
}
</script>
