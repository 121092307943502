<template>
  <card-grafico
    titulo="Origem das Demandas"
    :carregando="!carregouDemandasPorDemandante"
  >
    <grafico-barra-horizontal
      :chartdata="demandasPorDemandante"
      :options="opcoesRelatorioDemandasPorDemandante"
    />
  </card-grafico>
</template>

<script>
import Utils from "./../../../../Utils";
import rotas from "./../../../../rotas-servico";
import GraficoBarraHorizontal from "./../../../../components/relatorios/GraficoBarraHorizontal.vue";
export default {
  components: {
    GraficoBarraHorizontal,
  },
  props: {
    unidadeGestora: {
      type: Object,
    },
  },
  data() {
    return {
      carregouDemandasPorDemandante: false,
      dataDe: null,
      dataAte: null,
      demandasPorDemandante: null,
      opcoesRelatorioDemandasPorDemandante: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
        legend: {
          position: "bottom",
        },
      },
    };
  },

  methods: {
    carregarRelatorio(dateDe, dateAte) {
      this.dataDe = Utils.toIsoDate(dateDe);
      this.dataAte = Utils.toIsoDate(dateAte);
      this.carregarDemandasPorDemandante();
    },

    async carregarDemandasPorDemandante() {
      this.carregouDemandasPorDemandante = false;
      this.demandasPorDemandante = {
        labels: [],
        datasets: [
          {
            label: "Nova",
            data: [],
            backgroundColor: "red",
          },
          {
            label: "Em análise",
            data: [],
            backgroundColor: "green",
          },
          {
            label: "Pronta",
            data: [],
            backgroundColor: "orange",
          },
          {
            label: "Aguardando Assinatura",
            data: [],
            backgroundColor: "black",
          },
          {
            label: "Aguardando AR",
            data: [],
            backgroundColor: "purple",
          },
          {
            label: "Resolvida",
            data: [],
            backgroundColor: "grey",
          },
        ],
      };

      let url = rotas.demandas.relatorio.abertasPorDemandante.url();
      if (this.dataDe != null && this.dataAte != null) {
        url = url + "/" + this.dataDe + "/" + this.dataAte;
        if (this.unidadeGestora != null && this.unidadeGestora.id != null) {
          url = url + "/" + this.unidadeGestora.id;
        }
      }
      return this.$http
        .get(url)
        .then((res) => {
          let outros = {
            nova: 0,
            em_analise: 0,
            pronta: 0,
            aguardando_assinatura: 0,
            aguardando_ar: 0,
            resolvida: 0,
          };

          var tamanhoMinimoParaDetalhamento = 0;
          if (res.data.length > 6) {
            tamanhoMinimoParaDetalhamento = 10;
          }

          var totalOutros = 0;
          res.data.forEach((element) => {
            if (element.total > tamanhoMinimoParaDetalhamento) {
              this.demandasPorDemandante.labels.push(element.orgao);
              this.demandasPorDemandante.datasets[0].data.push(element.nova);
              this.demandasPorDemandante.datasets[1].data.push(
                element.em_analise
              );
              this.demandasPorDemandante.datasets[2].data.push(element.pronta);
              this.demandasPorDemandante.datasets[3].data.push(
                element.aguardando_assinatura
              );
              this.demandasPorDemandante.datasets[4].data.push(
                element.aguardando_ar
              );
              this.demandasPorDemandante.datasets[5].data.push(
                element.resolvida
              );
            } else {
              totalOutros++;
              outros.nova += element.nova;
              outros.em_analise += element.em_analise;
              outros.pronta += element.pronta;
              outros.aguardando_assinatura += element.aguardando_assinatura;
              outros.aguardando_ar += element.aguardando_ar;
              outros.resolvida += element.resolvida;
            }
          });

          if (totalOutros > 0) {
            this.demandasPorDemandante.labels.push("Outros");
            this.demandasPorDemandante.datasets[0].data.push(outros.nova);
            this.demandasPorDemandante.datasets[1].data.push(outros.em_analise);
            this.demandasPorDemandante.datasets[2].data.push(outros.pronta);
            this.demandasPorDemandante.datasets[3].data.push(
              outros.aguardando_assinatura
            );
            this.demandasPorDemandante.datasets[4].data.push(
              outros.aguardando_ar
            );
            this.demandasPorDemandante.datasets[5].data.push(outros.resolvida);
          }

          this.carregouDemandasPorDemandante = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  watch: {
    unidadeGestora(val) {
      this.carregarDemandasPorDemandante();
    },
  },
};
</script>

<style>
</style>