<template>
  <card-grafico
    titulo="Distribuição"
    :carregando="!carregouDemandasAbertasPorDistribuicao"
  >
    <grafico-barra-horizontal
      :chartdata="demandasAbertasPorDistribuicao"
      :options="opcoesRelatorioDemandasAbertasPorDistribuicao"
    />
  </card-grafico>
</template>

<script>
import CardGrafico from "./../../../../components/relatorios/CardGrafico.vue";
import Utils from "./../../../../Utils";
import rotas from "./../../../../rotas-servico";
export default {
  components: {
    CardGrafico,
  },
  props: {
    unidadeGestora: {
      type: Object,
    },
  },
  data() {
    return {
      carregouDemandasAbertasPorDistribuicao: false,
      dataDe: null,
      dataAte: null,
      demandasAbertasPorDistribuicao: null,
      opcoesRelatorioDemandasAbertasPorDistribuicao: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            grid: {
              offset: true,
            },
          },
        },
      },
    };
  },

  methods: {
    carregarRelatorio(dateDe, dateAte) {
      this.dataDe = Utils.toIsoDate(dateDe);
      this.dataAte = Utils.toIsoDate(dateAte);
      this.carregarDemandasAbertasPorDistribuicao();
    },

    async carregarDemandasAbertasPorDistribuicao() {
      this.carregouDemandasAbertasPorDistribuicao = false;
      this.demandasAbertasPorDistribuicao = {
        labels: [],
        datasets: [
          {
            label: "Demandas",
            backgroundColor: "blue",
            data: [],
          },
        ],
      };
      let url = rotas.demandas.relatorio.abertasPorDistribuicao.url();
      if (this.dataDe != null && this.dataAte != null) {
        url = url + "/" + this.dataDe + "/" + this.dataAte;
        if (this.unidadeGestora != null && this.unidadeGestora.id != null) {
          url = url + "/" + this.unidadeGestora.id;
        }
      }
      return this.$http
        .get(url)
        .then((res) => {
          res.data.forEach((element) => {
            this.demandasAbertasPorDistribuicao.labels.push(
              element.colaborador
            );
            this.demandasAbertasPorDistribuicao.datasets[0].data.push(
              element.demandas_distribuidas
            );
          });
          this.carregouDemandasAbertasPorDistribuicao = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    unidadeGestora(val) {
      this.carregarDemandasAbertasPorDistribuicao();
    },
  },
};
</script>

<style>
</style>