<template>
  <v-container fluid>
    <v-row align="start">
      <v-col md="9">
        <card-graficos-demandas />
      </v-col>
      <v-col md="3">
        <card-relatorio-demandas-por-nucleo />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardGraficosDemandas from "./dashboard/CardGraficosDemandas.vue";
import CardRelatorioDemandasPorNucleo from "./dashboard/CardRelatorioDemandasPorNucleo.vue";
export default {
  components: { CardGraficosDemandas, CardRelatorioDemandasPorNucleo },
};
</script>

<style>
</style>