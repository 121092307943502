<template>
    <div>
      <file-upload
        ref="upload"
        v-bind="options"
        @input="onFileSelected"
        class="file-upload"
      >
        <button>Selecionar Imagem</button>
      </file-upload>
      <img v-if="imageUrl" :src="imageUrl" alt="Imagem Carregada"  style="width: 400px; height: auto;"/>
    </div>
  </template>
  
  <script>
  import Destinacoes from './Destinacoes.vue';
  export default {
    data() {
      return {
        imagem: null, 
        imageUrl: null,
       
        options: {
          accept: 'image/*', // Aceitar apenas arquivos de imagem
        },
      };
    },
    methods: {
      onFileSelected(file) {
        this.imagem=file;
        if (file instanceof Array && file.length > 0) {
          const realFile = file[0].file; // Acesse o arquivo real dentro do objeto retornado
          if (realFile instanceof Blob || realFile instanceof File) {
            this.imageUrl = URL.createObjectURL(realFile);
          } else {
            console.error('Arquivo inválido:', realFile);
          }
        } else {
          console.error('Nenhum arquivo selecionado.');
        }
      }      
    },
  };
  </script>
  
  <style scoped>
  /* Estilos personalizados para o componente */
  .file-upload {
    
  }
  </style>