<template>
  <v-app>
    <v-navigation-drawer
      v-if="usuarioAutenticado"
      app
      :value="exibirMenu"
      clipped
      left
    >
      <spu-menu :itensMenu="menuSistema"></spu-menu>
    </v-navigation-drawer>

    <cabecalho />

    <v-main>
      <mensagem-sistema />
      <v-breadcrumbs :items="$route.meta.breadcrumb"></v-breadcrumbs>
      <v-container fluid class="ma-5 pa-5 pr-8">
            <transition name="fade">
              <slot></slot>
            </transition>
      </v-container>
    </v-main>
    <v-footer max-height="50px" light padless>
      <v-row>
        <v-col xs="12" style="padding: 0px; padding-top: 10px">
          <p class="text-center text-caption text--secondary">
            {{ appInfo.name }}
            {{ appInfo.version ? " - versão " + appInfo.version : "" }}
            <span v-if="$route.name == 'Inicio'">
               - <a href='https://www.freepik.com/photos/keyboard-typing' style="text-decoration: none; color: gray;">Keyboard typing photo created by pressfoto - www.freepik.com</a>
            </span>
          </p>
          <p class="text-center text-caption text--secondary"></p>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import MensagemSistema from "./MensagemSistema";
import Cabecalho from "./Cabecalho";
import Menu from "./Menu";
import rotas from "./../../rotas-servico/";

export default {
  components: {
    MensagemSistema,
    Cabecalho,
    "spu-menu": Menu,
  },
  data: () => ({
    usuario: null,
    appInfo: {
      version: null,
      env: "production",
    },
  }),

  methods: {
    carregarMenu() {
      // console.log("AppTemplate.carregarMenu()", this.usuario)
      if (this.usuario == null) {
        return;
      }
      this.$http.get(rotas.controleAcesso.menu.url()).then(
        (response) => {
          // console.log(response)
          this.$store.commit("auth/menu", response.body);
        },
        () => {
          console.log("Erro ao carregar menu");
        }
      );
    },

    carregarVersaoSistema() {
      this.$http.get(rotas.core.info.url()).then(
        (response) => {
          this.appInfo = response.body;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },

  computed: {
    menuSistema() {
      //console.log("AppTemplate.computed.menuSistema()")
      let itensMenu = this.$store.getters["auth/menu"];
      //this.$store.commit("sistema/exibirMenu", itensMenu == null);
      return itensMenu;
    },

    exibirMenu() {
      return this.$store.getters["sistema/exibirMenu"];
    },

    usuarioAutenticado() {
      // console.log("AppTemplate.computed.usuarioAutenticado()")
      this.usuario = this.$store.getters["auth/usuario"];
      // console.log(this.usuario)
      return this.usuario;
    },
  },

  watch: {
    $route() {
      this.breadcrumb = this.$route.meta.breadcrumb;
    },

    usuario() {
      // console.log("AppTemplate.watch.usuario", this.usuario)
      this.carregarMenu();
    },
  },

  mounted() {
    this.carregarVersaoSistema();
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
div.menu-siga-icon {
  margin-right: 15pt !important;
}
.menu-siga-group > div > div.menu-siga-title {
  width: 125pt;
}
.menu-siga-group > div {
  padding: 0;
}
.menu-siga-group > div > div[class*="icon"] {
  margin: 0 !important; 
  width: 20pt !important; 
  min-width: 10pt !important;
}
</style>
