<template>
  <card-grafico
    titulo="Natureza das Demandas"
    :carregando="!carregouDemandasPorNaturezaOrgao"
  >
    <div v-if="demandasNaturezaOrgao == null">
      Não carregou dados ainda.
    </div>
    <div v-else-if="demandasNaturezaOrgao.datasets[0].data.length == 0">
      Sem dados para exibição.
    </div>
    <grafico-pizza v-else
      :chartdata="demandasNaturezaOrgao"
      :options="opcoesRelatorioNaturezaOrgao"
      style="position: 'relative'; width:100%;"
    />
  </card-grafico>
</template>

<script>
const d3 = require("d3-scale-chromatic");
import CardGrafico from '../../../../components/relatorios/CardGrafico.vue'
import rotas from "../../../../rotas-servico"
import Utils from '../../../../Utils';


export default {
  components: {
    CardGrafico,
  },

  props: {
    unidadeGestora: {
      type: Object
    }
  },

  data() {
    return {
      carregouDemandasPorNaturezaOrgao: false,
      dataDe: null,
      dataAte: null,
      demandasNaturezaOrgao: null,
      opcoesRelatorioNaturezaOrgao: {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: true,
          position: "bottom",
        },
      },
    };
  },

  methods: {

    carregarRelatorio(dateDe, dateAte) {
      // console.log("carregarRelatorio");
      // console.log(dateDe, dateAte)
      this.dataDe = Utils.toIsoDate(dateDe)
      this.dataAte = Utils.toIsoDate(dateAte)
      // console.log(this.dataDe, this.dataAte);
      this.carregarDemandasNaturezaOrgao()
    },

    async carregarDemandasNaturezaOrgao() {
      this.carregouDemandasPorNaturezaOrgao = false;
      this.demandasNaturezaOrgao = {
        labels: [],
        datasets: [
          {
            data: [],
          },
        ],
      };

      let url = rotas.demandas.relatorio.abertasPorNaturezaOrgao.url();
      if (this.dataDe != null && this.dataAte != null) {
        url = url + "/" + this.dataDe + "/" + this.dataAte;
        if(this.unidadeGestora != null && this.unidadeGestora.id != null) {
          url = url + "/" + this.unidadeGestora.id
        }
      }
      return this.$http
        .get(url)
        .then((res) => {
          res.data.forEach((element) => {
            this.demandasNaturezaOrgao.labels.push(element.natureza);
            this.demandasNaturezaOrgao.datasets[0].data.push(
              element.quantidade
            );
          });

          const colorRangeInfo = {
            colorStart: 0.2,
            colorEnd: 1,
            useEndAsStart: true,
          };

          // gerar automaticamente as cores do grafico
          let colors = this.interpolateColors(
            this.demandasNaturezaOrgao.datasets[0].data.length,
            d3.interpolateBlues,
            colorRangeInfo
          );
          this.demandasNaturezaOrgao.datasets[0].backgroundColor = colors;

          this.carregouDemandasPorNaturezaOrgao = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    calculatePoint(colorRangeInfo, i, intervalSize) {
      var { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
      return useEndAsStart
        ? colorEnd - i * intervalSize
        : colorStart + i * intervalSize;
    },

    interpolateColors(dataLength, colorScale, colorRangeInfo) {
      var { colorStart, colorEnd } = colorRangeInfo;
      var colorRange = colorEnd - colorStart;
      var intervalSize = colorRange / dataLength;
      var i, colorPoint;
      var colorArray = [];

      for (i = 0; i < dataLength; i++) {
        colorPoint = this.calculatePoint(colorRangeInfo, i, intervalSize);
        colorArray.push(colorScale(colorPoint));
      }

      return colorArray;
    },
  },

  watch: {

    unidadeGestora(val) {
      this.carregarDemandasNaturezaOrgao()

    }

  },

  mounted() {
  },
};
</script>

<style>
</style>