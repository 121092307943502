class Rota {

    constructor(rota, opcoes = {}, prefixo_path = Rota.separador()) {
        this.nome = rota
        this._path = prefixo_path
        if(opcoes.path == null) {
            this._path += Rota.separador() + this.nome
        } else {
            if(opcoes.path.length > 0) {
                this._path += Rota.separador() + opcoes.path
            }
        }
        // console.log(this._path)
    }

    static separador() {
        return "/"
    }

    static prefixoParametro() {
        return "{"
    }

    static sufixoParametro() {
        return "}"
    }

    static parametro(parametro) {
        return Rota.prefixoParametro() + parametro + Rota.sufixoParametro()
    }


    url(parametros = {}) {
        let urlParametrizada = this._path
        for (const parametro in parametros) {
            if (Object.prototype.hasOwnProperty.call(parametros, parametro)) {
                let marcacao = Rota.parametro(parametro);
                urlParametrizada = urlParametrizada.replace(marcacao, parametros[parametro])
            }
        }
        return urlParametrizada
    }
}

export default Rota
