<template>
  <v-container fluid fill-height>
    <v-row align="stretch" justify="center">
      <v-col xs="12" md="6" lg="5" xl="3" align="center">
        <v-form v-model="valid" method="POST">
          <v-card>
            <v-card-title>Trocar Email </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="novoEmail"
                      :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(novoEmail, 8)]"
                      label="Novo email"
                      required
                      :suffix="sufixoEmail"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="confirmacaoEmail"
                      :rules="[validacao.obrigatorio, 
                        validacao.tamanhoMinimo(confirmacaoEmail, 8), 
                        validacao.igual(novoEmail, confirmacaoEmail, 'Confirmação não corresponde à senha informada')]"
                      label="Confirmação"
                      required
                      :suffix="sufixoEmail"
                      @keyup.enter="submit"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-row justify="end" class="mr-2">
                <v-btn
                  text
                  color="info"
                  @click="submit"
                  :disabled="!valid || carregando"
                >
                  <v-progress-circular
                    indeterminate
                    v-if="carregando"
                    color="grey"
                    size="20"
                    width="3"
                  ></v-progress-circular>
                  Salvar e continuar
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rotas from "../rotas-servico";
import Validador from "../validacao.js";
import EventBus from '../event-bus';

export default {
  data: () => ({
    carregando: false,
    valid: false,
    validacao: Validador,

    novoEmail: '',
    confirmacaoEmail: '',

    sufixoEmail: '@economia.gov.br'

  }),
  methods: {
    submit() {

      let formData = new FormData();
      formData.append("novoEmail", this.novoEmail + this.sufixoEmail);
      formData.append("confirmacaoEmail", this.confirmacaoEmail + this.sufixoEmail);

      this.carregando = true;
      return this.$http
        .post(rotas.controleAcesso.trocarEmail.url(), formData)
        .then(
          (response) => {
            EventBus.$emit('App::onLogin', response.data)
          },
          (error) => {
            if (error.status == 422) {
              let campos = Object.keys(error.body.errors)
              EventBus.alerta(error.body.errors[campos[0]][0])
            } else {
              EventBus.alerta(error.message)
            }
          }
        ).finally(() => {
          this.carregando = false;
        });
    },
  },
};
</script>