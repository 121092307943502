<template>
  <crud
    v-if="urlBase"
    ref="crud"
    nomeEntidade="Demandante"
    nomeEntidadePlural="Demandantes"
    :headers="cabecalhos"
    :resource-url="urlBase"
    :query-params="parametrosConsultaAPI"
    itemKey="id"
    @clicou-item="selecionarParaEdicao"
    :clicou-salvar="salvar"
    @clicou-cancelar="cancelar"
    @clicou-novo="novo"
  >
    <template slot="beforeAdd">
      <v-switch
        v-if="usuarioTemVisaoTotal"
        label="Listar de todas UGs?"
        v-model="parametrosConsultaAPI.listarDeTodasUGs"
        @change="aplicarListarDeTodasUGs"
      ></v-switch>
    </template>

    <template slot="detalhe">
      <div v-if="entidadeAtual">
        <v-layout row wrap>
          <!-- Nome -->
          <v-flex xs12>
            <v-text-field
              label="Nome"
              v-model="entidadeAtual.nome"
              :rules="[validacao.obrigatorio]"
              required
              counter="255"
              maxlength="255"
            />
          </v-flex>

          <!-- Cargo -->
          <v-flex xs12 lg3>
            <v-autocomplete
              label="Cargo"
              :items="cargos"
              v-model="entidadeAtual.idcargo"
              :rules="[validacao.obrigatorio]"
              item-text="cargo"
              item-value="id"
              :loading="carregandoCargos"
            />
          </v-flex>

          <!-- Órgão -->
          <v-flex xs12 lg9>
            <v-autocomplete
              label="Órgão"
              :items="orgaos"
              v-model="entidadeAtual.idorgao"
              :rules="[validacao.obrigatorio]"
              item-text="orgao"
              item-value="id"
              :loading="carregandoOrgaos"
            />
          </v-flex>

          <!-- E-mail -->
          <v-flex xs12 md6>
            <v-text-field
              label="E-mail"
              v-model="entidadeAtual.email"
              :rules="[validacao.email]"
              required
              counter="100"
              maxlength="100"
            />
          </v-flex>

          <!-- Telefone -->
          <v-flex xs12 md6>
            <v-text-field
              label="Telefone"
              v-model="entidadeAtual.telefone"
              counter="14"
              maxlength="14"
              mask="(##)#########"
            />
          </v-flex>
        </v-layout>
      </div>
    </template>
  </crud>
</template>

<script>
import rotas from '../../../rotas-servico'
import ApiCrud from '../../../components/ApiCrud.vue'
import Validador from '../../../validacao';
import EventBus from '../../../event-bus'
import storageBus from '../../../store/StorageBus'

export default {
  components: {
    'crud': ApiCrud
  },
  data: () => {
    return {
      cabecalhos: [
        {
          text: 'Nome',
          value: 'nome',
          subheader: 'cargotexto',
        },
        {
          text: 'Órgão',
          value: 'orgaotexto',
          subheader: 'siglaorgaotexto',
        },
      ],
      entidadeAtual: null,
      validacao: Validador,

      cargos: [],
      carregandoCargos: false,

      orgaos: [],
      carregandoOrgaos: false,

      urlBase: rotas.demandas.demandante.listar.url(),

      usuarioTemVisaoTotal: false,
      parametrosConsultaAPI: {
        listarDeTodasUGs: false,
      }
    }
  },
  methods: {

    selecionarParaEdicao (item) {
      this.carregarTabelasApoio()
      this.entidadeAtual = item
      // console.log('Item selecionado: ' + item.id)
    },
    salvar () {
      return new Promise((resolve, reject) => {
        try {
          let formData = new FormData()
          formData.append('nome', this.entidadeAtual.nome)
          if (!this.entidadeAtual.email){
            this.entidadeAtual.email = '';
          }
          formData.append('email', this.entidadeAtual.email)

          if (!this.entidadeAtual.telefone) {
            this.entidadeAtual.telefone='';
          }
          formData.append('telefone', this.entidadeAtual.telefone)

          if (this.entidadeAtual.idcargo) {
            formData.append('idcargo', this.entidadeAtual.idcargo)
          }
          if (this.entidadeAtual.idorgao) {
            formData.append('idorgao', this.entidadeAtual.idorgao)
          }

          let url = rotas.demandas.demandante.criar.url();
          if (this.entidadeAtual.id) {
            formData.append('id', this.entidadeAtual.id)
            formData.append('_method', 'PUT')
            url = rotas.demandas.demandante.editar.url({
              id: this.entidadeAtual.id
            })
          }

          this.$http.post(url, formData)
            .then(
              response => {
                EventBus.mensagem('Demandante cadastrado com sucesso!')
                resolve(response)
              },
              error => {
                  reject(new Error(error.body.message))
              }
            )
        } catch (e) {
          reject(e)
        }
      })
    },

    cancelar () {
    },

    novo (item) {
      this.carregarTabelasApoio()
      this.entidadeAtual = {
        id: null,
        cargo: {
        },
        orgao: {
        }
      }
    },

    carregarCargos () {
      this.carregandoCargos = true
      this.cargos = []
      this.$http
        .get(rotas.demandas.cargo.listar.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.cargos.push(element)
            })
          },
          error => {
            console.log(error)
          }
        )
        .finally(() => {
          this.carregandoCargos = false
        })
    },

    carregarOrgaos () {
      this.carregandoOrgaos = true
      this.orgaos = []
      this.$http
        .get(rotas.demandas.orgao.listar.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.orgaos.push(element)
            })
          },
          error => {
            console.log(error)
          }
        )
        .finally(() => {
          this.carregandoOrgaos = false
        })
    },

    carregarTabelasApoio () {
      this.carregarCargos()
      this.carregarOrgaos()
    },

    carregarPermissoesUsuario () {
      this.usuarioTemVisaoTotal = storageBus.usuarioPossuiPermissao("DEMANDA_VISAO_TOTAL")
    },

    aplicarListarDeTodasUGs () {
      this.$refs.crud.loadItems();
    },

  },
  mounted () {
    this.carregarPermissoesUsuario()
  }
}
</script>
