<template>
  <div >
    <div>
      <input v-model="termoPesquisa" class="termoDeBusca-visualizador" placeholder="Informe o local de pesquisa" @keyup.enter="pesquisarVisualizador"> 
        <a @click="pesquisarVisualizador">
            <i style="margin-left: -36px; color: rgb(25, 118, 210);" class="fa fa-search" aria-hidden="true"></i>
          </a>
          <a @click="pesquisarLimpar">
            <i style="font-size: 1rem; margin-left: -20px; color: rgb(25, 118, 210);" class="fa fa-times" aria-hidden="true"></i>
          </a>
    </div>
    <div id="map" ref="map" style=" width: 100%; height: 750px; margin-left: 14px;"></div>
  </div>
</template>

<script>

import L from 'leaflet';
import 'vue2-leaflet-draw-toolbar';
import "leaflet/dist/leaflet.css";
import 'leaflet-draw';

export default {
  name: 'MapEditor',

  data() {
    return {
      mapWidth: '80%',  
      mapHeight: '100%', 
      termoPesquisa: '',
      resposta: null,
      aplicarListarDeTodasUGs:'',
      parametrosConsultaAPI:'',
      usuarioTemVisaoTotal: false,

      mostrarMapa: true,
      showMap: false,
      map: null,

      tileLayer1: null,
      tileLayer2: null,

      drawnItems: null,
      polygonInfo: '',
      editMode: false,
      editControl: null,
      drawControlGeral:null,
      drawControlCreated: null,
      canDrawPolygon: true,
      cabecalhos: [
        {
          text: 'Tipo',
          value: 'tipoprocedimentoexterno'
        },
        {
          text: 'Procedimento',
          value: 'procedimento'
        },
        {
          text: 'Resumo',
          value: 'resumo'
        }
      ],
    };
  },
  props: {
    podeEditar: null,
    coordenadas: [],
    unidadeGestora: null,
    idReferenciado: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initMap();
      this.addGeoJSONToEditLayer();
    });    

   
  },
  methods: {
    novo(){
      this.showMap = true;
      if (this.$refs.map) {
        this.initMap();
        this.addGeoJSONToEditLayer();
      }
    },

    initMap() {
      const drawLocalPtBR = {
        draw: {
          toolbar: {
            actions: {
              title: 'Cancelar desenho',
              text: 'Cancelar',
            },
            finish: {
              title: 'Finalizar desenho',
              text: 'Finalizar',
            },
            undo: {
              title: 'Apagar último ponto desenhado',
              text: 'Apagar último ponto',
            },
            buttons: {
              polyline: 'Desenhar uma linha poligonal',
              polygon: 'Desenhar um polígono',
              rectangle: 'Desenhar um retângulo',
              circle: 'Desenhar um círculo',
              marker: 'Colocar um marcador',
              circlemarker: 'Colocar um marcador de círculo',
            },
          },
          handlers: {
            circle: {
              tooltip: {
                start: 'Clique e arraste para desenhar um círculo.',
              },
              radius: 'Raio',
            },
            circlemarker: {
              tooltip: {
                start: 'Clique no mapa para colocar um marcador de círculo.',
              },
            },
            marker: {
              tooltip: {
                start: 'Clique no mapa para colocar um marcador.',
              },
            },
            polygon: {
              tooltip: {
                start: 'Clique para começar a desenhar um polígono.',
                cont: 'Clique para continuar desenhando o polígono.',
                end: 'Clique no primeiro ponto para concluir o desenho e salva-lo.',
              },
            },
            polyline: {
              error: '<strong>Erro:</strong> Sobreposição não permitida',
              tooltip: {
                start: 'Clique para começar a desenhar uma linha.',
                cont: 'Clique para continuar desenhando a linha.',
                end: 'Clique no último ponto para finalizar a linha.',
              },
            },
            rectangle: {
              tooltip: {
                start: 'Clique e arraste para desenhar um retângulo.',
              },
            },
            simpleshape: {
              tooltip: {
                end: 'Solte o mouse para terminar de desenhar.',
              },
            },
          },
        },
        edit: {
          toolbar: {
            actions: {
              save: {
                title: 'Salvar alterações',
                text: 'Salvar',
              },
              cancel: {
                title: 'Cancelar edição, descartar todas as alterações',
                text: 'Cancelar',
              },
              clearAll: {
                title: 'Remover todos os polígonos',
                text: 'Remover tudo',
              },
            },
            buttons: {
              edit: 'Editar o polígono',
              editDisabled: 'Nenhum polígono para editar',
              remove: 'Remover poligono',
              removeDisabled: 'Nenhum polígono para remover',
            },
          },
          handlers: {
            edit: {
              tooltip: {
                text: 'Arraste os marcadores ou a forma para editar.',
              },
            },
            remove: {
              tooltip: {
                text: 'Clique no polígono para remove-lo.',
              },
            },
          },
        },
      };
      L.drawLocal = drawLocalPtBR;
      let googleRua = L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',  {subdomains: ['mt0', 'mt1', 'mt2', 'mt3'], attribution: 'Google Ruas', maxZoom: 20 })
      this.map = L.map(this.$refs.map, {
        minZoom: 5,
        layers: [googleRua], 
      });

      L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }).addTo(this.map);
      L.control.layers(
        {
          '<img src="img/mapa_osm3.jpg" style="border-radius: 4px; margin-left: 1px; margin-top: 1px;" width="36" height="36" alt="Google Ruas" title="Ruas">': googleRua.addTo(this.map),
          '<img src="img/mapa_esri3.jpg"  style="border-radius: 4px;"  width="36" height="36" title="Satélite" alt="Google">': L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {attribution:'Google Satélite', maxZoom: 20, subdomains: ['mt0', 'mt1', 'mt2', 'mt3']}),
          '<img src="img/mapa_hibrido.jpg"  style="border-radius: 4px;"  width="36" height="36" title="Híbrido" alt="Google">': L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {attribution:'Google Híbrido', maxZoom: 20, subdomains: ['mt0', 'mt1', 'mt2', 'mt3']}),
        },
        {  }, 
        { position: 'bottomright', collapsed: false }).addTo(this.map);
        const layersControlContainer = document.querySelector('.leaflet-control-layers');
    if (layersControlContainer) {
      layersControlContainer.classList.add('classe-personalizada-imovel');
    } 
      let unidade = this.unidadeGestora
      const WatermarkControl = L.Control.extend({
        onAdd: function(map) {
          const watermark = L.DomUtil.create('div', 'watermark-control');
          watermark.innerHTML = unidade;
          return watermark;
        }
      });
      var googleLogoControl = L.control({ position: 'bottomleft' });
      googleLogoControl.onAdd = function (map) {
        var div = L.DomUtil.create('div', 'google-logo-control');
        div.innerHTML = '<img src="img/logo_google_2.png" alt="Google Logo" style="width: 4rem; margin-bottom: -10px; margin-left: 10px;">';
        return div;
      };
      googleLogoControl.addTo(this.map);

      const watermarkControl = new WatermarkControl({ position: 'topright' });
      watermarkControl.addTo(this.map);

      this.drawnItems = new L.FeatureGroup().addTo(this.map);
      
      if (this.podeEditar){
      this.drawControlGeral = new L.Control.Draw({
        edit: {
          featureGroup: this.drawnItems,
        },
        draw: {
          rectangle: false, 
          polyline: false, 
          marker: false,
          circle: false,
          circlemarker: false,
          polygon:{
              allowIntersection: false, 
              drawError: {
                color: 'red',
                timeout: 2000,
                message: '<strong>Não permitido<strong>',
              },
              showArea: true,
              metric: true, //m2
              repeatMode: false,
              shapeOptions: {
                polylineID: false,
                color: '#3388ff',
                fillColor: '#3388ff',
                weight: 3,
                fillOpacity: 0.2,
                lineCap: 'round',
                lineJoin: 'bevel',
                dashArray: '',
                opacity: 1,
              },
            },
        },
      });
      this.drawControlCreated = new L.Control.Draw({
          edit: {
            featureGroup: this.drawnItems,
          },
          draw: false,
          rectangle: false, 
          polyline: false, 
          marker: false,
          circle: false,
          circlemarker: false
        });
      
      this.map.addControl(this.drawControlCreated);
      this.map.on('zoomend', this.atualizarZoom);
      this.atualizarZoom();
      this.map.on('draw:created', (e) => {
        const layer = e.layer;
        this.drawnItems.addLayer(layer);
        this.canDrawPolygon = false
        let result = this.drawnItems.toGeoJSON()
        let indexCamada = result.features.length - 1

        let novasCoordenadas = result.features[indexCamada].geometry.coordinates
        this.$emit('atualizarCoordenadas', novasCoordenadas);
        this.map.removeControl(this.drawControlGeral);
        this.map.addControl(this.drawControlCreated);
      });

      this.map.on('draw:edited', (e) => {
        const layers = e.layers;
        let result = this.drawnItems.toGeoJSON()
        let indexCamada = result.features.length - 1
        let novasCoordenadas = result.features[indexCamada].geometry.coordinates
        this.$emit('atualizarCoordenadas', novasCoordenadas);
        layers.eachLayer((layer) => {
        });
      });
      this.map.on('draw:deleted', (e) => {
        const layers = e.layers;
        layers.eachLayer((layer) => {
          let result = this.drawnItems.toGeoJSON()
        })
        let result = this.drawnItems.toGeoJSON()
        let novasCoordenadas = []
        this.$emit('atualizarCoordenadas', novasCoordenadas);
        this.map.removeControl(this.drawControlCreated);
        this.map.addControl(this.drawControlGeral);
      });
      const customButton = L.control({ position: 'topleft' });
    }
    },
   
    addGeoJSONToEditLayer() {
      const estados = [
        { estado: 'SPU-AC', lat: -8.77, lon: -70.55, cidade: 'Rio Branco', latDecimal: -9.974990, lonDecimal: -67.8243 },
        { estado: 'SPU-AL', lat: -9.62, lon: -36.82, cidade: 'Maceió', latDecimal: -9.665990, lonDecimal: -35.7350 },
        { estado: 'SPU-AM', lat: -3.47, lon: -65.10, cidade: 'Manaus', latDecimal: -3.118660, lonDecimal: -60.0212 },
        { estado: 'SPU-AP', lat: 1.41, lon: -51.77, cidade: 'Macapá', latDecimal: 0.034934, lonDecimal: -51.0694 },
        { estado: 'SPU-BA', lat: -13.29, lon: -41.71, cidade: 'Salvador', latDecimal: -12.971800, lonDecimal: -38.5011 },
        { estado: 'SPU-CE', lat: -5.20, lon: -39.53, cidade: 'Fortaleza', latDecimal: -3.716640, lonDecimal: -38.5423 },
        { estado: 'SPU-DF', lat: -15.83, lon: -47.86, cidade: 'Brasília', latDecimal: -15.779500, lonDecimal: -47.9297 },
        { estado: 'DEMIN', lat: -15.83, lon: -47.86, cidade: 'Brasília', latDecimal: -15.779500, lonDecimal: -47.9297 },
        { estado: 'SPU', lat: -15.83, lon: -47.86, cidade: 'Brasília', latDecimal: -15.779500, lonDecimal: -47.9297 },
        { estado: 'SPU-ES', lat: -19.19, lon: -40.34, cidade: 'Vitória', latDecimal: -20.315500, lonDecimal: -40.3128 },
        { estado: 'SPU-GO', lat: -15.98, lon: -49.86, cidade: 'Goiânia', latDecimal: -16.686400, lonDecimal: -49.2643 },
        { estado: 'SPU-MA', lat: -5.42, lon: -45.44, cidade: 'São Luís', latDecimal: -2.538740, lonDecimal: -44.2825 },
        { estado: 'SPU-MG', lat: -18.10, lon: -44.38, cidade: 'Belo Horizonte', latDecimal: -19.910200, lonDecimal: -43.9266 },
        { estado: 'SPU-MS', lat: -20.51, lon: -54.54, cidade: 'Campo Grande', latDecimal: -20.448600, lonDecimal: -54.6295 },
        { estado: 'SPU-MT', lat: -12.64, lon: -55.42, cidade: 'Cuiabá', latDecimal: -15.601000, lonDecimal: -56.0974 },
        { estado: 'SPU-PA', lat: -3.79, lon: -52.48, cidade: 'Belém', latDecimal: -1.455400, lonDecimal: -48.4898 },
        { estado: 'SPU-PB', lat: -7.28, lon: -36.72, cidade: 'João Pessoa', latDecimal: -7.115090, lonDecimal: -34.8641 },
        { estado: 'SPU-PE', lat: -8.38, lon: -37.86, cidade: 'Recife', latDecimal: -8.046660, lonDecimal: -34.8771 },
        { estado: 'SPU-PI', lat: -6.60, lon: -42.28, cidade: 'Teresina', latDecimal: -5.091940, lonDecimal: -42.8034 },
        { estado: 'SPU-PR', lat: -24.89, lon: -51.55, cidade: 'Curitiba', latDecimal: -25.419500, lonDecimal: -49.2646 },
        { estado: 'SPU-RJ', lat: -22.25, lon: -42.66, cidade: 'Rio de Janeiro', latDecimal: -22.912900, lonDecimal: -43.2003 },
        { estado: 'SPU-RN', lat: -5.81, lon: -36.59, cidade: 'Natal', latDecimal: -5.793570, lonDecimal: -35.1986 },
        { estado: 'SPU-RO', lat: -10.83, lon: -63.34, cidade: 'Porto Velho', latDecimal: -8.760770, lonDecimal: -63.8999 },
        { estado: 'SPU-RR', lat: 1.99, lon: -61.33, cidade: 'Boa Vista', latDecimal: 2.823840, lonDecimal: -60.6753 },
        { estado: 'SPU-RS', lat: -30.17, lon: -53.50, cidade: 'Porto Alegre', latDecimal: -30.031800, lonDecimal: -51.2065 },
        { estado: 'SPU-SC', lat: -27.45, lon: -50.95, cidade: 'Florianópolis', latDecimal: -27.594500, lonDecimal: -48.5477 },
        { estado: 'SPU-SE', lat: -10.57, lon: -37.45, cidade: 'Aracaju', latDecimal: -10.909100, lonDecimal: -37.0677 },
        { estado: 'SPU-SP', lat: -22.19, lon: -48.79, cidade: 'São Paulo', latDecimal: -23.532900, lonDecimal: -46.6395 },
        { estado: 'SPU-SP-EDESC', lat: -22.19, lon: -48.79, cidade: 'São Paulo', latDecimal: -23.532900, lonDecimal: -46.6395 },
        { estado: 'SPU-TO', lat: -9.46, lon: -48.26, cidade: 'Palmas', latDecimal: -10.240000, lonDecimal: -48.3558 }
];

      var geojsonPolygon =[{"type":"FeatureCollection","features":[{"type":"Feature","properties":{},"geometry":{"type":"Polygon","coordinates":[[]]}}]}];
      if (this.coordenadas && this.coordenadas.length > 0) {
        geojsonPolygon[0].features[0].geometry.coordinates = this.coordenadas;
        const layer = L.geoJSON(geojsonPolygon, {
          onEachFeature: (feature, layer) => {
            this.drawnItems.addLayer(layer);
            this.map.fitBounds(layer.getBounds()); 
          },
        });
        layer.addTo(this.map); 
        this.map.setZoom(19)

      } else {
        this.canDrawPolygon = true;
        this.map.removeControl(this.drawControlCreated);
        this.map.addControl(this.drawControlGeral);

        let coordenadasPadrao = [-10.091970831876239, -64.12566469552895];
        let latitude = -10.091970831876239;
        let longitude = -64.12566469552895;

        for (const estado of estados) {
          if (estado.estado === this.unidadeGestora) {
            latitude = estado.latDecimal;
            longitude = estado.lonDecimal;
            coordenadasPadrao=[latitude, longitude]
            break; 
          }
        }

        const layer = L.geoJSON(geojsonPolygon, {
          onEachFeature: (feature, layer) => {
            this.drawnItems.addLayer(layer);
          },
        }); 
        layer.addTo(this.map);  
        if (this.unidadeGestora == "SPU" || this.unidadeGestora == "DEMIN"){
          this.map.setView(coordenadasPadrao, 5)  
        }
        else{
          this.map.setView(coordenadasPadrao, 8)
        }
      }        
    },      

    savePolygon() {
      this.drawnItems.eachLayer((layer) => {
        const polygon = layer.toGeoJSON();
        polygon.properties = {
          info: this.polygonInfo,
        };
      });
    },
    atualizarZoom(){
      //console.log("zoom ", this.map.getZoom())
    },
    alterarVisibilidadeMapa(visibilidade) {
      this.mostrarMapa = visibilidade;
    },
    pesquisarVisualizador() {
      let termoFormatado = this.termoPesquisa.replace(/ /g, '+');
      const verCEP = termoFormatado.replace(/[^0-9]/g, '')
      if ((termoFormatado.length < 11) && (verCEP.length == 8) ){
        var Httpreq = new XMLHttpRequest(); 
        //console.log('entrei ', verCEP)
        const urlCEP = `https://viacep.com.br/ws/${verCEP}/json/`
        Httpreq.open("GET",urlCEP,true);
        Httpreq.onload = () => { 
          if (Httpreq.status === 200) {
            const respostaTexto = Httpreq.responseText;
            const objetoJSON = JSON.parse(respostaTexto);
            if (objetoJSON.erro){
              alert('O CEP informado não existe: '+ this.termoPesquisa)
            }
            else{
              const logradouro = objetoJSON.logradouro;
              const complemento = objetoJSON.complemento;
              const bairro = objetoJSON.bairro;
              const localidade = objetoJSON.localidade;
              const uf = objetoJSON.uf;
              const respostaCEP = (logradouro +' '+' '+ bairro +' '+ localidade +' '+ uf).replace(/ /g, '+');
              termoFormatado = respostaCEP
              this.pesquisaNominatim(termoFormatado)
            }
          } else {
            console.error('Ocorreu um erro na requisição.');
          }
        };          
        Httpreq.send();
      }
      else{
        this.pesquisaNominatim(termoFormatado)
      }
    },
    pesquisarLimpar(){
      if (this.marcador!=null) {
        this.termoPesquisa = '';
        this.map.removeLayer(this.marcador);
      }
    },
    async pesquisaNominatim(termoFormatado){
      if (this.marcador) {
        this.map.removeLayer(this.marcador);
      }
      const codigoPais = 'BR';
      const url = `https://nominatim.openstreetmap.org/search?format=json&q=${termoFormatado}&countrycodes=${codigoPais}`;
      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          if (data.length > 0) {
            const primeiroResultado = data[0];
            const latitude = parseFloat(primeiroResultado.lat);
            const longitude = parseFloat(primeiroResultado.lon);
            const novaCoordenada = L.latLng(latitude, longitude);


            this.marcador = L.marker(novaCoordenada).addTo(this.map);
            this.marcador.bindPopup("Atenção! Este ponto pode não representar o local pesquisado.").openPopup();
            this.marcador.setIcon(L.icon({ iconUrl: 'img/icon-mark-location-where.png', iconSize: [48, 48] }));
            this.marcador.dragging.enable();
            this.marcador.on('dragend', (e) => {
                const novaCoordenada = e.target.getLatLng();
            });
            this.map.setView(novaCoordenada, 19);
            this.coordenadaAtual = novaCoordenada;
          } else {
            alert('Nenhum resultado encontrado. Por favor, refine sua pesquisa.' + termoFormatado)
            this.resposta = 'Nenhum resultado encontrado';
          }
        } else {
          this.resposta = 'Erro ao fazer a solicitação';
        }
      } catch (erro) {
        console.error('Erro ao fazer a solicitação:', erro);
        this.resposta = 'Erro ao fazer a solicitação';
      }  
    }
  },
}
</script>
<style>
.custom-button {
  background-color: white;
  border: 2px solid gray;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
}
div#map1 {border: 2px solid #8080800d;}

div#map2 {border: 2px solid #8080800d;}
.leaflet-attribution-flag{
  display: none !important;
}

.watermark {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
}
.leaflet-control-attribution {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
}
.watermark-control {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
}
.leaflet-top .leaflet-control {
  left: 6px;
  top: 6px;
}
.leaflet-bottom .leaflet-control {
  bottom: 6px;
  right: 5px;
}
.classe-personalizada-imovel {
  background-color: rgb(238, 246, 248);
  width: 133px !important;
  height: 46px;
  padding-top: 2px;
  padding-bottom: 0;
  padding-left: 1px;
  padding-right: 3px;
}
.termoDeBusca-visualizador{
  min-width: 50%;
  max-width: 700px;
  border: solid 2px #d5d2d2;
  border-radius: 4px;
  margin-bottom: 5px;
  padding-left: 5px;
  margin-top: 20px;
  margin-left: 15px;
  background-color: #f9f7f7;
}
.leaflet-draw-actions > li > a {
  color: rgb(249, 246, 246); 
}
#map {
  width: 100%;
  height: 100vh; 
  z-index: 1; 
}
.v-card__text{
width: 99%;
  padding-top: 0;
}
</style>
