<template>
  <v-card flat outlined color="grey lighten-3">
    <v-card-text>
      <v-container fluid class="ma-2">
        <v-row>
          <v-col sm="5" md="4" lg="6" xl="4" class="d-flex align-stretch">
            <v-container>
              <v-row v-if="usuarioTemVisaoTotal">
                <v-col>
                  <card-selecao-unidade-gestora
                    :callback-unidade-gestora-alterada="aplicarUnidadeGestora"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <card-selecao-periodo
                    :data-de="dataDe"
                    :data-ate="dataAte"
                    :callbackPeriodoAlterado="aplicarPeriodoRelatorios"
                  />
                </v-col>
              </v-row>
              <v-divider />
            </v-container>
          </v-col>
          <v-col sm="7" md="8" lg="6" xl="4" class="d-flex align-stretch">
            <card-demandas-resumo-periodo
              ref="graficoResumoDemandas"
              :unidade-gestora="unidadeGestora"
            />
          </v-col>
          <v-col md="6" xl="4" class="d-flex align-stretch">
            <card-grafico-demandas-por-natureza-orgao
              ref="graficoDemandasPorNatureza"
              :unidade-gestora="unidadeGestora"
            />
          </v-col>
          <v-col md="6" class="d-flex align-stretch">
            <card-distribuicao-demandas
              ref="graficoDistribuicaoDemandas"
              :unidade-gestora="unidadeGestora"
            />
          </v-col>
          <v-col lg="6" class="d-flex align-stretch">
            <card-origem-demandas
              ref="graficoOrigemDemandas"
              :unidade-gestora="unidadeGestora"
            />
          </v-col>
          <v-col class="d-flex align-stretch">
            <card-entrada-saida-demandas
              ref="graficoEntradaSaidaDemandas"
              :unidade-gestora="unidadeGestora"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import CardSelecaoPeriodo from "./../../../../components/CardSelecaoPeriodo.vue";
import CardSelecaoUnidadeGestora from "./../CardSelecaoUnidadeGestora.vue";
import CardDemandasResumoPeriodo from "./CardDemandasResumoPeriodo.vue";
import CardGraficoDemandasPorNaturezaOrgao from "./CardGraficoDemandasPorNaturezaOrgao.vue";
import CardDistribuicaoDemandas from "./CardDistribuicaoDemandas.vue";
import CardOrigemDemandas from "./CardOrigemDemandas.vue";
import CardEntradaSaidaDemandas from "./CardEntradaSaidaDemandas.vue";
import storageBus from "./../../../../store/StorageBus";

export default {
  components: {
    CardDemandasResumoPeriodo,
    CardSelecaoPeriodo,
    CardSelecaoUnidadeGestora,
    CardGraficoDemandasPorNaturezaOrgao,
    CardDistribuicaoDemandas,
    CardOrigemDemandas,
    CardEntradaSaidaDemandas,
  },
  data() {
    return {
      usuarioTemVisaoTotal: false,
      unidadeGestora: null,
      dataDe: null,
      dataAte: null,
    };
  },

  methods: {
    aplicarUnidadeGestora(unidadeGestora) {
      this.unidadeGestora = unidadeGestora;
    },

    aplicarPeriodoRelatorios(dataDe, dataAte) {
      // estudar e descobrir o pq dessa bizarrice de add 2 days
      this.dataDe = new Date(moment(dataDe).add(2, "day").format("YYYY-MM-DD"));
      this.dataAte = new Date(moment(dataAte).add(2, "day").format("YYYY-MM-DD"));
      this.carregarRelatorios();
    },

    carregarRelatorios() {
      this.$refs.graficoResumoDemandas.carregarRelatorio(
        this.dataDe,
        this.dataAte
      );
      this.$refs.graficoDemandasPorNatureza.carregarRelatorio(
        this.dataDe,
        this.dataAte
      );
      this.$refs.graficoDistribuicaoDemandas.carregarRelatorio(
        this.dataDe,
        this.dataAte
      );
      this.$refs.graficoOrigemDemandas.carregarRelatorio(
        this.dataDe,
        this.dataAte
      );
      this.$refs.graficoEntradaSaidaDemandas.carregarRelatorio();
    },
  },

  async mounted() {
    this.usuarioTemVisaoTotal = storageBus.usuarioPossuiPermissao(
      "DEMANDA_VISAO_TOTAL"
    );
    this.aplicarUnidadeGestora(storageBus.getUnidadeGestora())
    this.aplicarPeriodoRelatorios(
      new Date(moment().subtract(1, "month").format("YYYY-MM-DD")),
      new Date(moment().format("YYYY-MM-DD"))
    );
  },
};
</script>

<style>
</style>