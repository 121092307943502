import GestaoPraias from './components/GestaoPraias.vue'
import PlanoGestaoIntegrada from './components/PlanoGestaoIntegrada.vue'
export default {
    rotas : {

        frontend : [
            {
                path: '/gestao-praias',
                name: 'Gestao_Praias',
                component: GestaoPraias,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [{
                            text: 'Início',
                            href: '/#/'
                        },
                        {
                            text: 'Gestão de Praias',
                        }
                    ]
                },
            },
            {
                path: '/gestao-praias/pgi',
                name: 'Gestao_Praias_PGI',
                component: PlanoGestaoIntegrada,
                meta: {
                    requiresAuth: true,
                    breadcrumb: [
                        {
                            text: 'Início',
                            href: '/#/'
                        },
                        {
                            text: 'Gestão de Praias',
                        },
                        {
                            text: 'Plano de Gestão Integrada',
                        }
                    ]
                },
            }

        ]

    }
}