<template>
  <v-card outlined>
    <v-card-title dense>
      <span class="subtitle-2 font-weight-light">{{ rotulo }}</span>
      <v-spacer />
      <v-btn-toggle v-model="modoVisao" outlined dense>
        <v-btn dense light x-small>
          <span class="font-weight-light">Todos</span>
          <span class="font-weight-light" v-if="itens.length > 0">
            ({{ itens.length }})</span>
        </v-btn>
        <v-btn dense light x-small>
          <span class="font-weight-light">Marcados </span>
          <span class="font-weight-light" v-if="selecao.length > 0">
            ({{ selecao.length }})</span>
        </v-btn>
        <v-btn dense light x-small>
          <span class="font-weight-light">Não Marcados</span>
          <span class="font-weight-light" v-if="(itens.length - selecao.length) > 0">
            ({{ itens.length - selecao.length }})</span>
        </v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-card-text>
      <v-text-field label="Filtro" dense v-model="filtro"></v-text-field>

      <v-list flat dense nav :two-line="comSubtitulo" max-height="300px" style="display: block; overflow: auto;">
        <v-list-item-group>
          <v-list-item
            v-for="item in itensFiltrados"
            :key="item.index"
            @click="clicouItem(item)"
          >
            <v-list-item-icon>
              <v-icon v-if="item.marcado">done</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                item[propriedadeTitulo]
              }}</v-list-item-title>
              <v-list-item-subtitle v-if="comSubtitulo">{{
                item[propriedadeSubtitulo]
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
const MODO_VISAO_MARCADOS = 1;
const MODO_VISAO_NAO_MARCADOS = 2;
export default {
  props: {
    rotulo: {
      type: String,
      required: true,
    },
    propriedadeValor: {
      type: String,
      default: "valor",
    },
    propriedadeMarcado: {
      type: String,
      default: "marcado",
    },
    propriedadeTitulo: {
      type: String,
      default: "titulo",
    },
    propriedadeSubtitulo: {
      type: String,
      default: "subtitulo",
    },
    comSubtitulo: {
      type: Boolean,
      default: false,
    },
    itens: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selecao: [],
      filtro: null,
      listaItens: [],
      modoVisao: 0,
    };
  },

  methods: {
    clicouItem(item) {
      // console.log("CaixaSelecaoFiltravel.alterarMarcacaoItem", item);
      item[this.propriedadeMarcado] = !item[this.propriedadeMarcado];
      this.atualizarSelecao();
    },

    atualizarSelecao() {
      this.selecao = this.listaItens.filter((item) => {
        return item[this.propriedadeMarcado];
      });
      this.$emit("change", this.selecao);
    },

    clonarItens() {
      this.listaItens = [];
      this.itens.forEach((item) => {
        let itemClonado = {};
        itemClonado[this.propriedadeMarcado] = item[this.propriedadeMarcado] ? true : false;
        itemClonado[this.propriedadeValor] = item[this.propriedadeValor];
        itemClonado[this.propriedadeTitulo] = item[this.propriedadeTitulo];
        itemClonado[this.propriedadeSubtitulo] =
          item[this.propriedadeSubtitulo];
        this.listaItens.push(itemClonado);
      });
    },

    compararItens(a, b) {
      const valorA = a[this.propriedadeTitulo].toUpperCase()
      const valorB = b[this.propriedadeTitulo].toUpperCase()
      let comporacao = 0;
      if (valorA > valorB) {
        comporacao = 1;
      } else if (valorA < valorB) {
        comporacao = -1;
      }
      return comporacao;
    }
  },

  computed: {
    itensFiltrados() {
      return this.listaItens.filter(
        function(item) {
          let passouFiltro =
            this.filtro == null ||
            item[this.propriedadeTitulo].includes(this.filtro) ||
            item[this.propriedadeSubtitulo].includes(this.filtro);

          let passouModoVisao = true;
          switch (this.modoVisao) {
            case MODO_VISAO_MARCADOS:
              passouModoVisao = item[this.propriedadeMarcado];
              break;

            case MODO_VISAO_NAO_MARCADOS:
              passouModoVisao = !item[this.propriedadeMarcado];
              break;

            default:
              passouModoVisao = true;
              break;
          }

          return passouFiltro && passouModoVisao;
        }.bind(this)
      );
    },
  },

  watch: {
    itens: function() {
      this.clonarItens()
      this.listaItens.sort(this.compararItens)
      this.atualizarSelecao()
    }
  },

  mounted() {
    this.clonarItens();
  },
};
</script>

<style lang="css" scoped>

.v-list--two-line .v-list-item .v-list-item__icon, .v-list--dense .v-list-item .v-list-item__icon {
  margin: 0px 8px;
  padding: 0px;
}
.v-list--dense .v-list-item .v-list-item__content {
  border-bottom: 1px solid #CECECE;
  margin: 0px;
  padding: 0px 0px 10px;
}
.v-list--two-line .v-list-item {
  min-height: 20px;
}
</style>
