var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(this.carregando)?_c('v-skeleton-loader',{attrs:{"type":"card-heading, button"}}):_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.possuiAtendimentoAberto ? "Você está em atendimento" : "Iniciar Atendimento")+" ")]),_c('v-card-text',[(_vm.possuiAtendimentoAberto)?_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"green","large":"","rounded":"","light":"","disabled":!_vm.podeAtenderPresencial},on:{"click":function($event){return _vm.continuarAtendimento(_vm.dadosAtendente.atendimento_aberto.id)}}},on),[_c('v-icon',[_vm._v("play_arrow")]),_c('span',{staticClass:"pa-2"},[_vm._v(" Continuar ")])],1)]}}],null,false,3051160587)},[_vm._v(" Continuar atendimento ")])],1)],1):_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary darken-1","large":"","rounded":"","light":"","disabled":!_vm.podeAtenderPresencial},on:{"click":function($event){return _vm.novo(_vm.idTipoPresencial)}}},on),[_c('v-icon',[_vm._v("people_outline")]),_c('span',{staticClass:"pa-2"},[_vm._v(" Presencial ")])],1)]}}])},[_vm._v(" Iniciar atendimento presencial ")])],1),_c('v-col',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","large":"","rounded":"","disabled":!_vm.podeAtenderTelefonico},on:{"click":function($event){return _vm.novo(_vm.idTipoTelefonico)}}},on),[_c('v-icon',[_vm._v("local_phone")]),_c('span',{staticClass:"pa-2"},[_vm._v(" Telefônico ")])],1)]}}])},[_vm._v(" Iniciar atendimento telefônico ")])],1),_c('v-col',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary lighten-1","large":"","rounded":"","disabled":!_vm.podeAtenderEmail},on:{"click":function($event){return _vm.novo(_vm.idTipoEmail)}}},on),[_c('v-icon',[_vm._v("mail_outline")]),_c('span',{staticClass:"pa-2"},[_vm._v(" Email ")])],1)]}}])},[_vm._v(" Iniciar atendimento de e-mail ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }