import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './../components/Home'
import Login from './../components/Login'
import OAuthGovBR from './../components/auth/OAuthGovBR'
import RecuperarSenha from './../components/RecuperarSenha'
import TrocarSenha from './../components/TrocarSenha'
import TrocarEmail from './../components/TrocarEmail'

import Usuario from './../components/permissoes/Usuario'
import Permissao from './../components/permissoes/Permissao'
import Organograma from './../components/permissoes/Organograma'

import Correspondencia from './../components/correspondencia/Correspondencia'
import Patrimonio from './../components/patrimonio/Patrimonio'
import Locais from     './../components/patrimonio/Localizacao.vue'

import Destinacoes from './../components/destinacoes/Destinacoes'
import VisualizadorDest from './../components/destinacoes/Visualizador'
import Migrado from './../components/destinacoes/Migrado'
// GEO
import GeoVisualizador from './../components/geo/GeoVisualizador'
import GeoCamada from './../components/geo/GeoCamada'




// Laboratório
// import Laboratorio from './../components/Laboratorio'

import store from './../store'

Vue.use(VueRouter)

const routes = [
  {
      path: '/',
      name: 'Inicio',
      component: Home,
      meta: {
          breadcrumb: [{
              text: 'Início'
          }]
      }
  },
  {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
          breadcrumb: [{
                  text: 'Início',
                  href: '/#/'
              },
              {
                  text: 'Login'
              },
          ]
      }
  },
  {
      path: '/oauth/govbr/token/:state/:access_token',
      name: 'OAuthGovBRToken',
      component: OAuthGovBR,
      meta: {
          breadcrumb: [{
                  text: 'Início',
                  href: '/#/'
              },
              {
                  text: 'Entrar com GOV.BR'
              },
          ]
      }
  },
  {
      path: '/recuperar-senha',
      name: 'RecuperarSenha',
      component: RecuperarSenha,
      meta: {
          breadcrumb: [{
                  text: 'Início',
                  href: '/#/'
              },
              {
                  text: 'Recuperar Senha'
              },
          ]
      }
  },
  {
      path: '/trocar-senha',
      name: 'trocar-senha',
      component: TrocarSenha,
      meta: {
          breadcrumb: [{
                  text: 'Início',
                  href: '/#/'
              },
              {
                  text: 'Recuperar Senha'
              },
          ]
      }
  },
  {
    path: '/trocar-email',
    name: 'trocar-email',
    component: TrocarEmail,
    meta: {
        breadcrumb: [{
                text: 'Início',
                href: '/#/'
            },
            {
                text: 'Trocar Email'
            },
        ]
    }
},
  // DEMARCACAO -----------------------------------
  /*
  {
      path: '/demarcacao',
      name: 'Demarcacao',
      component: Demarcacao,
      meta: {
          requiresAuth: true,
          breadcrumb: [{
              text: 'Início',
              href: '/#/'
          },
          {
              text: 'Demarcação'
          },
      ]
  }
  },
  {
      path: '/demarcacao/:id',
      name: 'DemarcacaoEditor',
      component: Demarcacao,
      meta: {
          requiresAuth: true,
          breadcrumb: [{
              text: 'Início',
              href: '/#/'
              },
              {
                  text: 'Demarcação'
              }]
          }
  },
  */
  {
      path: '/geo/visualizador',
      name: 'GeoVisualizador',
      component: GeoVisualizador,
      meta: {
          requiresAuth: true,
          breadcrumb: [{
                  text: 'Início',
                  href: '/#/'
              },
              {
                  text: 'Geo Informação',
              },
              {
                  text: 'Visualizador',
              }
          ]
      }
  },
  
  {
    path: '/destinacoes/destinacoes',
    name: 'Destinacoes',
    component: Migrado,
    meta: {
        requiresAuth: true,
        breadcrumb: [{
                text: 'Início',
                href: '/#/'
            },
            {
                text: 'Programa de Democratização', 
            },
            {
              text: 'Imóveis',
            }
    ]
    }
 }, 
 {
    path: '/destinacoes/destinacoes/:id',
    name: 'DestinacoesEditor',
    component: Migrado,
    meta: {
        requiresAuth: true,
        breadcrumb: [{
            text: 'Início',
            href: '/#/'
        },
        {
            text: 'Destinações',
            href: '/#/destinacoes-editor'
        },
        {
            text: 'Imóveis'
        },
        ]
    }
},
 {
    path: '/destinacoes/visualizador',
    name: 'VisualizadorDest',
    component: Migrado,
    meta: {
        requiresAuth: true,
        breadcrumb: [{
                text: 'Início',
                href: '/#/'
            },
            {
                text: 'Programa de Democratização',
            },
            {
              text: 'Visualização Imóveis',
            }
    ]
    }
 }, 
  
  {
      path: '/geo/camadas',
      name: 'GeoCamadas',
      component: GeoCamada,
      meta: {
          requiresAuth: true,
          breadcrumb: [{
                  text: 'Início',
                  href: '/#/'
              },
              {
                  text: 'Geo Informação',
              },
              {
                text: 'Camadas',
              }
      ]
      }
  },
  {
      path: '/usuario',
      name: 'Usuário',
      component: Usuario,
      meta: {
          requiresAuth: true,
          breadcrumb: [{
                  text: 'Início',
                  href: '/#/'
              },
              {
                  text: 'Usuário',
              }
          ]
      }
  },

  {
      path: '/permissao',
      name: 'Permissao',
      component: Permissao,
      meta: {
          requiresAuth: true,
          breadcrumb: [{
                  text: 'Início',
                  href: '/#/'
              },
              {
                  text: 'Permissão',
              }
          ]
      }
  },
  
  {
      path: '/correspondencia',
      name: 'Correspondência',
      component: Correspondencia,
      meta: {
          requiresAuth: true,
          breadcrumb: [{
                  text: 'Início',
                  href: '/#/'
              },
              {
                  text: 'Correspondência',
              }
          ]
      }
  },
  {
    path: '/destinacoes',
    name: 'Destinações',
    component: Destinacoes,
    meta: {
        requiresAuth: true,
        breadcrumb: [{
            text: 'Início',
            href: '/#/'
        },
        {
            text: 'Programa de Democratização',
        }]
    }
},

  {
      path: '/patrimonio',
      name: 'Patrimônio',
      component: Patrimonio,
      meta: {
          requiresAuth: true,
          breadcrumb: [{
              text: 'Início',
              href: '/#/'
          },
          {
              text: 'Bens Patrimoniais',
          }]
      }
  },
  {
      path: '/locais',
      name: 'Local',
      component: Locais,
      meta: {
          requiresAuth: true,
          breadcrumb: [{
                  text: 'Início',
                  href: '/#/'
              },
              {
                  text: 'Locais',
              }
          ]
      }
  },
  
  {
      path: '/organograma',
      name: 'Organograma',
      component: Organograma,
      meta: {
          requiresAuth: true,
          breadcrumb: [{
                  text: 'Início',
                  href: '/#/'
              },
              {
                  text: 'Divisões/Organograma',
              }
          ]
      }
  },
]

const router = new VueRouter({
  routes
})

import ModuloDemandas from './../modules/demandas'
ModuloDemandas.rotas.frontend.map(rota => {
    router.addRoute(rota)
})

import ModuloGestaoPraias from './../modules/gestao-praias'
ModuloGestaoPraias.rotas.frontend.map(rota => {
    router.addRoute(rota)
})

import ModuloAtendimento from './../modules/atendimento'
ModuloAtendimento.rotas.frontend.map(rota => {
    router.addRoute(rota)
})

router.beforeEach((to, from, next) => {
  let tokenArmazenado = store.getters['auth/token']
  if (to.matched.some(record => record.meta.requiresAuth)) {
      if (tokenArmazenado == null) {
          next({
              path: '/login',
              query: {
                  redirect: to.fullPath
              }
          })
          return
      }
  } 
  next()
})

export default router
