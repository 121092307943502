<template>
  <v-card flat color="grey lighten-3">
    <v-card-title> Unidade Gestora </v-card-title>
    <v-card-text>
      <v-autocomplete
        label="Unidade Gestora"
        :items="unidadesGestoras"
        :loading="carregandoUnidadesGestoras"
        @change="mudouUnidadeGestora"
        v-model="idUnidadeGestoraSelecionada"
        item-text="nome"
        item-value="id"
        :clearable="true"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import rotas from './../../../rotas-servico'
export default {
  props: {
    callbackUnidadeGestoraAlterada: {
      type: Function
    }
  },
  data() {
    return {
      idUnidadeGestoraSelecionada: null,
      unidadesGestoras: [],
      carregandoUnidadesGestoras: false,
    };
  },
  methods: {
    carregarUnidadesGestoras() {
      this.idUnidadeGestoraSelecionada = null
      this.unidadesGestoras = [];
      this.carregandoUnidadesGestoras = true;
      this.$http
        .get(rotas.controleAcesso.organograma.unidadeGestora.url())
        .then(
          (response) => {
            this.unidadesGestoras = response.body;
          },
          (error) => {
            console.log(error);
          }
        )
        .finally(() => {
          this.carregandoUnidadesGestoras = false;
        });
    },
    mudouUnidadeGestora() {
        let unidadeGestoraSelecionada = this.unidadesGestoras.find(unidadeGestora => {
          return unidadeGestora.id == this.idUnidadeGestoraSelecionada
        }, this)
        this.callbackUnidadeGestoraAlterada(unidadeGestoraSelecionada)
    }
  },
  mounted() {
      this.carregarUnidadesGestoras()
  }
};
</script>

<style>
</style>