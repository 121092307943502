<template>
    <v-card :loading="carregando" flat width="100%">
        <v-card-title v-if="titulo">
            <v-icon v-if="iconTitulo" :color="iconColorTitulo">{{ iconTitulo }}</v-icon>
            {{ titulo }}
        </v-card-title>
        <v-card-text>
            <v-skeleton-loader v-if="carregando"
                type="image"
            ></v-skeleton-loader>
            <p class="text-md-center" v-else>
                <slot></slot>
            </p>
        </v-card-text>
    </v-card>
</template>

<script>

export default {
    props: {
        titulo: {
            type: String,
            default: null
        },
        carregando: {
            type: Boolean,
            default: false
        },
        iconTitulo: {
            type: String,
            default: null
        },
        iconColorTitulo: {
            type: String,
            default: "yellow"
        },
    },
}
</script>