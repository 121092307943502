<script>
import { HorizontalBar } from 'vue-chartjs'

export default {
    extends: HorizontalBar,
    props: {
        chartdata: {
            type: Object,
            default: null
        },
        options: null
    },
    mounted () {
        this.renderChart(this.chartdata, this.options)
    }
}
</script>