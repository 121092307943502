<template>
    <v-col cols="12">
        <v-row align="start" justify="space-between">
            <card-indicadores-estrategicos/>
        </v-row>
    </v-col>     
</template>

<script>
import CardIndicadoresEstrategicos from './CardIndicadoresEstrategicos'
export default {
    mounted() {
    }    
}
</script>