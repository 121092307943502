<template>
  <crud
    v-if="urlBase"
    ref="crud"
    nomeEntidade="Procedimento Externo"
    nomeEntidadePlural="Procedimentos Externos"
    :headers="cabecalhos"
    :resource-url="urlBase"
    rota-edicao="ProcedimentoExternoEditor"
    :query-params="parametrosConsultaAPI"
    itemKey="id"
    :podeSalvar="podeSalvar"
    @clicou-item="selecionarParaEdicao"
    :clicou-salvar="salvar"
    @clicou-cancelar="cancelar"
    @clicou-novo="novo"
  >
    <template slot="beforeAdd">
      <v-switch
        v-if="usuarioTemVisaoTotal"
        label="Listar de todas UGs?"
        v-model="parametrosConsultaAPI.listarDeTodasUGs"
        @change="aplicarListarDeTodasUGs"
      ></v-switch>
    </template>
    <template slot="detalhe">
      <v-container>
        <v-layout row wrap>
          <v-flex 12>
            <v-card>
              <v-card-text>
                <v-tabs v-model="tabAtiva" @change="mudouDeTab">
                  <v-tab>Cadastro</v-tab>
                  <v-tab
                    :disabled="entidadeAtual == null || entidadeAtual.id == null"
                    >Demandas</v-tab>
                  <v-tab
                    :disabled="entidadeAtual == null || entidadeAtual.id == null"
                  >Georreferenciamento</v-tab>

                  <v-tabs-slider color="primary"></v-tabs-slider>

                  <!-- TAB CADASTRO -->
                  <v-tab-item>
                    <div v-if="entidadeAtual != null">
                      <v-container class="ma-4 pr-8">
                        <v-layout row wrap>
                          <v-flex lg4>
                            <v-autocomplete
                              label="Tipo"
                              :items="tiposProcedimento"
                              v-model="entidadeAtual.idtipoprocedimentoexterno"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="tipoprocedimento"
                              item-value="id"
                              :loading="carregandoTiposProcedimento"
                            />
                          </v-flex>
                          <v-flex lg4>
                            <v-text-field
                              label="Procedimento"
                              v-model="entidadeAtual.procedimento"
                              :rules="[validacao.obrigatorio, validacao.tamanhoMinimo(entidadeAtual.procedimento, 8)]"
                              required
                              counter="150"
                              maxlength="150"
                            />
                          </v-flex>
                          <v-flex lg4>
                            <v-text-field
                              label="Chave de Acesso"
                              v-model="entidadeAtual.chave"
                              counter="50"
                              maxlength="50"
                            />
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex lg8>
                            <v-text-field
                              label="Resumo"
                              v-model="entidadeAtual.resumo"
                              counter="150"
                              maxlength="150"
                            />
                          </v-flex>
                          <v-flex lg4>
                            <v-autocomplete
                              label="Polo"
                              :items="polosProcedimento"
                              v-model="entidadeAtual.idpoloprocedimentoexterno"
                              :rules="[validacao.obrigatorio]"
                              required
                              item-text="polo"
                              item-value="id"
                              :loading="carregandoPolosProcedimento"
                            />
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex lg12>
                            <v-textarea label="Descrição" v-model="entidadeAtual.descricao"></v-textarea>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </div>
                  </v-tab-item>
                  <!-- Fim da TAB CADASTRO -->

                  <!-- TAB DEMANDAS -->
                  <v-tab-item>
                    <v-container fluid>
                      <v-data-table
                        :headers="cabecalhosDemandas"
                        :items="demandas"
                        item-key="id"
                        v-if="demandas"
                      >
                        <template v-slot:item="props">
                          <tr
                            @click="abrirDemanda(props.item.id)"
                            style="cursor:pointer;"
                          >
                            <td>{{ (new Date(props.item.created_at)).toLocaleDateString() }}</td>
                            <td>
                              {{ props.item.documentoexterno }}
                              <div class="caption grey--text">{{ props.item.autor.nome }}</div>
                              <div
                                class="caption grey--text"
                              >{{ props.item.autor.cargo.cargo }} - {{ props.item.autor.orgao.sigla}}</div>
                            </td>
                            <td>{{ props.item.nupsei }}</td>
                            <td>{{ props.item.dataprazo ? ((new Date(props.item.dataprazo)).toLocaleDateString()) : '' }}</td>
                            <td>{{ props.item.situacao.situacao }}</td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-container>
                  </v-tab-item>
                  <!-- Fim de TAB DEMANDAS -->

                  <!-- TAB GEORREFERENCIAMENTO -->
                  <v-tab-item>
                    <v-container fluid>
                      <geo-referenciamento
                        v-if="entidadeAtual"
                        :tabelaReferenciaCamada="tabelaReferenciaCamada"
                        :idReferenciado="entidadeAtual.id"
                        :atualizarMapa="atualizarMapa"
                      ></geo-referenciamento>
                    </v-container>
                  </v-tab-item>
                  <!-- Fim da TAB GEORREFERENCIAMENTO -->
                </v-tabs>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </crud>
</template>

<script>
import rotas from '../../../rotas-servico'
import CRUD from '../../../components/ApiCrud.vue'
import Validador from '../../../validacao';
import EventBus from '../../../event-bus'
import storageBus from '../../../store/StorageBus'

const PROCEDIMENTO_TAB_CADASTRO = 0;
const PROCEDIMENTO_TAB_DEMANDAS = 1;
const PROCEDIMENTO_TAB_GEORREFERENCIAMENTO = 2;

export default {
  components: {
    'crud': CRUD
  },
  data: () => {
    return {
      tabelaReferenciaCamada: 'procedimentoexterno',
      atualizarMapa: false,
      tabAtiva: 0,
      urlBase: rotas.demandas.procedimentoExterno.listar.url(),
      cabecalhos: [
        {
          text: 'Tipo',
          value: 'tipoprocedimentoexterno'
        },
        {
          text: 'Procedimento',
          value: 'procedimento'
        },
        {
          text: 'Resumo',
          value: 'resumo'
        }
      ],
      entidadeAtual: null,

      validacao: Validador,

      tiposProcedimento: [],
      carregandoTiposProcedimento: false,

      polosProcedimento: [],
      carregandoPolosProcedimento: false,

      // demandas
      demandas: null,
      cabecalhosDemandas: [
        {
          text: 'Data',
          value: 'created_at'
        },
        {
          text: 'Documento',
          value: 'documentoexterno'
        },
        {
          text: 'SEI',
          value: 'nupsei'
        },
        {
          text: 'Prazo',
          value: 'dataprazo'
        },
        {
          text: 'Situação',
          value: 'situacao'
        },
      ],

      usuarioTemVisaoTotal: false,
      parametrosConsultaAPI: {
        listarDeTodasUGs: false,
      }
    }
  },
  computed: {
    podeSalvar () {
      return true
    },
  },
  methods: {

    async selecionarParaEdicao (item) {
      // console.log('Item selecionado: ' + item.id)
      this.tabAtiva = PROCEDIMENTO_TAB_CADASTRO
      let response = await this.$http.get(rotas.demandas.procedimentoExterno.obter.url({
        id: item.id
      }));
      if (response.status == 200) {
        this.entidadeAtual = response.body
        this.atualizarMapa = true
      } else {
        console.log(response)
      }
      this.carregarTabelasApoio()
    },

    salvar () {
      return new Promise((resolve, reject) => {
        try {
            let formData = new FormData()
            formData.append('idtipoprocedimentoexterno', this.entidadeAtual.idtipoprocedimentoexterno)
            formData.append('idpoloprocedimentoexterno', this.entidadeAtual.idpoloprocedimentoexterno)
            formData.append('procedimento', this.entidadeAtual.procedimento)

            if (!this.entidadeAtual.chave) {
              this.entidadeAtual.chave='';
            }            
            formData.append('chave', this.entidadeAtual.chave)
            if (!this.entidadeAtual.resumo) {
              this.entidadeAtual.resumo='';
            }
            formData.append('resumo', this.entidadeAtual.resumo);

            if (!this.entidadeAtual.descricao) {
              this.entidadeAtual.descricao = '';
            }
            formData.append('descricao', this.entidadeAtual.descricao);
      
            if (this.entidadeAtual.id != null) {
              formData.append('_method', 'PUT')
            }
      
            let url = this.entidadeAtual.id === null ?
              rotas.demandas.procedimentoExterno.criar.url() :
              rotas.demandas.procedimentoExterno.editar.url({ id: this.entidadeAtual.id });
      
            this.$http.post(url, formData)
              .then(
                response => {
                  EventBus.mensagem('Procedimento Externo cadastrado com sucesso!')
                  resolve(response)
                },
                error => {
                    reject(new Error(error.body.message))
                }
              )
        } catch (e) {
          reject(e)
        }
      })
    },
    cancelar () {
      this.$refs.crud.loadItems()
    },
    novo () {
      this.carregarTabelasApoio()
      this.entidadeAtual = {
        id: null
      }
      this.tabAtiva = 0
    },

    carregarTiposProcedimento () {
      this.carregandoTiposProcedimento = true
      this.tiposProcedimento = []
      this.$http
        .get(rotas.demandas.tipoProcedimentoExterno.listar.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.tiposProcedimento.push(element)
            })
          },
          error => {
            console.log(error)
          }
        )
        .finally(() => {
          this.carregandoTiposProcedimento = false
        })
    },

    carregarPolosProcedimento () {
      this.carregandoPolosProcedimento = true
      this.polosProcedimento = []
      this.$http
        .get(rotas.demandas.poloProcedimentoExterno.listar.url())
        .then(
          response => {
            response.body.forEach(element => {
              this.polosProcedimento.push(element)
            })
          },
          error => {
            console.log(error)
          }
        )
        .finally(() => {
          this.carregandoPolosProcedimento = false
        })
    },

    carregarDemandas () {
      this.demandas = [];
      let url = rotas.demandas.demanda.porProcedimento.url({ idprocedimentoexterno: this.entidadeAtual.id })
      this.$http.get(url)
        .then(
          response => {
            // console.log(response);
            response.body.forEach(element => {
              // console.log(element)
              this.demandas.push(element)
            })
          },
          error => {
            console.log(error)
          }
        )

    },
    isTabDemandas () {
      return this.tabAtiva == PROCEDIMENTO_TAB_DEMANDAS
    },

    isTabGeorreferenciamento () {
      return this.tabAtiva == PROCEDIMENTO_TAB_GEORREFERENCIAMENTO
    },

    mudouDeTab () {
      this.atualizarMapa = false;
      if (this.isTabDemandas()) {
        this.carregarDemandas()
      }
      if (this.isTabGeorreferenciamento()) {
        this.atualizarMapa = true;
      }
    },

    carregarTabelasApoio () {
      this.carregarTiposProcedimento()
      this.carregarPolosProcedimento()
    },

    carregarPermissoesUsuario () {
      this.usuarioTemVisaoTotal = storageBus.usuarioPossuiPermissao("DEMANDA_VISAO_TOTAL")
    },

    aplicarListarDeTodasUGs () {
      this.$refs.crud.loadItems();
    },

    abrirDemanda(idDemanda) {
        this.$router.push("/demanda/" + idDemanda )
    }
  },
  mounted () {
    this.carregarPermissoesUsuario()
    if (this.$route.params.id) {
      this.selecionarParaEdicao({ id: this.$route.params.id })
    }
  }
}
</script>
