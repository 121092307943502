<template>
  <v-container>
    <!--
    <p>Disponíveis marcados: {{ disponiveisMarcados }}</p>
    <p>Selecionados marcados: {{ selecionadosMarcados }}</p>
    -->
    <v-row align="stretch" justify="space-between" fill-height>
      <!-- caixa de disponíveis -->
      <v-col xs="12" md="5" fill-height>
        <lista-selecao-filtravel
          rotulo="Disponíveis"
          :itens="listaDisponiveisComputed"
          :propriedadeValor="propriedadeValor"
          :propriedadeMarcado="propriedadeMarcado"
          :propriedadeTitulo="propriedadeTitulo"
          :propriedadeSubtitulo="propriedadeSubtitulo"
          :comSubtitulo="comSubtitulo"
          
          @change="mudouDisponiveis"
        />
      </v-col>

      <!-- botões de seleção -->
      <v-col xs="12" md="2" align="center">
          <v-container fill-height>
            <v-row>
                <v-spacer />
                <v-badge 
                  :content="disponiveisMarcados.length"
                  :value="disponiveisMarcados.length"
                  overlap>
                  <v-btn fab @click="clicouAdicionar" :disabled="disponiveisMarcados.length < 1"><v-icon>arrow_right</v-icon></v-btn>
                </v-badge>
                <v-spacer />
            </v-row>
            <v-row>
                <v-spacer />
                <v-badge 
                  :content="selecionadosMarcados.length"
                  :value="selecionadosMarcados.length"
                  color="red"
                  overlap>
                  <v-btn fab @click="clicouRemover" :disabled="selecionadosMarcados.length < 1"><v-icon>arrow_left</v-icon></v-btn>
                </v-badge>
                <v-spacer />
            </v-row>
          </v-container>
      </v-col>

      <!-- caixa de selecionados -->
      <v-col xs="12" md="5" fill-height>
        <lista-selecao-filtravel
          rotulo="Selecionados"
          :itens="listaSelecionados"
          :propriedadeValor="propriedadeValor"
          :propriedadeMarcado="propriedadeMarcado"
          :propriedadeTitulo="propriedadeTitulo"
          :propriedadeSubtitulo="propriedadeSubtitulo"
          :comSubtitulo="comSubtitulo"
          @change="mudouSelecionados"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListaSelecaoFiltravel from "./ListaSelecaoFiltravel";

export default {
  components: {
    "lista-selecao-filtravel": ListaSelecaoFiltravel,
  },

  props: {

    disponiveis: {
      type: Array,
      required: true,
    },

    selecionados: {
      type: Array,
      required: true,
    },

    propriedadeValor: {
      type: String,
      default: "valor",
    },
    propriedadeMarcado: {
      type: String,
      default: "marcado",
    },
    propriedadeTitulo: {
      type: String,
      default: "titulo",
    },
    propriedadeSubtitulo: {
      type: String,
      default: "subtitulo",
    },
    comSubtitulo: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      listaDisponiveis: [],
      listaSelecionados: [],

      disponiveisMarcados: [],
      selecionadosMarcados: [],
    };
  },

  methods: {
    mudouDisponiveis(disponiveisMarcados) {
      this.disponiveisMarcados = disponiveisMarcados;
    },

    mudouSelecionados(selecionadosMarcados) {
      //console.log("ListaDuplaSelecaoFiltravel.mudouSelecionados", selecionadosMarcados)
      this.selecionadosMarcados = selecionadosMarcados;
    },

    clicouAdicionar() {
        //console.log("ListaDuplaSelecaoFiltravel.clicouAdicionar", this.disponiveisMarcados)
        this.disponiveisMarcados.forEach(itemMarcado => {
          itemMarcado[this.propriedadeMarcado] = false
          this.adicionarItemNaLista(itemMarcado, this.listaSelecionados)
          this.listaDisponiveis = this.removerItemDaLista(itemMarcado, this.listaDisponiveis)
        })
        this.$emit("change", this.listaDisponiveis, this.listaSelecionados)
        
    },

    clicouRemover() {
        //console.log("ListaDuplaSelecaoFiltravel.clicouRemover", this.disponiveisMarcados)
        this.selecionadosMarcados.forEach(itemMarcado => {
          itemMarcado[this.propriedadeMarcado] = false
          this.adicionarItemNaLista(itemMarcado, this.listaDisponiveis)
          this.listaSelecionados = this.removerItemDaLista(itemMarcado, this.listaSelecionados)
        })
        this.$emit("change", this.listaDisponiveis, this.listaSelecionados)
    },

    adicionarItemNaLista(item, lista) {
      lista.push(item)
    },

    removerItemDaLista(elementoARemover, lista) {
      return lista.filter(elementoDaLista => {
        return elementoDaLista[this.propriedadeValor] !== elementoARemover[this.propriedadeValor]
      })
    },

    clonarDisponiveis() {
      this.listaDisponiveis = [];
      this.disponiveis.forEach(item => {
        let itemClonado = this.clonarItem(item)
        this.listaDisponiveis.push(itemClonado)
      });
    },

    clonarSelecionados() {
      this.listaSelecionados = [];
      this.selecionados.forEach(item => {
        let itemClonado = this.clonarItem(item)
        this.listaSelecionados.push(itemClonado)
      });
    },

    clonarItem(item) {
        let itemClonado = {};
        itemClonado[this.propriedadeMarcado] = item[this.propriedadeMarcado] ? true : false;
        itemClonado[this.propriedadeValor] = item[this.propriedadeValor];
        itemClonado[this.propriedadeTitulo] = item[this.propriedadeTitulo];
        itemClonado[this.propriedadeSubtitulo] = item[this.propriedadeSubtitulo];
        //console.log("clonarItem", item, itemClonado)
        return itemClonado
    },

    clonarItens() {
      this.clonarDisponiveis();
      this.clonarSelecionados();
    },

  },

  watch: {
    disponiveis: function() {
      this.clonarDisponiveis()
    },

    selecionados: function() {
      this.clonarSelecionados()
    }

  },

  computed: {
    listaDisponiveisComputed() {
      this.selecionados.forEach(itemSelecionado => {
        this.listaDisponiveis = this.removerItemDaLista(itemSelecionado, this.listaDisponiveis)
      })
      return this.listaDisponiveis
    }
  },

  mounted() {
    this.clonarItens();
  }
};
</script>
