import Vue from 'vue';
import store from './store'

const EventBus = new Vue()

EventBus.mensagem = (msg) => {
    EventBus.$emit('mensagem', msg)
}

EventBus.aviso = (msg) => {
    EventBus.$emit('mensagem-aviso', msg)
}

EventBus.alerta = (msg) => {
    EventBus.$emit('mensagem-alerta', msg)
}

export default EventBus