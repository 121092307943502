import store from './index';

class StorageBus {

    constructor() {
        this.store = store
    }

    getUsuario = () => {
        return this.store.getters['auth/usuario']
    }

    getUnidadeGestora = () => {
        return this.store.getters['auth/superintendencia']
    }
    
    getPermissoes = () => {
        return this.store.getters['auth/permissoes']
    }

    usuarioPossuiPermissao(permissao) {
        let permissoes = this.getPermissoes()
        for (let i = 0; i < permissoes.length; i++) {
            const permissaoDoUsuario = permissoes[i];
            //console.log(permissaoDoUsuario.permissao + " == " + permissao, permissaoDoUsuario.permissao == permissao)
            if(permissaoDoUsuario.permissao == permissao) {
                return true
            }
        }
        return false
    }

}

const storageBus = new StorageBus()

export default storageBus