<template>
    <v-container fluid fill-height>
        <v-row align="stretch" justify="center" >
            <v-col xs="12" md="6" align="center">
                <v-form v-model="valid" method="POST">
                    <v-card >
                        <v-card-title>
                            Identifique-se
                        </v-card-title>
                        <v-card-text>
                            <v-text-field v-model="email" 
                                :rules="[emailRules.required, emailRules.numero, emailRules.min]" label="Login (CPF)" placeholder="Informe seu CPF, somente os números" required @keyup.enter="submit"></v-text-field>
                            <v-text-field
                                v-model="password"
                                :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                                :rules="[passwordRules.required, passwordRules.min]"
                                :type="showPassword ? 'text' : 'password'"
                                name="input-10-1"
                                label="Senha"
                                hint="No mínimo 8 caracteres"
                                counter
                                @click:append="showPassword = !showPassword"
                                @keyup.enter="submit"
                            ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-container fluid class="ma-0 pa-0">
                                <v-row class="ma-0 pa-0">
                                    <v-col>
                                        <v-btn text @click="clicouEsqueceuSenha">Esqueceu sua senha?</v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-btn color="info" @click="submit" :disabled="!valid || carregando">
                                            <v-progress-circular indeterminate v-if="carregando" color="grey" size="20" width="3"></v-progress-circular>
                                            Entrar
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <v-row v-if="mostrarBotaoLogarComGovBr" class="pt-2 mx-2" style="border-top: 1px solid #A0A0A0;">
                                    <v-col>
                                        <v-btn
                                            @click="logarComGovBr" small outlined
                                            class="rounded-pill" color="primary">
                                            Acessar com Gov.Br
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import rotas from '../rotas-servico/'
import EventBus from './../event-bus'

export default {
    data: () => ({
        carregando: false,
        valid: false,
        password: '',
        showPassword: false,
        passwordRules: {
            required: value => !!value || 'Preenchimento obrigatório.',
            min: v => v !== null && v.length >= 8 || 'No mínimo 8 caracteres',
        },
        email: '',
        emailRules: {
            required: v => !!v || 'Preenchimento obrigatório.',
            valid: v => /.+@.+\..+/.test(v) || 'E-mail precisa ser válido',
            min: v => v !== null && v.length >= 11 || 'No mínimo 11 caracteres',
            numero: v => v !== null && (/^\d+$/.test(v)) || "CPF inválido. Use apenas números, sem edição",
        },
        mostrarBotaoLogarComGovBr: false
    }),
    methods: {
        async submit () {
            let formData = new FormData()
            formData.append('cpf', this.email)
            formData.append('password', this.password)

            // formData.append('csrf_token', $('meta[name="csrf-token"]').attr('content'));

            this.carregando = true
            return this.$http
                .post(rotas.controleAcesso.login.url(), formData)
                .then(
                    response => {
                        if(response.body && response.body.error) {
                            EventBus.alerta('E-mail e/ou senha inválidos')
                        } else {
                            EventBus.$emit('App::onLogin', response.data)
                        }
                    },
                    error => {
                        if(error.status == 401) {
                            EventBus.alerta('E-mail e/ou senha inválidos')
                        } else {
                            console.log(error);
                            console.log("*** Erro ao tentar efetuar login");
                            EventBus.alerta('Erro ao tentar efetuar login '+ error.status)
                        }
                    }
                ).finally(() => {
                    this.carregando = false
                })
        },
        
        clicouEsqueceuSenha() {
            this.$router.push({ 
                name: 'RecuperarSenha',
                })
                .catch(e => {
                    console.log('Erro ao redirecionar para /recuperar-senha: ', e)
                })
        },

        logarComGovBr() {
            window.location.href = '/oauth/access-token'
            //console.log('abrindo govbr')
            //this.$router.push({ name: 'OAuthGovBR'});
        }
    }
}
</script>