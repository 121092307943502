<template>
  <v-card>
    <v-card-title>Demandas por Núcleo</v-card-title>
    <v-card-actions>
      <v-btn
        @click="gerarRelatorioDemandasPorNucleo"
        :disabled="carregandoRelatorioDemandasPorNucleo"
        small
      >
        <v-progress-circular
          indeterminate
          v-if="carregandoRelatorioDemandasPorNucleo"
          color="grey"
          size="20"
          width="3"
        ></v-progress-circular>
        Gerar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import rotas from '../../../../rotas-servico'
export default {
  data: () => {
    return {
      carregandoRelatorioDemandasPorNucleo: false,
    };
  },

  methods: {
    async gerarRelatorioDemandasPorNucleo() {
      let formData = new FormData();

      this.carregandoRelatorioDemandasPorNucleo = true;
      let url = rotas.demandas.relatorio.abertasPorDivisaoOrganograma.url();
      return this.$http
        .post(url, formData, { responseType: "arraybuffer" })
        .then(
          (response) => {
            // console.log(response.data);
            let blob = new Blob([response.data], {
              type: response.headers.get("content-type"),
            });
            const data = window.URL.createObjectURL(blob);
            window.open(data, "_blank");
          },
          (error) => {
              EventBus.alerta(error.body.message);
          }
        ).finally(() => {
            this.carregandoRelatorioDemandasPorNucleo = false;
        });
    },
  },
};
</script>

<style>
</style>